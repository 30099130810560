import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import SlideTransition from '../SlideTransition';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import TaskEventDiffTotal from '../TaskEventDiffTotal/TaskEventDiffTotal';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const CreateEventModal = (props) => {
  const classes = useStyles();
  const {
    open,
    selected,
    close,
    edit,
    save,
    tabIndex,
    tasksS,
    events,
    dateMarq,
    selDate,
    tasks,
    deleteTask,
  } = props;

  const [selectedTask, setSelectedTask] = useState(
    selected?.yearTask ? selected.yearTask.id : '',
  );
  const [filteredTaskEvents, setFilteredTaskEvents] = useState([]);

  useEffect(() => {
    const rows = [];
    setFilteredTaskEvents(
      rows.filter((item) => {
        if (!selectedTask) {
          return false;
        }
        return selectedTask.id === item.id;
      }),
    );
  }, [selectedTask]);

  const onTaskChange = (e) => {
    setSelectedTask(e.target.value);
  };

  const handleEdit = () => {
    let taskId =
      typeof selectedTask === 'string' ||
        selectedTask instanceof String
        ? selectedTask
        : selectedTask.id;
    const yearTaskSel = tasksS.filter((item) => {
      return item.id === taskId;
    });
    props.edit({
      id: selected.id,
      title: yearTaskSel[0].text,
      yearTask: yearTaskSel[0],
      progress: 0
    });
    props.close();
  };

  const handleSubmit = () => {
    handleEdit();
  };

  const onClose = () => {
    setSelectedTask('');
    close();
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      TransitionComponent={SlideTransition}
    >
      <DialogContent>
        <TaskEventDiffTotal
          events={events}
          tasksS={tasksS}
          selDate={selDate}
          tasks={tasks}
        />
        <Grid container justify="space-around">
          <FormControl style={{ minWidth: '100%' }}>
            <InputLabel id="demo-simple-select-label">
              タスクを選択して下さい。
            </InputLabel>
            <Select value={selectedTask} onChange={onTaskChange}>
              {tasksS.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  <ListItemText
                    primary={
                      item.parentText
                        ? item.parentText + ' - ' + item.text
                        : item.text
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
          color="primary"
        >
          キャンセル
        </Button>
        <Button onClick={deleteTask} color="secondary">
          削除
        </Button>
        <Button
          disabled={!selectedTask}
          onClick={handleSubmit}
          color="primary"
        >
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEventModal;
