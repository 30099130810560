import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import './TaskEventDiffTotal.scoped.scss';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-dates/initialize';
import Marquee  from 'react-double-marquee';
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import NumberFormat from 'react-number-format';

import {getYearlyEvent} from '../../../api/yearlyEvents';

import TaskEventDiffTotal from './TaskEventDiffTotal';


const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        textAlign: 'center',
      },
    },
  },
});

import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { setDate } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const PreviewRow = props => {
  const { task, weekTasks } = props;
  const [parent,setParent] = useState(null);


  const taskStart = moment(task.start_date);
  const taskEnd = moment(task.end_date);
  let weeks = Math.ceil(taskEnd.diff(taskStart, 'week', true));

  if (weeks === 0) weeks = 1;

  const taskMinutes = parseInt(task.totalHours) * 60

  let totalWari = 0;
  let progress = 0;

  const fetchParent = async () =>{
    await getYearlyEvent(task.parent.toString()).then((data)=>{
      setParent(data);
    })
  }
  useEffect(()=>{
    if(task.parent === 0) return;
    fetchParent();
  },[])

  if (weekTasks.mon) {
    weekTasks.mon.filter((item)=>{
      const taskEnd = moment(item.end);
      return (parseInt(item.progress) > 0 || taskEnd >= moment());
    }).map(item => {
      if (item.yearTask.id === task.id) {
        const eventStart = moment(item.end).diff(
          moment(item.start),
          'minutes',
          true,
        );
        const eventJ = parseInt(item.progress)
        totalWari = totalWari + eventStart;
        progress = progress + eventJ;
      }
    });
  }
  if (weekTasks.tue) {
    weekTasks.tue.filter((item)=>{
      const taskEnd = moment(item.end);
      return (parseInt(item.progress) > 0 || taskEnd >= moment());
    }).map(item => {
      if (item.yearTask.id === task.id) {
        const eventStart = moment(item.end).diff(
          moment(item.start),
          'minutes',
          true,
        );
        const eventJ = parseInt(item.progress)
        totalWari = totalWari + eventStart;
        progress = progress + eventJ;
      }
    });
  }
  if (weekTasks.wed) {
    weekTasks.wed.filter((item)=>{
      const taskEnd = moment(item.end);
      return (parseInt(item.progress) > 0 || taskEnd >= moment());
    }).map(item => {
      if (item.yearTask.id === task.id) {
        const eventStart = moment(item.end).diff(
          moment(item.start),
          'minutes',
          true,
        );
        const eventJ = parseInt(item.progress)
        totalWari = totalWari + eventStart;
        progress = progress + eventJ;
      }
    });
  }
  if (weekTasks.thur) {
    weekTasks.thur.filter((item)=>{
      const taskEnd = moment(item.end);
      return (parseInt(item.progress) > 0 || taskEnd >= moment());
    }).map(item => {
      if (item.yearTask.id === task.id) {
        const eventStart = moment(item.end).diff(
          moment(item.start),
          'minutes',
          true,
        );
        const eventJ = parseInt(item.progress)
        totalWari = totalWari + eventStart;
        progress = progress + eventJ;
      }
    });
  }
  if (weekTasks.fri) {
    weekTasks.fri.filter((item)=>{
      const taskEnd = moment(item.end);
      return (parseInt(item.progress) > 0 || taskEnd >= moment());
    }).map(item => {
      if (item.yearTask.id === task.id) {
        const eventStart = moment(item.end).diff(
          moment(item.start),
          'minutes',
          true,
        );
        const eventJ = parseInt(item.progress)
        totalWari = totalWari + eventStart;
        progress = progress + eventJ;
      }
    });
  }
  if (weekTasks.sat) {
    weekTasks.sat.filter((item)=>{
      const taskEnd = moment(item.end);
      return (parseInt(item.progress) > 0 || taskEnd >= moment());
    }).map(item => {
      if (item.yearTask.id === task.id) {
        const eventStart = moment(item.end).diff(
          moment(item.start),
          'minutes',
          true,
        );
        const eventJ = parseInt(item.progress)
        totalWari = totalWari + eventStart;
        progress = progress + eventJ;
      }
    });
  }
  if (weekTasks.sun) {
    weekTasks.sun.filter((item)=>{
      const taskEnd = moment(item.end);
      return (parseInt(item.progress) > 0 || taskEnd >= moment());
    }).map(item => {
      if (item.yearTask.id === task.id) {
        const eventStart = moment(item.end).diff(
          moment(item.start),
          'minutes',
          true,
        );
        const eventJ = parseInt(item.progress)
        totalWari = totalWari + eventStart;
        progress = progress + eventJ;
      }
    });
  }
  
  return (
    <tr>
      <th className="mar-th"><Marquee direction="left" delay={0} speed="0.02" scrollWhen="overflow">{parent ? parent.text + "-" : ''}{task.text}</Marquee></th>
      <td><NumberFormat value={(task.totalHours * 60) / weeks} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
      <td>
        <strong className="minus"><NumberFormat value={totalWari - (task.totalHours * 60)} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></strong>
      </td>
      <td><NumberFormat value={totalWari} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
      <td>
        <strong><NumberFormat value={parseInt(progress) - totalWari} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></strong>
      </td>
      <td><NumberFormat value={parseInt(progress)} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
    </tr>
  );
};

const PreviewRowTotal = props => {
  const { yearTasks, weekTasks } = props;

  let taskMinutes = 0;
  let totalWari = 0;
  let progress = 0;

  yearTasks.map(task => {
    const taskStart = moment(task.start_date);
    const taskEnd = moment(task.end_date);
    let weeks = Math.ceil(taskEnd.diff(taskStart, 'week', true));
    if (weeks === 0) weeks = 1;
    taskMinutes = (taskMinutes + parseInt(task.totalHours) * 60) / weeks
    if (weekTasks.mon) {
      weekTasks.mon.filter((item)=>{
        const taskEnd = moment(item.end);
        return (parseInt(item.progress) > 0 || taskEnd >= moment());
      }).map(item => {
        if (item.yearTask.id === task.id) {
          const eventStart = moment(item.end).diff(
            moment(item.start),
            'minutes',
            true,
          );
          const eventJ = item.progress
          totalWari = totalWari + eventStart;
          progress = parseInt(progress) + eventJ;
        }
      });
    }
    if (weekTasks.tue) {
      weekTasks.tue.filter((item)=>{
        const taskEnd = moment(item.end);
        return (parseInt(item.progress) > 0 || taskEnd >= moment());
      }).map(item => {
        if (item.yearTask.id === task.id) {
          const eventStart = moment(item.end).diff(
            moment(item.start),
            'minutes',
            true,
          );
          const eventJ = item.progress
          totalWari = totalWari + eventStart;
          progress = parseInt(progress) + eventJ;
        }
      });
    }
    if (weekTasks.wed) {
      weekTasks.wed.filter((item)=>{
        const taskEnd = moment(item.end);
        return (parseInt(item.progress) > 0 || taskEnd >= moment());
      }).map(item => {
        if (item.yearTask.id === task.id) {
          const eventStart = moment(item.end).diff(
            moment(item.start),
            'minutes',
            true,
          );
          const eventJ = item.progress
          totalWari = totalWari + eventStart;
          progress = parseInt(progress) + eventJ;
        }
      });
    }
    if (weekTasks.thur) {
      weekTasks.thur.filter((item)=>{
        const taskEnd = moment(item.end);
        return (parseInt(item.progress) > 0 || taskEnd >= moment());
      }).map(item => {
        if (item.yearTask.id === task.id) {
          const eventStart = moment(item.end).diff(
            moment(item.start),
            'minutes',
            true,
          );
          const eventJ = item.progress
          totalWari = totalWari + eventStart;
          progress = parseInt(progress) + eventJ;
        }
      });
    }
    if (weekTasks.fri) {
      weekTasks.fri.filter((item)=>{
        const taskEnd = moment(item.end);
        return (parseInt(item.progress) > 0 || taskEnd >= moment());
      }).map(item => {
        if (item.yearTask.id === task.id) {
          const eventStart = moment(item.end).diff(
            moment(item.start),
            'minutes',
            true,
          );
          const eventJ = item.progress
          totalWari = totalWari + eventStart;
          progress = parseInt(progress) + eventJ;
        }
      });
    }
    if (weekTasks.sat) {
      weekTasks.sat.filter((item)=>{
        const taskEnd = moment(item.end);
        return (parseInt(item.progress) > 0 || taskEnd >= moment());
      }).map(item => {
        if (item.yearTask.id === task.id) {
          const eventStart = moment(item.end).diff(
            moment(item.start),
            'minutes',
            true,
          );
          const eventJ = item.progress
          totalWari = totalWari + eventStart;
          progress = parseInt(progress) + eventJ;
        }
      });
    }
    if (weekTasks.sun) {
      weekTasks.sun.filter((item)=>{
        const taskEnd = moment(item.end);
        return (parseInt(item.progress) > 0 || taskEnd >= moment());
      }).map(item => {
        if (item.yearTask.id === task.id) {
          const eventStart = moment(item.end).diff(
            moment(item.start),
            'minutes',
            true,
          );
          const eventJ = item.progress
          totalWari = totalWari + eventStart;
          progress = parseInt(progress) + eventJ;
        }
      });
    }
  });

  return (
    <tr>
      <th>合計</th>
      <td><NumberFormat value={taskMinutes} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
      <td>
        <strong className="minus">
        <NumberFormat value={totalWari - taskMinutes} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} />
        </strong>
      </td>
      <td><NumberFormat value={totalWari} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
      <td>
        <strong><NumberFormat value={parseInt(progress) - totalWari} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></strong>
      </td>
      <td><NumberFormat value={parseInt(progress)} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
    </tr>
  );
};

const MiniPreviewRow = props => {
  const { text, totalWari, progress } = props;
  return (
    <tr>
      <th>{text}</th>
      <td><NumberFormat value={totalWari} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
      <td>
        <strong className="minus"><NumberFormat value={parseInt(progress) - totalWari} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></strong>
      </td>
      <td><NumberFormat value={parseInt(progress)} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
    </tr>
  );
};
const MiniPreviewRowTotal = props => {
  const { tasks } = props;

  let totalWari = 0;
  let progress = 0;

  tasks.map(item => {
    totalWari = item.totalHours + totalWari;
    progress = parseInt(item.progress) + parseInt(progress)
  })

  return (
    <tr>
      <th>合計</th>
      <td><NumberFormat value={totalWari} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
      <td>
        <strong className="minus"><NumberFormat value={parseInt(progress) - totalWari} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></strong>
      </td>
      <td><NumberFormat value={parseInt(progress)} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
    </tr>
  );
};

export default function TaskEventDiffTotalModal(props) {

  const {open, close, events, tasks, selDate} = props;
  
  const classes = useStyles();
  const [value, setValue] = useState(0);

  return (
      <Dialog
        open={props.open}
        onClose={props.close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">タスク別集計</DialogTitle>
        <DialogContent>
          <TaskEventDiffTotal events={events} tasks={tasks} selDate={selDate} />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
  );
}
