import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import "./dialogues.scoped.scss";

export default (props) => {

    const [currPass, setCurrPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [newPassConf, setNewPassConf] = useState('');
    const [passError, setPassError] = useState('');

    const passSubmit = async ()=>{
        setPassError('');
        if(newPass !== newPassConf){
            setPassError('パスワードは一致していません。');
            return false;
        }
        if(currPass === ''){
            setPassError('現在のパスワードを入力して下さい。');
            return false;
        }
        if(newPass === '' || newPassConf === ''){
            setPassError('新パスワードを入力して下さい。');
            return false;
        }
        await props.submit(currPass, newPass).then(()=>{
            formReset();
        }).catch(err=>{
            console.error(err);
            if(err.message === 'The password is invalid or the user does not have a password.'){
                setPassError('現在のパスワードは正しくありません。');
            }else{
                setPassError('パスワードの更新失敗しました。');
            }
            return false;
        });
        
    }

    const formReset = ()=>{
        setNewPass('');
        setNewPassConf('');
        setPassError('');
        setCurrPass('')
    }

    const handleClose = ()=>{
        formReset();
        props.close();
    }

    return (
        <>
            <Dialog
                open={props.show}
                onClose={props.close}
                aria-labelledby="form-dialog-title"
                className="user-diag"
            >
                <DialogTitle id="form-dialog-title">パスワードの再設定</DialogTitle>
                <DialogContent>
                <Grid container spacing={1} style={{marginBottom : 20}}>
                <Grid item xs={6}>
                            <TextField label="現在のパスワード" variant="outlined" type="password" onChange={(e) => setCurrPass(e.target.value)} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField label="新パスワード" variant="outlined" defaultValue={newPass} onChange={(e) => setNewPass(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="パスワード再入力" variant="outlined" defaultValue={newPassConf} onChange={(e) => setNewPassConf(e.target.value)} />
                        </Grid>
                    </Grid>
                    {passError ? (<p style={{color:"red", fontSize:12, marginTop:10, marginBottom:10}}>{passError}</p>) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>handleClose()} color="primary">キャンセル</Button>
                    <Button onClick={()=>passSubmit()} color="primary">保存</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

