import React from 'react'
import NumberFormat from 'react-number-format';
import './EventTimePreview.scoped.scss';
import Chip from '../TaskEventDiffTotal/Chip'

const EventTimePreview = (props) => {
    return(
        <>
        <div className="ej-preview">
            <div>
                <p>計画<strong><NumberFormat value={props.about} displayType={'text'} decimalScale={0} thousandSeparator={true} />分</strong></p>
            </div>
            <div className="chipper">
            <p><Chip tJiseki={props.real()} tWari={props.about} /></p>
            </div>
            <div>
                <p>計画割当<strong><NumberFormat value={props.real()} displayType={'text'} decimalScale={0} thousandSeparator={true} />分</strong></p>
            </div>
        </div></>
    )
}

export default EventTimePreview;