Array.prototype.sum = function (prop) {
  var total = 0;
  for (var i = 0, _len = this.length; i < _len; i++) {
    total += this[i][prop];
  }
  return total;
};

import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import moment from 'moment';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { cleanTaskData } from '../../../api/weeklyEvents';
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import TabPanel from '../TabPanel';
import TaskEventDiffTotal from '../TaskEventDiffTotal/TaskEventDiffTotal';

import EventTimePreview from './EventTimePreview';

const NewEventPanel = (props) => {
  const {
    tabVal,
    tasksS,
    events,
    selectedTask,
    onTaskChange,
    eventStart,
    eventEnd,
    onEventStartChange,
    onEventEndChange,
    filteredTaskEvents,
    selDate,
    tasks,
    selected,
  } = props;

  const taskSumArray = () => {
    const rows = [];
    tasks
      ?.filter((item) => {
        return item.id === selectedTask;
      })
      .map((task) => {
        const hasEvent = rows.findIndex((x) => x.id === task.id);
        const gantEventWeeks = Math.ceil(
          moment(task.end_date).diff(
            moment(task.start_date),
            'weeks',
            true,
          ),
        );

        let totalSched = 0;
        if (task?.type === 'routine') {
          totalSched = Math.ceil(((task.hpd * task.dpw)) / 60 * 60);
        } else {
          totalSched = Math.ceil(
            (task.totalHours * 60) / gantEventWeeks,
          );
        }

        if (hasEvent == -1) {
          rows.push({
            id: task.id,
            title: task.text,
            totalProgress: 0,
            totalScheduled: totalSched,
            parentText: task.parentText ? task.parentText : '',
            totalMinutes: 0,
          });
        } else {
          rows[hasEvent].id = task.id;
          rows[hasEvent].totalScheduled =
          totalSched;
          rows[hasEvent].parentText = task.parentText
            ? task.parentText
            : '';
        }
      });
    events?.mon?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    events?.tue?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    events?.wed?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    events?.thur?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    events?.fri?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    events?.sat?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    events?.sun?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    return rows;
  };

  const previewTotal = () => {
    const eventMinutes = taskSumArray().sum('totalMinutes') + Math.ceil(moment(eventEnd).diff(moment(eventStart),'minutes',true)) - (selected ? Math.ceil(moment(selected.end).diff(moment(selected.start),'minutes',true)) : 0)
    return eventMinutes === 0 ? Math.ceil(moment(eventEnd).diff(moment(eventStart),'minutes',true)) : eventMinutes;
  }

  return (
    <TabPanel value={tabVal} index={0}>
      <TaskEventDiffTotal
        events={events}
        tasksS={tasksS}
        selDate={selDate}
        tasks={tasks}
      />

      <Grid container justify="space-around">
        <FormControl style={{ minWidth: '100%' }}>
          <InputLabel id="demo-simple-select-label">
            タスク
          </InputLabel>
          <Select value={selectedTask} onChange={onTaskChange}>
            {tasksS.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                <ListItemText
                  primary={
                    item.parentText
                      ? item.parentText + ' - ' + item.text
                      : item.text
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid container justify="space-around">
        <Grid
          item
          xs={12}
          style={{ marginTop: 10, marginBottom: -10 }}
        >
          開始
        </Grid>
        <Grid item xs={6}>
          <KeyboardDatePicker
            inputProps={{ readOnly: true }}
            disableToolbar
            variant="inline"
            margin="normal"
            id="date-picker-inline"
            label="日程"
            value={eventStart}
            onChange={onEventStartChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyboardTimePicker
            inputProps={{ readOnly: true }}
            minDate={eventStart}
            maxDate={moment(eventStart).endOf('day').toDate()}
            margin="normal"
            id="time-picker"
            label="時間"
            ampm={false}
            value={eventStart}
            onChange={onEventStartChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            keyboardIcon={<AccessTimeIcon />}
          />
        </Grid>
      </Grid>

      <Grid container justify="space-around">
        <Grid
          item
          xs={12}
          style={{ marginTop: 10, marginBottom: -10 }}
        >
          終了
        </Grid>
        <Grid item xs={6}>
          <KeyboardDatePicker
            inputProps={{ readOnly: true }}
            disableToolbar
            variant="inline"
            margin="normal"
            id="date-picker-inline"
            label="日程"
            minDate={eventStart}
            maxDate={moment(eventStart).endOf('day').toDate()}
            value={eventEnd}
            onChange={onEventEndChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyboardTimePicker
            inputProps={{ readOnly: true }}
            margin="normal"
            id="time-picker"
            label="時間"
            ampm={false}
            value={eventEnd}
            minDate={eventStart}
            maxDate={moment(eventStart).endOf('day').toDate()}
            onChange={onEventEndChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            keyboardIcon={<AccessTimeIcon />}
          />
        </Grid>
        <Grid item xs={12}>
          <EventTimePreview
            about={taskSumArray().sum('totalScheduled')}
            real={previewTotal}
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default NewEventPanel;
