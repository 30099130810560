import React, { useState, useEffect } from 'react';
import { withFirebase } from '../../Firebase';
import { useLiff } from 'react-liff';
import { useHistory, useLocation } from "react-router-dom";

import { getLineUser } from '../../../api/lineUsers';

const LineAuthCheck = (props) => {
    const { error, liff, isLoggedIn, ready } = useLiff();
    const context = liff.getContext();

    const [loadedUser, setLoadingUser] = useState(false);
    const [userData, setUserData] = useState(null);

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (isLoggedIn && !userData) {
            getLineUser(context.userId).then(data => {
                if (data) {
                    setUserData(data);
                    setLoadingUser(true);
                    if (location.pathname !== '/lineapp-calendar') {
                        history.push("/lineapp-calendar");
                    }
                } else {
                    if (location.pathname !== '/lineapp-register') {
                        history.push("/lineapp-register");
                    }
                }
            });
            return;
        }
        if (isLoggedIn && userData && location.pathname !== '/lineapp-calendar') {
            history.push("/lineapp-calendar");
            return
        }
        if (!isLoggedIn && location.pathname !== "/lineapp") {
            history.push("/lineapp")
            return;
        };
    }, [location, isLoggedIn])

    const showLogin = () => {
        if (error) return <p>エラー発生しました。</p>;
        if (!ready && !loadedUser) return <p>ローディング...</p>;
        return props.children
    }

    return (
        <>
            {showLogin()}
        </>
    );
}

export default withFirebase(LineAuthCheck);