import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import './StudentListItem.scoped.scss';

export default props => {
  const { user, fireData } = props;
  const [isEdit, setIsEdit] = React.useState(false);

  const [deleteDia, setDeleteDia] = React.useState(false);

  const handleDelete = () => {
    setDeleteDia(true);
  };

  const renderDepartment = department => {
    if (getDepartmentFromId(department) !== null) {
      return getDepartmentFromId(department);
    } else {
      return '未設定';
    }
  };

  const renderSchool = school => {
    if (getSchoolFromId(school) !== null) {
      return getSchoolFromId(school);
    } else {
      return '未設定';
    }
  };

  const renderClassifications = classifications => {
    const items = [];
    classifications.map((dep, index) => {
      if (getClassFromId(dep) !== null) {
        items.push(
          <React.Fragment key={index}>
            {getClassFromId(dep)}
            {index != classifications.length - 1 ? ', ' : null}
          </React.Fragment>,
        );
      }
    });
    if (!items.length) return '未設定';
    return items;
  };

  const renderCourses = courses => {
    const items = [];
    courses.map((dep, index) => {
      if (getCourseFromId(dep) !== null) {
        items.push(
          <React.Fragment key={index}>
            {getCourseFromId(dep)}
            {index != courses.length - 1 ? ', ' : null}
          </React.Fragment>,
        );
      }
    });
    if (!items.length) return '未設定';
    return items;
  };

  const renderTags = tags => {
    const items = [];
    tags.map((dep, index) => {
      if (getTagFromId(dep) !== null) {
        items.push(
          <React.Fragment key={index}>
            {getTagFromId(dep)}
            {index != tags.length - 1 ? ', ' : null}
          </React.Fragment>,
        );
      }
    });
    if (!items.length) return '未設定';
    return items;
  };

  const getTagFromId = id => {
    const team = fireData.tags.filter(tm => tm.id === id);
    if (team.length) {
      return team[0].title;
    }
    return null;
  };

  const getSchoolFromId = id => {
    const team = fireData.schools.filter(tm => tm.id === id);
    if (team.length) {
      return team[0].title;
    }
    return null;
  };

  const getClassFromId = id => {
    const team = fireData.classifications.filter(tm => tm.id === id);
    if (team.length) {
      return team[0].title;
    }
    return null;
  };

  const getDepartmentFromId = id => {
    const team = fireData.departments.filter(tm => tm.id === id);
    if (team.length) {
      return team[0].title;
    }
    return null;
  };

  const getCourseFromId = id => {
    const team = fireData.courses.filter(tm => tm.id === id);
    if (team.length) {
      return team[0].title;
    }
    return null;
  };

  return (
      <TableRow className="user-list-item">
        <TableCell padding="checkbox">
          <Checkbox
            checked={props.selected}
            inputProps={{ 'aria-labelledby': props.labelId }}
            onClick={() => props.select(user.id)}
          />
        </TableCell>
        <TableCell>
          <Link to={`/console/students/${user.id}/weekly`}>
            <div className="avatar-name">
              <Avatar
                className="avatar-pic"
                alt=""
                src={user.avatar}
              />
              {user.name}
            </div>
          </Link>
        </TableCell>
        <TableCell>{user.studentId}</TableCell>
        <TableCell>
          {user.department
            ? renderDepartment(user.department)
            : '未定'}
        </TableCell>
        <TableCell>
          {user.school ? renderSchool(user.school) : '未定'}
        </TableCell>
        <TableCell>
          {user.tags ? renderTags(user.tags) : ''}
        </TableCell>
        <TableCell>
          <Link
            className="user-edit"
            style={{
              display: 'block',
              textAlign: 'center',
              lineHeight: '25px',
              padding: 0,
            }}
            to={`/console/students/${user.id}`}
          >
            編集
          </Link>
        </TableCell>
      </TableRow>
  );
};
