import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';

if (!firebase.apps.length) {
  const CLIENT_CONFIG = {
    apiKey: "AIzaSyDeV1gVgtuJzbKfdmdIMXDu2Fy6XUmxTq4",
    authDomain: "future-log-67799.firebaseapp.com",
    projectId: "future-log-67799",
    storageBucket: "future-log-67799.appspot.com",
    messagingSenderId: "337593555009",
    appId: "1:337593555009:web:6bc12bf96d00005cf48ec0",
    measurementId: "G-DSJRD2QR53"
  };


  firebase.initializeApp(CLIENT_CONFIG);
}

const db = firebase.firestore();
const col = 'invites';
const auth = firebase.auth();

export const createInvite = async invite => {
    const courseRef = db.collection(col).doc();
    const courseObj = {
      template : {
          name : "d0T4UWq8EKaAv3y7JLar",
          data : {
              email: invite.email,
              nameMei:invite.nameMei,
              nameSei:invite.nameSei,
              tempPassword: "WMgQvERV"
          }
      },
      to : invite.email
    }
    await courseRef.set(courseObj);
    return courseObj;
  };