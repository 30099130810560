import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function TemplateAddModal(props) {
    const [templateName, setTemplateName] = useState('');

    const handleSave = () =>{
        props.onCreate(templateName);
        setTemplateName('');
    }

    return (
        <>
            <Dialog open={props.open} onClose={props.close} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">テンプレートを作成</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="テンプレート名を入力"
                        type="text"
                        value={templateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} color="primary">
                        キャンセル
          </Button>
                    <Button onClick={()=>handleSave()} color="primary">
                        作成
          </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}