import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Marquee  from 'react-double-marquee';
import DeleteTemplateModal from './DeleteTemplateModal';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OverRide = (props) => {
    return (<Dialog
        open={props.open}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          テンプレート上書しました。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.close()} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>)
}

export default function TemplateSelectModal(props) {
    const classes = useStyles();

    const [overOpen, setOverOpen] = React.useState(false);

    const handleClick = (template) => {
        props.templateSelected(template);
    }

    const handleDeleteClick = (item) => {
        setSelDeleteTemplate(item.id);
        setSelDeleteConfirmModalOpen(true);
    }

    const submitDelete = () =>{
        props.submitDelete(selDeleteTemplate);
    }

    const submitOverride = (template) => {
        props.submitOverride(template).then(()=>{
            setOverOpen(true);
        });
    }

    const [selDeleteTemplate, setSelDeleteTemplate] = useState(null);
    const [selDeleteConfirmModalOpen, setSelDeleteConfirmModalOpen] = useState(false);

    const renderTemplateList = () =>{

        if(props.templates.length === 0){
            return (<ListItem>
                <ListItemText primary="テンプレートはまだございません。" />
             </ListItem>)
        }
        
        return props.templates.map(
            (template)=>(
            <ListItem key={template.id} className="template-level">
                <div className="template-name"><Marquee direction="left" delay={0} speed="0.02" scrollWhen="overflow">{template.name}</Marquee></div>
                <ListItemSecondaryAction className="template-actions">
                <button
                    className="edit"
                    onClick={() => handleClick(template)}
                >
                    適用
                </button>
                <button
                    className="edit"
                    onClick={() => submitOverride(template)}
                >
                    上書
                </button>
                <button
                    className="delete"
                    onClick={() => handleDeleteClick(template)}
                >
                    削除
                </button>
                </ListItemSecondaryAction>
                <OverRide open={overOpen} close={()=>{setOverOpen(false)}} />
            </ListItem>
        )
        )
    }

    return (
        <>
            <Dialog fullWidth　maxWidth="sm"　open={props.open} onClose={props.close} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">テンプレート</DialogTitle>
                <DialogContent>
                    <List>
                        {renderTemplateList()}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} color="danger">
                        キャンセル
          </Button>
          <Button onClick={props.new} color="primary">
                        新規登録
          </Button>
                </DialogActions>
            </Dialog>
            <DeleteTemplateModal 
            open={selDeleteConfirmModalOpen}
            close={()=>{
                setSelDeleteConfirmModalOpen(false);
                setSelDeleteTemplate(null)
            }}
            onClose={()=>{
                setSelDeleteConfirmModalOpen(false);
                setSelDeleteTemplate(null)
            }}
            submit={submitDelete}
        />
        </>
    );
}