import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { useLocation } from 'react-router-dom';

const moment = extendMoment(Moment);
moment.locale('ja', {
  week: {
    dow: 1,
    doy: 1,
  },
});
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import './Gant.scoped.scss';
import {
  createYearlyEvent,
  updateYearlyEvent,
  getYearlyEvents,
  deleteYearlyEvent,
} from '../../../api/yearlyEvents';
import NumberFormat from 'react-number-format';
import '../../../libs/dhtmlxgant.css';
import '../../../libs/controls_styles.css';
import '../../../libs/customstyles.css';

const gantScript = require('../../../libs/dhtmlxgantt.js');

import {
  getProgressRate,
  getTaskTotalHours,
} from '../../../api/yearlyEvents';

function getISOWeekInMonth(date) {
  // Copy date so don't affect original
  var d = new Date(+date);
  if (isNaN(d)) return;
  // Move to previous Monday
  d.setDate(d.getDate() - d.getDay() + 1);
  // Week number is ceil date/7
  return {
    month: +d.getMonth() + 1,
    week: Math.ceil(d.getDate() / 7),
  };
}

const Gant = (props) => {
  const { events, gantObj, user } = props;
  const location = useLocation();

  const [view, setView] = useState('week');
  const [showDet, setshowDet] = useState(false);
  const [switchCount, setSwitchCount] = useState(0);

  const [showTime, setShowTime] = useState(true);

  //Utils
  const handleShowTime = (e) => {
    setShowTime(!showTime);
    setshowDet(!showDet);
    gantObj.getGridColumn('editor_holders').hide = !showDet;
    gantObj.getGridColumn('prev_total').hide = !showDet;
    gantObj.render();
  };

  const reloadGantData = () => {
    props.reload().then(() => {
      gantObj.clearAll();
      var lb = document.getElementById('lightbox_here');
      lb.classList.remove('active');
      gantObj.parse(events);
      handleResources();
      gantObj.render();
    });
  };

  const setDayClass = (level = 2) => {
    gantObj.templates.scale_cell_class = function (date) {
      const today = moment();
      const cellDate = moment(date);
      const zoom = level;

      if (zoom === 0) {
        if (
          cellDate >= today.startOf('year') &&
          cellDate <= today.endOf('year')
        ) {
          return 'this-week';
        }
      }
      //月
      if (zoom === 1) {
        if (
          cellDate >= today.startOf('month') &&
          cellDate <= today.endOf('month')
        ) {
          return 'this-week';
        }
      }
      //週
      if (zoom === 2) {
        if (
          cellDate >= today.startOf('week') &&
          cellDate <= today.endOf('week')
        ) {
          return 'this-week';
        }
      }
      //日
      if (zoom === 3) {
        if (
          cellDate >= today.startOf('day') &&
          cellDate <= today.endOf('day')
        ) {
          return 'this-week';
        }
      }
    };
  };

  const resourceConfig = {
    columns: [
      {
        name: 'name',
        label: '',
        tree: true,
        template: function (resource) {
          return resource.text;
        },
      },
    ],
  };

  const handleResources = () => {
    var resourcesStore = gantObj.createDatastore({
      name: gantObj.config.resource_store,
      type: 'treeDatastore',
      initItem: function (item) {
        item.parent = item.parent || gantObj.config.root_id;
        item[gantObj.config.resource_property] = item.parent;
        item.open = true;
        return item;
      },
    });

    resourcesStore.attachEvent('onParse', function () {
      var people = [];
      resourcesStore.eachItem(function (res) {
        if (!resourcesStore.hasChild(res.id)) {
          var copy = gantObj.copy(res);
          copy.key = res.id;
          copy.label = res.text;
          people.push(copy);
        }
      });
      gantObj.updateCollection('people', people);
    });

    resourcesStore.parse([{ id: 1, text: '計画', parent: null }]);
  };

  useEffect(() => {
    if (!gantObj) return;
    var lb = document.getElementById('lightbox_here');
    lb.classList.add('active');
    reloadGantData();
  }, [view, showDet, gantObj]);

  //Resources
  useEffect(() => {
    if (!gantObj) return;
    var lb = document.getElementById('lightbox_here');
    lb.classList.remove('active');
    //To Use Css
    gantObj.templates.resource_cell_class = function (
      start_date,
      end_date,
      resource,
      tasks,
    ) {
      var css = [];
      css.push('resource_marker');
    };

    gantObj.templates.resource_cell_value = function (
      start_date,
      end_date,
      resource,
      tasks,
    ) {
      let totalTaskHoursPerColumn = 0;
      let eventProgressTotalHours = 0;

      const currentZoom = gantObj.ext.zoom.getCurrentLevel();

      for (var i = 0; i < tasks.length; i++) {
        let overlapedColumnNum;
        let sD = moment(tasks[i].start_date);
        let eD = moment(tasks[i].end_date);

        if (currentZoom === 0) {
          overlapedColumnNum = Math.ceil(
            eD
              .clone()
              .subtract(1, 'day')
              .endOf('year')
              .diff(sD.clone().startOf('year'), 'years', true),
          );

        }
        if (currentZoom === 1) {
          overlapedColumnNum = Math.ceil(
            eD
              .clone()
              .subtract(1, 'day')
              .endOf('month')
              .diff(sD.clone().startOf('month'), 'months', true),
          );

        }
        if (currentZoom === 2) {
          overlapedColumnNum = Math.ceil(
            eD
              .clone()
              .subtract(1, 'day')
              .endOf('week')
              .diff(sD.clone().startOf('week'), 'weeks', true),
          );

        }
        if (currentZoom === 3) {
          overlapedColumnNum = Math.ceil(
            eD
              .clone()
              .subtract(1, 'day')
              .endOf('day')
              .diff(sD.clone().startOf('day'), 'days', true),
          );
        }
        if (
          overlapedColumnNum === 0 ||
          overlapedColumnNum === 'NaN'
        ) {
          overlapedColumnNum = 1;
        }

        totalTaskHoursPerColumn +=
          getTaskTotalHours(tasks[i]) / overlapedColumnNum;
      }

      const retval = totalTaskHoursPerColumn;
      if (resource.id === 1) {
        return `<div class="resource_obj_item">${!isNaN(retval) ? Math.ceil(retval) : 0
          }時間</div>`;
      }
    };

    gantObj.config.layout = {
      css: 'gantt_container',
      rows: [
        {
          cols: [
            { view: 'grid', group: 'grids', scrollY: 'scrollVer' },
            {
              view: 'timeline',
              scrollX: 'scrollHor',
              scrollY: 'scrollVer',
            },
            { view: 'scrollbar', id: 'scrollVer', group: 'vertical' },
          ],
          gravity: 2,
        },
        {
          config: resourceConfig,
          cols: [
            {
              view: 'resourceGrid',
              group: 'grids',
              width: 435,
              scrollY: 'resourceVScroll',
            },
            {
              view: 'resourceTimeline',
              scrollX: 'scrollHor',
              scrollY: 'resourceVScroll',
            },
            {
              view: 'scrollbar',
              id: 'resourceVScroll',
              group: 'vertical',
            },
          ],
          gravity: 1,
        },
        { view: 'scrollbar', id: 'scrollHor' },
      ],
    };

    gantObj.config.resource_store = 'resource';
    gantObj.config.resource_property = 'owner_id';
  }, [gantObj, events]);

  //Init Gant
  useEffect(() => {
    if (!gantObj) return;
    //CONFIG
    var colHeader =
      '<div class="gantt_grid_head_cell gantt_grid_head_add" onclick="gantt.createTask()"></div>';
    const zoomConfig = {
      levels: [
        {
          name: 'year',
          start_date: moment()
            .startOf('month')
            .subtract(2, 'years')
            .toDate(),
          end_date: moment()
            .startOf('month')
            .add(2, 'years')
            .toDate(),
          scales: [
            {
              unit: 'year',
              step: 1,
              format: function (date) {
                return moment(date).format('YYYY') + '年';
              },
            },
          ],
        },
        {
          name: 'month',
          start_date: moment()
            .startOf('year')
            .subtract(2, 'years')
            .toDate(),
          end_date: moment().startOf('year').add(2, 'years').toDate(),
          scales: [
            {
              unit: 'month',
              step: 1,
              format: function (date) {
                return (
                  moment(date).format('YYYY') +
                  '年' +
                  moment(date).format('MM') +
                  '月'
                );
              },
            },
          ],
        },
        {
          name: 'week',
          start_date: moment()
            .startOf('year')
            .subtract(2, 'years')
            .toDate(),
          end_date: moment().endOf('year').add(2, 'years').toDate(),
          scales: [
            {
              unit: 'week',
              step: 1,
              format: function (date) {
                return (
                  +date.getFullYear() +
                  '年' +
                  getISOWeekInMonth(date).month +
                  '月<br/>' +
                  getISOWeekInMonth(date).week +
                  '週目'
                );
              },
            },
          ],
        },
        {
          name: 'day',
          start_date: moment()
            .startOf('month')
            .subtract(2, 'years')
            .toDate(),
          end_date: moment()
            .startOf('month')
            .add(2, 'years')
            .toDate(),
          scales: [
            {
              unit: 'day',
              step: 1,
              format: function (date) {
                return (
                  moment(date).format('YYYY') +
                  '年' +
                  moment(date).format('MM') +
                  '月<br/>' +
                  moment(date).format('DD') +
                  '日'
                );
              },
            },
          ],
        },
      ],
    };
    gantObj.ext.zoom.init(zoomConfig);
    gantObj.ext.zoom.setLevel('week');
    gantObj.config.columns = [
      {
        name: 'text',
        label: 'タスク',
        tree: true,
        width: 80,
        resize: true,
        min_width: 10,
      },
      {
        name: 'prev_total',
        hide: showDet,
        label: '目標時間',
        template: function (obj) {
          if (obj.type === 'project') {
            return (
              (obj.totalHours ? Math.ceil(obj.totalHours) : 0) +
              '時間'
            );
          } else {
            return '';
          }
        },
      },
      {
        name: 'editor_holders',
        hide: showDet,
        label: '計画時間',
        template: function (obj) {
          if (obj.type === 'project') {
            let ymTotal = 0;
            gantObj.eachTask(function (task) {
              if (task.type !== 'project') {
                ymTotal += getTaskTotalHours(task);
              }
            }, obj.id);
            return (
              (!isNaN(ymTotal) ? Math.ceil(ymTotal) : 0) + '時間'
            );
          } else {
            return (
              (!isNaN(getTaskTotalHours(obj))
                ? Math.ceil(getTaskTotalHours(obj))
                : 0) + '時間'
            );
          }
        },
      },
      {
        name: 'buttons',
        label: colHeader,
        width: 65,
        resize: true,
        template: (task) => {
          return (
            '<i class="fa gantt_button_grid gantt_grid_edit fa-files-o" onclick="(function(){var task = gantt.getTask(' +
            task.id +
            '); var clone = gantt.copy(task); clone.id = +(new Date()); clone.progress=0; clone.weekTask = []; gantt.addTask(clone, clone.parent, clone.$index)})();"></i>' +
            '<i class="fa gantt_button_grid gantt_grid_add fa-plus" onclick="gantt.createTask(null, ' +
            task.id +
            ');"></i>'
          );
        },
      },
    ];
    gantObj.config.start_on_monday = true;
    gantObj.config.show_links = false;
    gantObj.config.scale_height = 38 * 1;
    gantObj.config.order_branch = true;
    gantObj.config.order_branch_free = true;
    gantObj.config.drag_progress = false;
    gantObj.config.show_tasks_outside_timescale = true;
    gantObj.config.auto_types = true;
    gantObj.config.min_duration = 24 * 60 * 60 * 1000 * 1;
    gantObj.config.types = {
      task: 'task',
      project: 'project',
      routine: 'routine',
    };
    gantObj.config.grid_elastic_columns = false;
    gantObj.config.lightbox.project_sections = [
      {
        name: 'description',
        height: 70,
        map_to: 'text',
        type: 'textarea',
        focus: true,
      },
    ];
    gantObj.config.lightbox.sections = [
      {
        name: 'type',
        type: 'typeselect',
        map_to: 'type',
        filter: function (item) {
          return item != 'project';
        },
      },
      {
        name: 'description',
        height: 38,
        map_to: 'text',
        type: 'desc',
        focus: true,
      },
      {
        name: 'editor_holders',
        type: 'totalHours1',
        map_to: 'auto',
        height: 38,
      },
      {
        name: 'time',
        type: 'time',
        map_to: 'auto',
        height: 38,
        time_format: ['%Y', '%m', '%d'],
      },
    ];
    gantt.config.lightbox.routine_sections = [
      {
        name: 'type',
        type: 'typeselect',
        map_to: 'type',
        filter: function (item) {
          return item != 'project';
        },
      },
      {
        name: 'description',
        height: 38,
        map_to: 'text',
        type: 'desc',
        focus: true,
      },
      {
        name: 'editor_holders',
        type: 'totalHours2',
        map_to: 'auto',
        height: 38,
      },
      {
        name: 'time',
        type: 'time',
        map_to: 'auto',
        height: 38,
        time_format: ['%Y', '%m', '%d'],
      },
    ];

    //TEMPLATES
    gantObj.form_blocks['desc'] = {
      render: function (sns) {
        return "<div class='gantObj_cal_ltext' style='height:120px;'>タスク名 <input class='editor_description' type='text'></div>";
      },
      set_value: function (node, value, task) {
        node.querySelector('.editor_description').value = value || '';
      },
      get_value: function (node, task) {
        return node.querySelector('.editor_description').value;
      },
      focus: function (node) {
        var a = node.querySelector('.editor_description');
        a.select();
        a.focus();
      },
    };
    gantObj.form_blocks['totalHours1'] = {
      render: function (sns) {
        return "<div class='gantObj_cal_ltext' style='height:80px;'>時間設定 <input class='editor_holders' type='number'> 時間</div>";
      },
      set_value: function (node, value, task) {
        node.querySelector('.editor_holders').value =
          task.totalHours || 0;
      },
      get_value: function (node, task) {
        return (task.totalHours = node.querySelector(
          '.editor_holders',
        ).value);
      },
    };
    gantObj.form_blocks['totalHours2'] = {
      render: function (sns) {
        return "<div class='gantObj_cal_ltext' style='height:80px;'>時間設定 <input class='hpd' type='number'> 分／日　x　<input class='dpw' type='number'> 日／週</div>";
      },
      set_value: function (node, value, task) {
        node.querySelector('.hpd').value = task.hpd || 0;
        node.querySelector('.dpw').value = task.dpw || 0;
      },
      get_value: function (node, task) {
        const hpd = Number(node.querySelector('.hpd').value);

        const dpw = Number(node.querySelector('.dpw').value);

        task.hpd = hpd;

        task.dpw = dpw;

        return (task.totalHours = (hpd * dpw) / 60);
      },
    };
    gantObj.templates.grid_row_class = function (start, end, task) {
      if (task.$level > 0) {
        return 'nested_task';
      }
      return '';
    };
    gantObj.templates.task_class = function (start, end, task) {
      if (task.type == 'routine') {
        return 'mission_task';
      }
      return '';
    };
    gantObj.templates.task_text = function (start, end, task) {
      const displayText = isNaN(
        Math.ceil(getProgressRate(task, gantObj)),
      )
        ? 0
        : Math.ceil(getProgressRate(task, gantObj));
      return task.text + ' - ' + displayText + '%';
    };
    gantObj.templates.lightbox_header = function (
      start_date,
      end_date,
      task,
    ) {
      return '新しいタスクの登録';
    };

    //METHODS
    async function createGantTask(event) {
      displayParentJiseki(event);
      await createYearlyEvent({
        id: event.id.toString(),
        end_date: event.end_date,
        parent: event.parent,
        progress: event.progress,
        start_date: event.start_date,
        text: event.text,
        type: event.type,
        user: props.user,
        weekTask: event.weekTask ? event.weekTask : [],
        totalHours: getTaskTotalHours(event),
        dpw: event.dpw ? event.dpw : 0,
        hpd: event.hpd ? event.hpd : 0,
        owner_id: [1, 2, 3],
        parentText: event.parentText ? event.parentText : '',
      });
    }

    async function updateGantTask(event) {
      if (!event || !event.user) return false;

      event.totalHours = getTaskTotalHours(event);

      gantObj.eachTask((task) => {
        let taskParentObj;
        if (task.parent != 0) taskParentObj = gantObj.getTask(task.parent);
        if (taskParentObj?.type === 'routine') {
          taskParentObj.type === 'project';
          gantObj.render();
          updateYearlyEvent({
            id: taskParentObj.id.toString(),
            type: 'project',
          })
        }
        task.parentText = taskParentObj?.text || '';
        updateYearlyEvent({
          id: task.id.toString(),
          parentText: taskParentObj?.text || '',
        });
      });

      displayParentJiseki();

      await updateYearlyEvent({
        id: event.id.toString(),
        start_date: event.start_date,
        end_date: event.end_date,
        parent: event.parent,
        progress: event.progress,
        text: event.text,
        type: event.type,
        user: event.user,
        totalHours: event.totalHours
          ? event.totalHours
          : getTaskTotalHours(event),
        dpw: event.dpw ? event.dpw : 0,
        hpd: event.hpd ? event.hpd : 0,
        weekTask: event.weekTask ? event.weekTask : [],
      }).then(() => {
        gantObj.render();
      });

      gantObj.templates.lightbox_header = function () {
        return '新しいタスクの登録';
      };
    }

    async function deleteGantTask(id) {
      deleteYearlyEvent(id.toString()).then(() => {
        gantObj.eachTask(function (task) {
          if (task.type === 'project') return;
          displayParentJiseki(task);
        });
        gantObj.templates.lightbox_header = function (
          start_date,
          end_date,
          task,
        ) {
          return '新しいタスクの登録';
        };
      });
    }

    function displayParentJiseki() {
      gantObj.eachTask((event) => {
        if (event.type === 'project') {
          event.progress = getProgressRate(event, gantObj) / 100;
        }
      });
    }

    //EVENTS

    /*PARSE*/
    gantObj.attachEvent('onParse', function () {
      gantObj.eachTask(function (task) {
        if (task.type === 'project') return;
        displayParentJiseki(task);
      });
      return true;
    });

    /*CREATE*/
    gantObj.attachEvent(
      'onLightboxSave',
      async function (id, event, is_new) {
        if (event.parent != 0) {
          let parentTask = gantObj.getTask(event?.parent);
          parentTask.type = 'project';
          updateGantTask(parentTask).then(() => {
            gantObj.render();
          });
          gantObj.eachParent(function (parentObj) {
            if (parentObj.id === event.parent) {
              event.parentText = parentObj?.text || '';
            }
          }, event.id);
        }
        if (is_new) {
          createGantTask(event).then(() => {
            gantObj.render();
          });
        } else {
          updateGantTask(event).then(() => {
            gantObj.render();
          });
        }
        gantObj.templates.lightbox_header = function (
          start_date,
          end_date,
          task,
        ) {
          return '新しいタスクの登録';
        };
        var lb = document.getElementById('lightbox_here');
        lb.classList.remove('active');
        return true;
      },
    );

    gantObj.attachEvent('onAfterTaskAdd', function (id, event) {
      event.user = props.user;
      gantObj.eachTask((task) => {
        let taskParentObj;
        if (task.parent != 0) taskParentObj = gantObj.getTask(task.parent)
        updateYearlyEvent({
          id: task.id.toString(),
          parentText: taskParentObj?.text || '',
        });
      });
      createGantTask(event).then(() => {
        gantObj.render();
      });
    });

    /*UPDATE*/
    gantObj.attachEvent('onRowDragEnd', function (id, target) {
      var event = gantObj.getTask(id);
      if (event.parent !== 0) {
        let parentTask = gantObj.getTask(event?.parent);
        parentTask.type = 'project';
        updateGantTask(parentTask).then(() => {
          gantObj.render();
        });
      }
      event.user = props.user;
      updateGantTask(event).then(() => {
        gantObj.render();
      });
      return true;
    });

    gantObj.attachEvent('onAfterTaskUpdate', function (id, event) {
      var lb = document.getElementById('lightbox_here');
      lb.classList.remove('active');
      event.user = props.user;
      updateGantTask(event).then(() => {
        gantObj.render();
      });
      return true;
    });

    /*DELETE*/
    gantObj.attachEvent('onBeforeTaskDelete', function (id, item) {
      gantObj.eachTask((task) => {
        gantObj.eachParent(function (task) {
          var children = gantt.getChildren(task.id).filter((item) => {
            return item.type !== 'project' && item.id !== id;
          });
          var child_progress = 0;
          for (var i = 0; i < children.length; i++) {
            var child = gantt.getTask(children[i]);
            child_progress += child.progress * 100;
          }
          task.progress = child_progress / children.length / 100;
        }, id);
        deleteGantTask(task.id).then(() => {
          gantObj.render();
        });
      }, id);
      return true;
    });
    gantObj.attachEvent('onAfterTaskDelete', function (id, item) {
      var lb = document.getElementById('lightbox_here');
      lb.classList.remove('active');
      deleteGantTask(id).then(() => {
        gantObj.eachTask(function (task) {
          if (task.type === 'project') return;
          displayParentJiseki(task);
        });
        gantObj.render();
      });
      return true;
    });

    /*LIGHTBOX*/
    gantObj.attachEvent('onLightboxChange', function () {
      var lb = document.getElementById('lightbox_here');
      lb.classList.add('active');
      var box = gantObj.getLightbox();
      var container = document.getElementById('gantObj_here');
      var backdrop = document.getElementsByClassName(
        'gantt_cal_cover',
      )[0];
      if (box.parentNode != container) {
        container.appendChild(box);
        container.appendChild(backdrop);
      }
    });
    gantObj.attachEvent('onLightbox', function () {
      var box = gantObj.getLightbox();
      var container = document.getElementById('gantObj_here');
      var lb = document.getElementById('lightbox_here');
      lb.classList.add('active');
      var backdrop = document.getElementsByClassName(
        'gantt_cal_cover',
      )[0];
      if (box.parentNode != container) {
        container.appendChild(box);
        container.appendChild(backdrop);
      }
    });
    gantObj.attachEvent('onLightboxCancel', function (id) {
      var lb = document.getElementById('lightbox_here');
      lb.classList.remove('active');
      gantObj.templates.lightbox_header = function (
        start_date,
        end_date,
        task,
      ) {
        return '新しいタスクの登録';
      };
      gantObj.render();
    });
    gantObj.attachEvent('onTaskDblClick', function (id, e) {
      var lb = document.getElementById('lightbox_here');
      lb.classList.add('active');
      gantObj.templates.lightbox_header = function (
        start_date,
        end_date,
        task,
      ) {
        return (
          task.text +
          ' - (' +
          moment(start_date).format('YYYY年MM月DD日') +
          '~' +
          moment(end_date).format('YYYY年MM月DD日') +
          ') ' +
          task.totalHours +
          '時間'
        );
      };
      gantObj.render();
      return true;
    });

    /*ZOOM*/
    gantObj.ext.zoom.attachEvent(
      'onAfterZoom',
      function (level, config) {
        setDayClass(level);
        gantObj.render();
        return true;
      },
    );

    //ローカライゼーション
    gantObj.i18n.setLocale('jp');
    gantObj.locale.labels.new_task = '新しいタスク';
    gantObj.locale.labels.section_description = '';
    gantObj.locale.labels.section_period = '期間';
    gantObj.locale.labels.type_task = 'ミッション';
    gantObj.locale.labels.type_routine = 'ルーチン';
    gantObj.locale.labels.type_project = 'フォルダー';
    gantObj.locale.labels.confirm_deleting =
      'タスクが完全に削除されます、宜しいですか?';

    //INIT
    setDayClass();
    gantObj.init('gantObj_here');
    gantObj.parse(events);
  }, [gantObj, user]);

  useEffect(() => {
    if (!gantObj) return;
    gantObj.clearAll();
    gantObj.parse(events);
    handleResources();
    gantObj.render();
  }, [events]);

  useEffect(() => {
    if (!gantObj) return;
    gantObj.ext.zoom.setLevel(view);
  }, [view]);

  return (
    <>
      <div className="gant-modal-body">
        <div className="gant-wrapper">
          <div className="gantObj_control">
            <div className="title-toggle">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showTime}
                      onChange={() => handleShowTime()}
                      name="show-time"
                    />
                  }
                  label="時間表示"
                />
              </FormGroup>
            </div>
            <div className="inputs">
              <input
                className={view === 'year' ? 'active' : null}
                type="button"
                value="年"
                onClick={() => {
                  setView('year');
                }}
              />
              <input
                className={view === 'month' ? 'active' : null}
                type="button"
                value="月"
                onClick={() => {
                  setView('month');
                }}
              />
              <input
                className={view === 'week' ? 'active' : null}
                type="button"
                value="週"
                onClick={() => {
                  setView('week');
                }}
              />
              <input
                className={view === 'day' ? 'active' : null}
                type="button"
                value="日"
                onClick={() => {
                  setView('day');
                }}
              />
            </div>
          </div>
          <div className="main-content">
            <div
              id="gantObj_here"
              style={{
                width: '100%',
                height: '100%',
                padding: '0px',
              }}
            ></div>
            <div id="lightbox_here" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Gant;
