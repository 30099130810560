import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import { LiffProvider } from 'react-liff';
import './index.scss';

import * as serviceWorker from './serviceWorker';

import App from './views/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import {
  FirebaseAppProvider,
  useFirestoreDocData,
  useFirestore,
} from "reactfire";

import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faUser, faUsers, faUserCog } from '@fortawesome/free-solid-svg-icons'

library.add(fas, faUser, faUsers, faUserCog )

const config = {
  apiKey: "AIzaSyDeV1gVgtuJzbKfdmdIMXDu2Fy6XUmxTq4",
  authDomain: "future-log-67799.firebaseapp.com",
  projectId: "future-log-67799",
  storageBucket: "future-log-67799.appspot.com",
  messagingSenderId: "337593555009",
  appId: "1:337593555009:web:6bc12bf96d00005cf48ec0",
  measurementId: "G-DSJRD2QR53"
};

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <FirebaseAppProvider firebaseConfig={config}>
      <LiffProvider liffId="1655640313-3Oo2Og4w" stubEnabled={(process.env.NODE_ENV === "development")}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <App />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </LiffProvider>
    </FirebaseAppProvider>
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
