import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import * as ROUTES from '../constants/routes';
import { withAuthentication } from '../components/Session';

//common
import Home from './Home';
import PasswordForgot from './PasswordForgot';
import PasswordReset from './PasswordReset';
import MyPage from './MyPage';
import Teachers from './Teachers';
import Students from './Students';
import Student from './Student';
import StudentWeekly from './StudentWeekly';
import Masters from './Masters';

//LINE
import LineHome from './LINE/LineHome';
import LineCalendar from './LINE/LineCalendar';
import LineRegister from './LINE/LineRegister';

const App = () => (
  <Router>
    <div>
      <Route exact path={ROUTES.HOME} component={Home} />
      <Route exact path={ROUTES.MY_PAGE} component={MyPage} />
      <Route exact path={ROUTES.TEACHERS} component={Teachers} />
      <Route exact path={ROUTES.STUDENTS} component={Students} />
      <Route exact path={ROUTES.STUDENTS + '/:id'} component={Student} />
      <Route exact path={ROUTES.MASTERS} component={Masters} />
      <Route exact path={ROUTES.STUDENTS + '/:id/weekly'} component={StudentWeekly} />
      <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgot} />
      <Route exact path={ROUTES.PASSWORD_RESET} component={PasswordReset} />
      <Route exact path={ROUTES.LINE_HOME} component={LineHome} />
      <Route exact path={ROUTES.LINE_CALENDAR} component={LineCalendar} />
      <Route exact path={ROUTES.LINE_REGISTER} component={LineRegister} />
    </div>
  </Router>
);

export default withAuthentication(App);
