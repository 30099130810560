import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
moment.locale('ja', {
    week: {
        dow: 1,
        doy: 1,
    },
});

import "./MoveEventModal.scoped.scss";

const MoveEventModal = (props) => {
    const { updateEvent, close, event } = props;

    const startOfWeek = moment(event.start).startOf('week').set({
        hour: 0,
        minute: 0,
        second: 0
    });
    const endOfWeek = moment(event.start).endOf('week').set({
        hour: 23,
        minute: 59,
        second: 59
    });

    const up = () => {
        const oldEv = { ...event };
        const startT = moment(oldEv.start);
        const endT = moment(oldEv.end);
        const durationMins = endT.diff(startT, 'minutes');
        if (startT.get('hour') === 0 && startT.get('minute') <= 30) {
            oldEv.start = startT.clone().set({
                hour: 0,
                minute: 0,
                second: 0
            }).toDate();
            oldEv.end = moment(oldEv.start)
                .clone()
                .add('minutes', durationMins)
                .toDate();
        } else {
            oldEv.start = startT.subtract(30, 'minutes').toDate();
            oldEv.end = endT.subtract(30, 'minutes').toDate();
        }
        updateEvent(oldEv);
    }

    const down = () => {
        const oldEv = { ...event };
        const startT = moment(oldEv.start);
        const endT = moment(oldEv.end);
        const durationMins = endT.diff(startT, 'minutes');
        if (endT.get('hour') === 23 && endT.get('minute') >= 30) {
            oldEv.end = endT.clone().set({
                hour: 23,
                minute: 59,
                second: 59
            }).toDate();
            oldEv.start = moment(oldEv.end)
                .clone()
                .subtract('minutes', durationMins)
                .toDate();
        } else {
            oldEv.start = startT.add(30, 'minutes').toDate();
            oldEv.end = endT.add(30, 'minutes').toDate();
        }

        updateEvent(oldEv);
    }

    const left = () => {
        const oldEv = { ...event };
        const startT = moment(oldEv.start);
        const endT = moment(oldEv.end);
        const firstdayofweek = startT.clone().startOf('week').date();
        if (startT.date() > firstdayofweek) {
            oldEv.start = startT.subtract(1, 'day').toDate();
            oldEv.end = endT.subtract(1, 'day').toDate();
            updateEvent(oldEv);
        }
    }

    const right = () => {
        const oldEv = { ...event };
        const startT = moment(oldEv.start);
        const endT = moment(oldEv.end);
        const lastdayofweek = endT.clone().endOf('week').date();
        if (startT.date() < lastdayofweek) {
            oldEv.start = startT.add(1, 'day').toDate();
            oldEv.end = endT.add(1, 'day').toDate();
            updateEvent(oldEv);
        }
    }

    const add = () => {
        const oldEv = { ...event };
        const endT = moment(oldEv.end);
        if (endT.get('hour') === 23 && endT.get('minute') >= 30) {
            oldEv.end = endT.clone().set({
                hour: 23,
                minute: 59,
                second: 59
            }).toDate();
        } else {
            oldEv.end = endT.add(30, 'minutes').toDate();
        }
        updateEvent(oldEv);
    }

    const subtract = () => {
        const oldEv = { ...event };
        const endT = moment(oldEv.end);
        const startT = moment(oldEv.start);
        const durationMins = endT.diff(startT, 'minutes');
        if (durationMins > 30) oldEv.end = endT.subtract(30, 'minutes').toDate();
        updateEvent(oldEv);
    }

    const save = () => {
        close();
    }

    return (
        <div className="event-move-modal">
            <div className="top-buttons">
                <button className="move-button" onClick={() => up()}><i className="fa fa-arrow-up" ariaHidden="true" /></button>
                <button className="move-button" onClick={() => down()}><i className="fa fa-arrow-down" ariaHidden="true" /></button>
                <button className="move-button" onClick={() => left()}><i className="fa fa-arrow-left" ariaHidden="true" /></button>
                <button className="move-button" onClick={() => right()}><i className="fa fa-arrow-right" ariaHidden="true" /></button>
            </div>
            <div className="bottom-buttons">
                <div className="bottom-buttons-top">
                    <button className="length-button" onClick={() => add()}><i className="fa fa-plus" ariaHidden="true" /></button>
                    <button className="length-button" onClick={() => subtract()}><i className="fa fa-minus" ariaHidden="true" /></button>
                </div>
                <div className="bottom-buttons-lower">
                    <button className="ok-button" onClick={() => save()}><i className="fa fa-check-circle" ariaHidden="true" /></button>
                </div>
            </div>
        </div>
    )
}

export default MoveEventModal;