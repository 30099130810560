import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Dropzone from 'react-dropzone';
import csv from 'csv';

import {getUsers} from '../../api/users';

import { Multiselect } from 'multiselect-react-dropdown';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import './dialogues.scoped.scss';
import './userdiag.scss';

export default props => {
  const { departments, schools } = props;

  const [userEmails, setUserEmails] = useState([]);
  useEffect(()=>{
    getUsers().then((data)=>{
      let mails = [];
      data.map(item => {
        mails.push(item.email);
      });
      setUserEmails(mails);
    });
  },[])

  const [invites, setInvites] = useState([
    {
      nameSei: '',
      nameMei: '',
      email: '',
      role: '',
      department: [],
      school: [],
    },
  ]);

  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState([]);

  const handleEmailChange = (index, val) => {
    const oldInvites = [...invites];
    oldInvites[index].email = val;
    setInvites(oldInvites);
  };

  const handleNameMeiChange = (index, val) => {
    const oldInvites = [...invites];
    oldInvites[index].nameMei = val;
    setInvites(oldInvites);
  };

  const handleNameSeiChange = (index, val) => {
    const oldInvites = [...invites];
    oldInvites[index].nameSei = val;
    setInvites(oldInvites);
  };

  const handleRoleChange = (index, val) => {
    const oldInvites = [...invites];
    oldInvites[index].role = val;
    setInvites(oldInvites);
  };

  const handleDepartmentChange = (index, selectedList) => {
    const oldInvites = [...invites];
    const sel = [];
    selectedList.map(item => {
      sel.push(item.id);
    });
    oldInvites[index].department = sel;
    setInvites(oldInvites);
  };

  const handleSchoolChange = (index, selectedList) => {
    const oldInvites = [...invites];
    const sel = [];
    selectedList.map(item => {
      sel.push(item.id);
    });
    oldInvites[index].school = sel;
    setInvites(oldInvites);
  };

  const addInvite = () => {
    const oldInvites = [...invites];
    oldInvites.push({
      nameSei: '',
      nameMei: '',
      email: '',
      role: '',
      department: [],
      school: [],
      isError: false,
      errorMessage: '',
      errorType: '',
    });
    setInvites(oldInvites);
  };

  const removeInvite = index => {
    const oldInvites = [...invites];
    oldInvites.splice(index, 1);
    setInvites(oldInvites);
  };

  const validateEmails = () => {
    const oldInvites = [...invites];
    let isError = false;
    for (let i = 0; i < oldInvites.length; i++) {
      const same = oldInvites.filter(
        invite => invite.email === oldInvites[i].email,
      );
      if (oldInvites[i].email === '') {
        oldInvites[i].isError = true;
        oldInvites[i].errorMessage = 'メールアドレスは必須です。';
        oldInvites[i].errorType = 'email';
        isError = true;
        continue;
      }
      if(userEmails.includes(oldInvites[i].email)){
        oldInvites[i].isError = true;
        oldInvites[i].errorMessage = 'このメールアドレスはすでに使用されております。';
        oldInvites[i].errorType = 'email';
        isError = true;
        continue;
      }
      if (!ValidateEmail(oldInvites[i].email)) {
        oldInvites[i].isError = true;
        oldInvites[i].errorMessage =
          'メールアドレスは正式ではありません。';
        oldInvites[i].errorType = 'email';
        isError = true;
        continue;
      }
      if (same.length >= 2) {
        oldInvites[i].isError = true;
        oldInvites[i].errorMessage =
          'メールアドレスがすでにリストに入っています';
        oldInvites[i].errorType = 'email';
        isError = true;
        continue;
      }

      isError = false;
      oldInvites[i].errorMessage = '';
      oldInvites[i].errorType = '';
    }
    setInvites(oldInvites);
    return isError;
  };

  const validateNames = () => {
    const oldInvites = [...invites];
    let isError = false;
    for (let i = 0; i < oldInvites.length; i++) {
      if (oldInvites[i].nameSei === '') {
        oldInvites[i].isError = true;
        oldInvites[i].errorMessage = '姓は必須です。';
        oldInvites[i].errorType = 'nameSei';
        isError = true;
        continue;
      }
      if (oldInvites[i].nameMei === '') {
        oldInvites[i].isError = true;
        oldInvites[i].errorMessage = '名は必須です。';
        oldInvites[i].errorType = 'nameMei';
        isError = true;
        continue;
      }
      isError = false;
      oldInvites[i].errorMessage = '';
      oldInvites[i].errorType = '';
    }
    setInvites(oldInvites);
    return isError;
  };

  const validateRoles = () => {
    const oldInvites = [...invites];
    let isError = false;
    for (let i = 0; i < oldInvites.length; i++) {
      if (oldInvites[i].role == '') {
        oldInvites[i].isError = true;
        oldInvites[i].errorMessage = '種別は必須です。';
        oldInvites[i].errorType = 'role';
        isError = true;
        continue;
      }
      isError = false;
      oldInvites[i].errorMessage = '';
      oldInvites[i].errorType = '';
    }
    setInvites(oldInvites);
    return isError;
  };

  const [mainErrors, setMailErrors] = useState([]);

  const handleSubmit = async () => {
    if (!validateEmails() && !validateNames() && !validateRoles()) {
      await props.submit(invites).then((res) => {
        let isError = false;
        const errors = [];
        res.map((resItem, index)=>{
          if(resItem.name == "Error"){
            const oldInvites = [...invites];
            oldInvites[index].isError = true;
            oldInvites[index].errorMessage = "このメールアドレスはすでに使用されています。";
            oldInvites[index].errorType = "email";
            setInvites(oldInvites);
          }
          isError = true;
        })
        if(isError)return;

        setInvites([
          {
            nameSei: '',
            nameMei: '',
            email: '',
            role: '',
          },
        ]);
      }).catch(err => {
        setMailErrors(err);
        return false;
      })
    }
    return false;
  };

  const renderFields = () => {
    const formFields = [];
    invites.map((invite, index) => {
      formFields.push(
        <div className="form-row-item" key={index}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3}>
              <label className="form-label">メールアドレス</label>
              <input
                className={
                  invites[index].isError &&
                  invites[index].errorType === 'email'
                    ? 'form-input error'
                    : 'form-input'
                }
                type="email"
                value={invites[index].email}
                placeholder="メールアドレス"
                onChange={e =>
                  handleEmailChange(index, e.target.value)
                }
              />
            </Grid>
            <Grid item xs={3}>
              <label className="form-label">お名前</label>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={6}>
                  <input
                    className={
                      invites[index].isError &&
                      invites[index].errorType === 'nameSei'
                        ? 'form-input split error'
                        : 'form-input split'
                    }
                    type="text"
                    value={invites[index].nameSei}
                    placeholder="姓"
                    onChange={e =>
                      handleNameSeiChange(index, e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <input
                    className={
                      invites[index].isError &&
                      invites[index].errorType === 'nameMei'
                        ? 'form-input error'
                        : 'form-input'
                    }
                    type="text"
                    value={invites[index].nameMei}
                    placeholder="名"
                    onChange={e =>
                      handleNameMeiChange(index, e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <label className="form-label">部門</label>
              <Multiselect
                style={{
                  multiselectContainer: {
                    backgroundColor: '#f1f3f4',
                    borderColor: '#f1f3f4',
                    borderWidth: 0,
                  },
                  searchBox: {
                    border: 'none',
                  },
                }}
                placeholder="部門"
                options={departments}
                displayValue="title"
                onSelect={(selectedList, selectedItem) =>
                  handleDepartmentChange(index, selectedList)
                }
                onRemove={(selectedList, selectedItem) =>
                  handleDepartmentChange(index, selectedList)
                }
              />
            </Grid>
            <Grid item xs={2}>
              <label className="form-label">校舎</label>
              <Multiselect
                style={{
                  multiselectContainer: {
                    backgroundColor: '#f1f3f4',
                    borderColor: '#f1f3f4',
                    borderWidth: 0,
                  },
                  searchBox: {
                    border: 'none',
                  },
                }}
                options={schools}
                placeholder="校舎"
                displayValue="title"
                onSelect={(selectedList, selectedItem) =>
                  handleSchoolChange(index, selectedList)
                }
                onRemove={(selectedList, selectedItem) =>
                  handleSchoolChange(index, selectedList)
                }
              />
            </Grid>
            <Grid item xs={2}>
              <label className="form-label">種別</label>
              <select
                className={
                  invites[index].isError &&
                  invites[index].errorType === 'role'
                    ? 'form-input error'
                    : 'form-input'
                }
                onChange={e =>
                  handleRoleChange(index, e.target.value)
                }
                value={invites[index].role}
              >
                <option
                  value=""
                >
                  種別を選択
                </option>
                <option
                  value="teacher"
                >
                  講師
                </option>
                <option
                  value="admin"
                >
                  管理者
                </option>
              </select>
            </Grid>
            {index > 0 ? (
              <Grid item xs={1} style={{ textAlign: 'center' }}>
                <button
                  className="delete"
                  onClick={() => removeInvite(index)}
                >
                  ✖
                </button>
              </Grid>
            ) : null}
          </Grid>
          {invites[index].isError ? (
            <p className="error">{invites[index].errorMessage}</p>
          ) : null}
        </div>,
      );
    });
    return formFields;
  };

  const [files, setFiles] = useState(null);
  const [loadingFile, setLoading] = useState(false);
  const [csvErrors, setCsvErrors] = useState(["hello"]);

  const addDepartmentByName = (department,index) => {
    const search = departments.filter((item)=>{
      return item.title === department.title
    });
    if(search.length === 0){
      return []
    }else{
      return [department.id]
    }
  }

  const addSchoolByName = (school,index) => {
    const search = departments.filter((item)=>{
      return item.title === school.title
    });
    if(search.length === 0){
      return []
    }else{
      return [school.id]
    }
  }

  const onDrop = files => {
    setLoading(true);
    setFiles(files);
    var file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      csv.parse(reader.result, (err, data) => {
        var userList = [];
        for (var i = 0; i < data.length; i++) {
          const email = data[i][0];
          const nameSei = data[i][1];
          const nameMei = data[i][2];
          const school = data[i][3];
          const department = data[i][4];
          const role = data[i][5];

          let dName = addDepartmentByName(department, i);
          let sName = addSchoolByName(school, i);

          const newUser = {
            email: email,
            nameSei: nameSei,
            nameMei: nameMei,
            role: [role],
          };

          if(dName.length === 0){
            newUser.isError = true;
            newUser.errorMessage = 'CSVに登録された部門は正しくありません。';
            newUser.errorType = 'email';
          }
          if(sName.length === 0){
            newUser.isError = true;
            newUser.errorMessage = 'CSVに登録された校舎は正しくありません。';
            newUser.errorType = 'email';
          }

          newUser.department = dName;
          newUser.school = sName;
          
          userList.push(newUser);
        }
        setInvites(userList);
      });
    };
    setLoading(false);
    reader.readAsText(file, "utf-8");
  };
  return (
    <>
      <Dialog
        open={props.show}
        onClose={props.close}
        aria-labelledby="form-dialog-title"
        className="user-diag"
      >
        <DialogTitle id="form-dialog-title">
          ユーザーを登録
        </DialogTitle>
        <DialogContent>
          <div
            className={
              props.loading ? 'loading diag-content' : 'diag-content'
            }
          >
            {renderFields()}
            <div className="add-form-fields">
              <Button
                startIcon={<AddCircleIcon />}
                onClick={addInvite}
              >
                もう一つ追加する
              </Button>
            </div>
            <div className="button-wrap">
              <button
                className="grad-button"
                onClick={() => handleSubmit()}
              >
                登録する
              </button>
            </div>
          </div>
          <div className={loadingFile ? "csv-dropzone loading" : "csv-dropzone"}>
            {loadingFile ? <span className="dropzone-loading"></span> : null}
          <Dropzone accept=".csv" onDrop={onDrop.bind(this)}>
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>こちらにCSVをドラッグする,又はクリックしてファイルを選択して下さい。</p>
                </div>
              </section>
            )}
            </Dropzone>
            <ul>{csvErrors.map((errorItem, index)=>{
                <li className="csv-error" key={index}>{errorItem}</li>
            })}</ul>
          </div>
        </DialogContent>
        
      </Dialog>
    </>
  );
};

const ValidateEmail = mail => {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail,
    )
  ) {
    return true;
  }
  return false;
};