import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import _ from 'lodash';
import 'react-dates/lib/css/_datepicker.css';
import NumberFormat from 'react-number-format';
import './TaskEventDiffTotal.scoped.scss';

import { createMuiTheme } from '@material-ui/core';
import TaskEventSchedRow from './TaskEventSchedRow';
import TaskEventSchedTotal from './TaskEventSchedTotal';
import TaskProgressDiffRow from './TaskProgressDiffRow';
import TaskProgressTotal from './TaskProgressTotal';

import './TaskEventDiffTotal';

import { cleanTaskData } from '../../../api/weeklyEvents';
import { getTaskTotalHours } from '../../../api/yearlyEvents';
import TabPanel from '../TabPanel';

import './TaskEventDiffTotal.scoped.scss';

const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        textAlign: 'center',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

Array.prototype.sum = function (prop) {
  var total = 0;
  for (var i = 0, _len = this.length; i < _len; i++) {
    total += this[i][prop];
  }
  return total;
};

export default function TaskEventDiffTotal(props) {
  const { events, tasks, selDate } = props;

  const [value, setValue] = useState(0);

  const [filteredWeeklyTasks, setFilteredWeeklyTasks] = useState([]);
  const [totalSched, setTotalSched] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [dateRange, setDateRange] = React.useState([
    moment().startOf('week'),
    moment().endOf('week'),
  ]);

  useEffect(() => {
    setDateRange([
      moment(selDate).startOf('week'),
      moment(selDate).endOf('week'),
    ]);
  }, [selDate]);

  const [focusedInput, setFocusedInput] = useState(null);

  let schedMinutesTotal = 0;

  const TaskEventSchedTotalRows = () => {
    const rows = [];
    const weekRange = moment.range(
      moment(selDate).startOf('week'),
      moment(selDate).endOf('week'),
    );

    tasks
      ?.filter((task) => {
        const taskRange = moment.range(
          moment(task.start_date),
          moment(task.end_date),
        );
        return (
          weekRange.overlaps(taskRange) && task.type !== 'project'
        );
      })
      .map((task) => {
        const hasEvent = rows.findIndex((x) => x.id === task.id);

        let sD = moment(task.start_date);
        let eD = moment(task.end_date);
        let overlapedWeekNum = Math.ceil(
          eD
            .clone()
            .subtract(1, 'day')
            .endOf('week')
            .diff(sD.clone().startOf('week'), 'weeks', true),
        );

        const schedMinutes =
          (getTaskTotalHours(task) * 60) / overlapedWeekNum;

        schedMinutesTotal += schedMinutes;

        if (hasEvent == -1) {
          rows.push({
            id: task.id,
            title: task.text,
            totalProgress: 0,
            totalMinutes: 0,
            totalScheduled: schedMinutes,
            parentText: task.parentText ? task.parentText : '',
          });
        } else {
          rows[hasEvent].id = task.id;
          rows[hasEvent].totalScheduled = schedMinutes;
          rows[hasEvent].parentText = task.parentText
            ? task.parentText
            : '';
        }
      });

    if (events) {
      for (const [key, dOW] of Object.entries(events)) {
        dOW?.events
          ?.filter((item) => {
            const taskRange = moment.range(
              moment(item.start),
              moment(item.end),
            );
            const cleanParent = cleanTaskData(item?.yearTask);
            const parentRange = moment.range(moment(cleanParent?.start_date), moment(cleanParent?.end_date))
            if (
              (moment(item.end) < moment() && item.progress === 0) ||
              !moment.range(dateRange).overlaps(taskRange) ||
              !item.yearTask || !taskRange.overlaps(parentRange)
            ) {
              return false;
            } else {
              return true;
            }
          })
          .map((event) => {
            const hasEvent = rows.findIndex(
              (x) => x.id === event?.yearTask?.id,
            );
            const yt = cleanTaskData(event.yearTask);
            const gantEventWeeks = event.yearTask
              ? Math.ceil(
                  moment(yt.end_date).diff(
                    yt.start_date,
                    'weeks',
                    true,
                  ),
                )
              : 1;
            if (hasEvent !== -1) {
              rows[hasEvent].totalProgress += event.progress;
              rows[hasEvent].totalMinutes += Math.ceil(
                moment(event.end).diff(
                  moment(event.start),
                  'minutes',
                  true,
                ),
              );
            } else {
              rows.push({
                id: event?.yearTask?.id || 0,
                title: event.title,
                totalProgress: event.progress,
                totalMinutes: Math.ceil(
                  moment(event.end).diff(
                    moment(event.start),
                    'minutes',
                    true,
                  ),
                ),
                parentText: event.yearTask
                  ? event.yearTask.parentText
                  : '',
              });
            }
          });
      }
    }

    return rows;
  };

  useEffect(() => {
    setTotalSched(schedMinutesTotal);
  }, [events, dateRange, tasks]);

  useEffect(() => {
    const rows = [];
    if (events?.current) {
      for (const [key, dOW] of Object.entries(events.current)) {
        dOW.events
          ?.filter((item) => {
            const taskRange = moment.range(
              moment(item.start),
              moment(item.end),
            );
            const cleanParent = cleanTaskData(item?.yearTask);
            const parentRange = moment.range(moment(cleanParent?.start_date), moment(cleanParent?.end_date))
            if (
              (moment(item.end) < moment() && item.progress === 0) ||
              !moment.range(dateRange).overlaps(taskRange) ||
              !item.yearTask || !taskRange.overlaps(parentRange)
            ) {
              return false;
            } else {
              return true;
            }
          })
          .map((event) => {
            const hasEvent = rows.findIndex(
              (x) => x.id === event?.yearTask?.id,
            );
            const yt = cleanTaskData(event.yearTask);
            const gantEventWeeks = event.yearTask
              ? Math.ceil(
                  moment(yt.end_date).diff(
                    yt.start_date,
                    'weeks',
                    true,
                  ),
                )
              : 1;
            if (hasEvent !== -1) {
              rows[hasEvent].totalProgress += event.progress;
              rows[hasEvent].totalMinutes += Math.ceil(
                moment(event.end).diff(
                  moment(event.start),
                  'minutes',
                  true,
                ),
              );
            } else {
              rows.push({
                id: event?.yearTask?.id || 0,
                title: event.title,
                parentText: event?.yearTask?.parentText || '',
                totalProgress: event.progress,
                totalMinutes: Math.ceil(
                  moment(event.end).diff(
                    moment(event.start),
                    'minutes',
                    true,
                  ),
                ),
                totalScheduled: getTaskTotalHours(event),
              });
            }
          });
      }
    }

    setFilteredWeeklyTasks(rows);
  }, [dateRange, tasks, events]);

  return (
    <>
      <div className="tab-wrapper--header">
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="今週" />
          <Tab label="期間指定" />
        </Tabs>
      </div>
      <div className="taskevent-height">
        <TabPanel value={value} index={0}>
          <table className="check-list">
            <thead>
              <tr>
                <th>タスク</th>
                <th>計画</th>
                <th />
                <th>計画割当</th>
                <th />
                <th>実績</th>
              </tr>
            </thead>
            <tbody>
              {TaskEventSchedTotalRows().map((row) => (
                <TaskEventSchedRow
                  text={row.title}
                  parent={row?.parentText || ''}
                  planned={row.totalScheduled}
                  scheduled={row.totalMinutes}
                  progress={row.totalProgress}
                />
              ))}
              <TaskEventSchedTotal
                scheduledMinutes={totalSched}
                plannedMinutes={
                  events?.current?.totals?.plannedMinutes || 0
                }
                progress={events?.current?.totals?.progress || 0}
              />
            </tbody>
          </table>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="sel-dp">
            <DateRangePicker
              startDate={dateRange[0]}
              startDateId="start_date"
              endDate={dateRange[1]}
              readOnly
              endDateId="end_date"
              onDatesChange={({ startDate, endDate }) => {
                setDateRange([startDate, endDate]);
              }}
              minimumNights={0}
              focusedInput={focusedInput}
              disableScroll={true}
              onFocusChange={(focusedInput) =>
                setFocusedInput(focusedInput)
              }
              verticalHeight={350}
              orientation="vertical"
              hideKeyboardShortcutsPanel={true}
              numberOfMonths={1}
              isOutsideRange={(item) => {
                const day = moment(item);
                const sow = moment(selDate).startOf('week');
                const eow = moment(selDate).endOf('week');
                if (day >= sow && day <= eow) {
                  return false;
                } else {
                  return true;
                }
              }}
            />
          </div>
          <table className="check-list">
            <thead>
              <tr>
                <th>タスク</th>
                <th>計画割当</th>
                <th />
                <th>実績</th>
              </tr>
            </thead>
            <tbody>
              {filteredWeeklyTasks
                .filter((item) => {
                  return item.type !== 'project';
                })
                .map((item) => {
                  return (
                    <TaskProgressDiffRow
                      parent={item.parentText}
                      text={item.title}
                      totalWari={item.totalMinutes}
                      progress={item.totalProgress}
                      keikaku={item.totalSchedule}
                    />
                  );
                })}
              <TaskProgressTotal tasks={filteredWeeklyTasks} />
            </tbody>
          </table>
        </TabPanel>
      </div>
    </>
  );
}
