import React from 'react';
import { withFirebase } from '../../components/Firebase';
import { useLiff } from 'react-liff';

import LineAuthCheck from '../../components/Line/LineAuthCheck/LineAuthCheck';

import "./LineHome.scoped.scss";

const LineHomePage = (props) => {

  const { error, liff, isLoggedIn, ready } = useLiff();

  return (
    <LineAuthCheck>
      <div className="liffLoginWrapper">
          <figure>
            <img src="/flog-logo.png" alt="" />
          </figure>
          <button
            className='grad-button'
            onClick={liff.login}
          >
            ログイン/登録
        </button>
        </div>
    </LineAuthCheck>
  );
}

export default withFirebase(LineHomePage);