import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import DeleteConfirmModal from '../Common/DeleteConfirmModal';
import './DepartmentSchoolLinker.scoped.scss';
import './Masters.scoped.scss';

import SchoolMenu from '../Teachers/SchoolMenu';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        boxSizing: 'border-box'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    schoolList: {
        root: {
            marginBottom: 0
        }
    }
}));

import { createDepartment, updateDepartment, deleteDepartment } from '../../api/departments';
import { createSchool, updateSchool, deleteSchool } from '../../api/schools';

const DepartmentSchoolLinker = (props) => {
    const { departments, schools, reload } = props;
    const classes = useStyles()

    const [selectedDepartment, setSelectedDepartment] = useState(0);
    const [selectedDepartmentObject, setSelectedDepartmentObject] = useState(null);
    const [departmentSchoolList, setDepartmentSchoolList] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState([]);
    const [isDepartmentModal, setIsDepartmentModal] = useState(false);
    const [departmentTitle, setDepartmentTitle] = useState('');
    const [departmentIsEdit, setDepartmentIsEdit] = useState(false);
    const [isDeleteDepartmentConfirm, setIsDeleteDepartmentConfirm] = useState(false);

    const [anchorElSchool, setAnchorElSchool] = React.useState(null);

    useEffect(() => {
        const filSchools = schools.filter((item) => { return selectedDepartmentObject?.schools?.includes(item.id) });
        setDepartmentSchoolList(filSchools ? filSchools : []);

        const filtdeps = departments.filter((item) => { return item.id === selectedDepartment });
        setSelectedDepartmentObject(filtdeps[0]);
    }, [selectedDepartment])

    useEffect(() => {
        setSelectedSchool(selectedDepartmentObject?.schools || []);
    }, [selectedDepartmentObject])

    const handleAddDepartment = () => {
        setIsDepartmentModal(true)
    }

    const handleEditDepartment = () => {
        setDepartmentIsEdit(true);
        setIsDepartmentModal(true);
        setDepartmentTitle(selectedDepartmentObject?.title || '')
    }

    const handleDeleteDepartment = () => {
        setIsDeleteDepartmentConfirm(true)
    }

    const handleCloseDepartmentModal = () => {
        setIsDepartmentModal(false)
        setDepartmentIsEdit(false);
        setDepartmentTitle('')
    }

    const handleSaveDepartment = () => {
        const depObj = { ...selectedDepartmentObject }
        if (!departmentIsEdit) {
            createDepartment({ title: departmentTitle }).then((data) => {
                reload();
                handleCloseDepartmentModal();
            })
        } else {
            depObj.title = departmentTitle;
            depObj.id = selectedDepartment;
            updateDepartment(depObj).then(() => {
                reload();
                handleCloseDepartmentModal();
            })
        }

    }

    const submitDeleteDepartment = () => {
        deleteDepartment(selectedDepartment).then(() => {
            setIsDeleteDepartmentConfirm(false);
            setSelectedDepartment(0);
            reload();
        })

    }


    const newSchoolSubmit = title => {
        createSchool({ title: title });
        props.reload();
    };

    const schoolEditSubmit = school => {
        updateSchool(school);
        props.reload();
    };

    const schoolDelete = id => {
        deleteSchool(id);
        props.reload();
    };

    const schoolSelectClick = loc => {
        const depObj = { ...selectedDepartmentObject };
        if (depObj?.schools?.includes(loc)) {
            depObj.schools = depObj.schools.filter(item => item !== loc);
        } else {
            depObj.schools = depObj.schools ? [loc, ...depObj.schools] : [loc];
        }
        setSelectedDepartment(depObj);
        if (selectedDepartmentObject) {
            updateDepartment(depObj).then(() => {
                props.reload().then(() => {
                    setSelectedDepartmentObject(depObj);
                    setSelectedSchool(depObj.schools)
                })
            })
        }

    };

    return (
        <>
            <div className="master-panel">
                <h2 className="master-panel-title">マスター紐付</h2>
                <div className="department-school-linker">
                    <div className="department-list">
                        <h3>部門</h3>
                        <ListItem>
                            <FormControl className={classes.formControl}>
                                <Select variant="outlined" value={selectedDepartment.id} onChange={(e) => setSelectedDepartment(e.target.value)}>
                                    {departments.map((item) => (<MenuItem onClick={() => setSelectedDepartmentObject(item)} key={item.id} value={item.id}>{item.title}</MenuItem>))}
                                </Select>
                            </FormControl>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDepartment(selectedDepartment)}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" onClick={handleEditDepartment}>
                                <CreateIcon />
                            </IconButton>
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={handleAddDepartment}>
                            <ListItemText
                                primary="部門を追加する"
                            />
                            <AddIcon />
                        </ListItem>
                    </div>
                    <div className="school-list">
                        <h3>校舎</h3>
                        {selectedDepartment ? <List className={classes.schoolList}>
                            {
                                schools.filter((item) => { return selectedDepartmentObject?.schools?.includes(item.id) }).length ? schools.filter((item) => { return selectedDepartmentObject?.schools?.includes(item.id) }).map((item) => {
                                    return (<ListItem key={item.id}>
                                        <ListItemText
                                            primary={item.title}
                                        />
                                    </ListItem>);
                                }) : <p>校舎を追加して下さい。</p>}
                            <Divider />
                            <ListItem button onClick={(e) => setAnchorElSchool(e.currentTarget)}>
                                <ListItemText
                                    primary="校舎を追加する"
                                />
                                <AddIcon />
                            </ListItem>
                        </List> : <p>部門を選択してください。</p>}
                    </div>
                </div>
            </div>
            <Dialog open={isDepartmentModal} onClose={handleCloseDepartmentModal}>
                <DialogTitle id="form-dialog-title">部門{departmentIsEdit ? '編集' : '登録'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        部門名を入力して下さい。
          </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="department"
                        label="部門"
                        type="text"
                        fullWidth
                        value={departmentTitle}
                        onChange={(e) => { setDepartmentTitle(e.target.value) }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDepartmentModal} color="primary">
                        キャンセル
          </Button>
                    <Button onClick={handleSaveDepartment} color="primary">
                        保存
          </Button>
                </DialogActions>
            </Dialog>
            <SchoolMenu
                anchorEl={anchorElSchool}
                locs={schools ? schools : []}
                handleClose={() => setAnchorElSchool(null)}
                editLocSubmit={school => schoolEditSubmit(school)}
                newLocSubmit={title => newSchoolSubmit(title)}
                deleteLocSubmit={schoolDelete}
                schoolSelected={schoolSelectClick}
                sels={selectedSchool?.length ? [...selectedSchool] : []}
            />
            <DeleteConfirmModal open={isDeleteDepartmentConfirm} close={() => setIsDeleteDepartmentConfirm(false)} submit={submitDeleteDepartment} />
        </>
    )
}

export default DepartmentSchoolLinker;