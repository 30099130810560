import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {isEqual} from 'lodash';

const moment = extendMoment(Moment);
moment.locale('ja', {
  week: {
    dow: 1,
    doy: 1,
  },
});

import {
  getYearlyEvent,
  updateYearlyEvent,
  recalculateJiseki,
  addWeeklyTaskAsChild,
  removeWeeklyTaskAsChild,
} from './yearlyEvents';

if (!firebase.apps.length) {
  const CLIENT_CONFIG = {
    apiKey: 'AIzaSyDeV1gVgtuJzbKfdmdIMXDu2Fy6XUmxTq4',
    authDomain: 'future-log-67799.firebaseapp.com',
    projectId: 'future-log-67799',
    storageBucket: 'future-log-67799.appspot.com',
    messagingSenderId: '337593555009',
    appId: '1:337593555009:web:6bc12bf96d00005cf48ec0',
    measurementId: 'G-DSJRD2QR53',
  };

  firebase.initializeApp(CLIENT_CONFIG);
}

const db = firebase.firestore();
const col = 'weeklyEvents';
const auth = firebase.auth();

const hasParent = (event) => {
  const that = event
  if (!that.yearTask) return false;
  if (that.yearTask.type === 'project') return false;
  const parent = cleanTaskData(that.yearTask);
  const st = moment(that.start).startOf('week');
  const en = moment(that.end).endOf('week');
  const taskRange = moment.range(st, en);
  const parentRange = moment.range(
    moment(parent.start_date),
    moment(parent.end_date),
  );

  const overlaps = taskRange.overlaps(parentRange);

  return overlaps;
};

export const defaultWeeklyStructure = {
  mon: {
    events: [],
    totals: {
      progress: 0,
      plannedMinutes: 0,
    },
  },
  tue: {
    events: [],
    totals: {
      progress: 0,
      plannedMinutes: 0,
    },
  },
  wed: {
    events: [],
    totals: {
      progress: 0,
      plannedMinutes: 0,
    },
  },
  thur: {
    events: [],
    totals: {
      progress: 0,
      plannedMinutes: 0,
    },
  },
  fri: {
    events: [],
    totals: {
      progress: 0,
      plannedMinutes: 0,
    },
  },
  sat: {
    events: [],
    totals: {
      progress: 0,
      plannedMinutes: 0,
    },
  },
  sun: {
    events: [],
    totals: {
      progress: 0,
      plannedMinutes: 0,
    },
  },
  current: {
    mon: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    tue: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    wed: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    thur: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    fri: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    sat: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    sun: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    totals: {
      progress: 0,
      plannedMinutes: 0,
      scheduled: 0,
    },
  },
  template: {
    mon: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    tue: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    wed: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    thur: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    fri: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    sat: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    sun: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    totals: {
      progress: 0,
      plannedMinutes: 0,
    },
  },
  totals: {
    progress: 0,
    plannedMinutes: 0,
    scheduled: 0,
  },
};

const cleanEventData = (evtData) => {
  const evt = { ...evtData };
  const startTime = moment(
    new Date(
      evt.start.seconds * 1000 + evt.start.nanoseconds / 1000000,
    ),
  ).toDate();
  const endTime = moment(
    new Date(evt.end.seconds * 1000 + evt.end.nanoseconds / 1000000),
  ).toDate();
  evt.start = startTime;
  evt.end = endTime;

  evt.progress = parseInt(evt.progress);

  return evt;
};

export const cleanTaskData = (tskData) => {
  const evt = { ...tskData };
  if (evt.id) {
    const startTime = moment(
      new Date(
        evt.start_date.seconds * 1000 +
        evt.start_date.nanoseconds / 1000000,
      ),
    ).toDate();
    const endTime = moment(
      new Date(
        evt.end_date.seconds * 1000 +
        evt.end_date.nanoseconds / 1000000,
      ),
    ).toDate();
    evt.start_date = startTime;
    evt.end_date = endTime;
  } else {
    return null;
  }
  return evt;
};

const filterEventsByWeek = (data, selDate) => {
  const weekData = {
    mon: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    tue: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    wed: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    thur: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    fri: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    sat: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    sun: {
      events: [],
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    current: {
      mon: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      tue: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      wed: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      thur: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      fri: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      sat: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      sun: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    template: {
      mon: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      tue: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      wed: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      thur: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      fri: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      sat: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      sun: {
        events: [],
        totals: {
          progress: 0,
          plannedMinutes: 0,
        },
      },
      totals: {
        progress: 0,
        plannedMinutes: 0,
      },
    },
    totals: {
      progress: 0,
      plannedMinutes: 0,
      scheduledMinutes: 0,
    },
  };

  if (!data) return weekData;

  const nData = [...data];

  const startOfWeek = moment(selDate).startOf('week');
  const endOfWeek = moment(selDate).endOf('week');

  const filteredTasks = nData.filter((task) => {
    const cleanTask = cleanEventData(task);
    const weekRange = moment.range(startOfWeek, endOfWeek);
    const taskRange = moment.range(
      moment(cleanTask.start),
      moment(cleanTask.end),
    );
    return taskRange.overlaps(weekRange);
  });

  filteredTasks.map((task) => {
    const cleanTask = cleanEventData(task);
    const taskStartTime = moment(cleanTask.start);

    if (taskStartTime.day() === 0) {
      if (hasParent(cleanTask)) {
        weekData.sun.events.push(cleanTask);
        weekData.sun.totals.progress += cleanTask.progress;
        weekData.sun.totals.plannedMinutes += Math.ceil(
          moment(cleanTask.end).diff(
            moment(cleanTask.start),
            'minutes',
            true,
          ),
        );
        if (moment(cleanTask.end) >= moment()) {
          weekData.current.sun.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.sun.totals.progress += cleanTask.progress;
          weekData.current.sun.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        } else if (
          moment(cleanTask.end) < moment() &&
          cleanTask.progress !== 0
        ) {
          weekData.current.sun.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.sun.totals.progress += cleanTask.progress;
          weekData.current.sun.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        }
      }
      weekData.template.sun.events.push(cleanTask);
    }
    if (taskStartTime.day() === 1) {
      if (hasParent(cleanTask)) {
        weekData.mon.events.push(cleanTask);
        weekData.mon.totals.progress += cleanTask.progress;
        weekData.mon.totals.plannedMinutes += Math.ceil(
          moment(cleanTask.end).diff(
            moment(cleanTask.start),
            'minutes',
            true,
          ),
        );
        if (moment(cleanTask.end) >= moment()) {
          weekData.current.mon.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.mon.totals.progress += cleanTask.progress;
          weekData.current.mon.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        } else if (
          moment(cleanTask.end) < moment() &&
          cleanTask.progress !== 0
        ) {
          weekData.current.mon.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.mon.totals.progress += cleanTask.progress;
          weekData.current.mon.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        }
      }
      weekData.template.mon.events.push(cleanTask);
    }
    if (taskStartTime.day() === 2) {
      if (hasParent(cleanTask)) {
        weekData.tue.events.push(cleanTask);
        weekData.tue.totals.progress += cleanTask.progress;
        weekData.tue.totals.plannedMinutes += Math.ceil(
          moment(cleanTask.end).diff(
            moment(cleanTask.start),
            'minutes',
            true,
          ),
        );
        if (moment(cleanTask.end) >= moment()) {
          weekData.current.tue.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.tue.totals.progress += cleanTask.progress;
          weekData.current.tue.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        } else if (
          moment(cleanTask.end) < moment() &&
          cleanTask.progress !== 0
        ) {
          weekData.current.tue.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.tue.totals.progress += cleanTask.progress;
          weekData.current.tue.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        }
      }
      weekData.template.tue.events.push(cleanTask);
    }
    if (taskStartTime.day() === 3) {
      if (hasParent(cleanTask)) {
        weekData.wed.events.push(cleanTask);
        weekData.wed.totals.progress += cleanTask.progress;
        weekData.wed.totals.plannedMinutes += Math.ceil(
          moment(cleanTask.end).diff(
            moment(cleanTask.start),
            'minutes',
            true,
          ),
        );
        if (moment(cleanTask.end) >= moment()) {
          weekData.current.wed.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.wed.totals.progress += cleanTask.progress;
          weekData.current.wed.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        } else if (
          moment(cleanTask.end) < moment() &&
          cleanTask.progress !== 0
        ) {
          weekData.current.wed.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.wed.totals.progress += cleanTask.progress;
          weekData.current.wed.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        }
      }
      weekData.template.wed.events.push(cleanTask);
    }
    if (taskStartTime.day() === 4) {
      if (hasParent(cleanTask)) {
        weekData.thur.events.push(cleanTask);
        weekData.thur.totals.progress += cleanTask.progress;
        weekData.thur.totals.plannedMinutes += Math.ceil(
          moment(cleanTask.end).diff(
            moment(cleanTask.start),
            'minutes',
            true,
          ),
        );
        if (moment(cleanTask.end) >= moment()) {
          weekData.current.thur.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.thur.totals.progress += cleanTask.progress;
          weekData.current.thur.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        } else if (
          moment(cleanTask.end) < moment() &&
          cleanTask.progress !== 0
        ) {
          weekData.current.thur.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.thur.totals.progress += cleanTask.progress;
          weekData.current.thur.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        }
      }
      weekData.template.thur.events.push(cleanTask);
    }
    if (taskStartTime.day() === 5) {
      if (hasParent(cleanTask)) {
        weekData.fri.events.push(cleanTask);
        weekData.fri.totals.progress += cleanTask.progress;
        weekData.fri.totals.plannedMinutes += Math.ceil(
          moment(cleanTask.end).diff(
            moment(cleanTask.start),
            'minutes',
            true,
          ),
        );
        if (moment(cleanTask.end) >= moment()) {
          weekData.current.fri.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.fri.totals.progress += cleanTask.progress;
          weekData.current.fri.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        } else if (
          moment(cleanTask.end) < moment() &&
          cleanTask.progress !== 0
        ) {
          weekData.current.fri.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.fri.totals.progress += cleanTask.progress;
          weekData.current.fri.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        }
      }
      weekData.template.fri.events.push(cleanTask);
    }
    if (taskStartTime.day() === 6) {
      if (hasParent(cleanTask)) {
        weekData.sat.events.push(cleanTask);
        weekData.sat.totals.progress += cleanTask.progress;
        weekData.sat.totals.plannedMinutes += Math.ceil(
          moment(cleanTask.end).diff(
            moment(cleanTask.start),
            'minutes',
            true,
          ),
        );
        if (moment(cleanTask.end) >= moment()) {
          weekData.current.sat.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.sat.totals.progress += cleanTask.progress;
          weekData.current.sat.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        } else if (
          moment(cleanTask.end) < moment() &&
          cleanTask.progress !== 0
        ) {
          weekData.current.sat.events.push(cleanTask);
          weekData.current.totals.progress += cleanTask.progress;
          weekData.current.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
          weekData.current.sat.totals.progress += cleanTask.progress;
          weekData.current.sat.totals.plannedMinutes += Math.ceil(
            moment(cleanTask.end).diff(
              moment(cleanTask.start),
              'minutes',
              true,
            ),
          );
        }
      }
      weekData.template.sat.events.push(cleanTask);
    }

    if (task.yearTask) {
      let yt = cleanTaskData(task.yearTask);
      let weeks = Math.ceil(
        moment(yt.end_date).diff(
          moment(yt.start_date),
          'weeks',
          true,
        ),
      );
      weekData.totals.scheduledMinutes =
        yt.type === 'routine'
          ? Math.ceil((yt.totalHours * 60) / weeks)
          : Math.ceil(yt.totalHours * 60);
    }

    weekData.totals.progress += cleanTask.progress;
    weekData.totals.plannedMinutes += Math.ceil(
      moment(cleanTask.end).diff(
        moment(cleanTask.start),
        'minutes',
        true,
      ),
    );
  });

  return weekData;
};

export const getWeeklyEvents = async (uid, selDate) => {
  if (!uid || !selDate) {
    console.error('missing fields');
    return false;
  }

  const data = await db
    .collection(col)
    .where('user', '==', uid)
    .get()
    .then((snap) => {
      const data = [];
      snap.forEach((doc) => {
        data.push(doc.data());
      });
      return data;
    })
    .catch((err) => {
      return err;
    });

  let filteredData = filterEventsByWeek(data, selDate);
  let cleanData = {
    all: await Promise.all(data.map(async (item) => {
      let verifiedTask = await checkAndFixTaskParentData(item.id);
      return cleanEventData(verifiedTask ? verifiedTask : item);
    })),
    filtered: filteredData,
  };

  console.log(cleanData);

  return cleanData;
};

export const getWeeklyEvent = async (id) => {
  const data = await db
    .collection(col)
    .doc(id)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        return false;
      }
      return doc.data();
    })
    .catch((err) => {
      return err;
    });
  return data;
};

export const createWeeklyEvent = async (weeklyEvent) => {
  const weeklyEventRef = db.collection(col).doc();

  const weeklyEventObj = {
    id: weeklyEventRef.id,
    user: weeklyEvent.user,
    title: weeklyEvent.title,
    created: new Date(),
    updated: new Date(),
    start: weeklyEvent.start,
    end: weeklyEvent.end,
    progress: Number(weeklyEvent.progress),
    allDay: false,
    sentMessageToUser: false,
    yearTask: weeklyEvent.yearTask ? weeklyEvent.yearTask : null,
  };

  await weeklyEventRef.set(weeklyEventObj);
  await addWeeklyTaskAsChild(
    weeklyEventRef.id,
    weeklyEvent.yearTask.id,
  );

  return weeklyEventObj;
};

export const updateWeeklyEvent = async (weeklyEvent) => {
  const weeklyEventRef = db.collection(col).doc(weeklyEvent.id);

  const oldWeeklyEvent = await getWeeklyEvent(weeklyEvent.id);
  if (!oldWeeklyEvent) return;

  if (weeklyEvent?.yearTask.id.toString() !== oldWeeklyEvent?.yearTask?.id.toString()) {
    removeWeeklyTaskAsChild(oldWeeklyEvent.id)
  }

  const weeklyEventObj = { ...weeklyEvent };
  weeklyEventObj.updated = new Date();

  //calculate progress and add time if necessary
  const jiseki = Number(weeklyEvent.progress);
  const sw = moment(weeklyEvent.start);
  const nw = moment(weeklyEvent.end);
  const diff = nw.diff(sw, 'minutes');
  if (jiseki > diff) {
    weeklyEventObj.end = moment(weeklyEventObj.end)
      .add(Number(jiseki) - diff, 'minutes')
      .toDate();
  }

  if (weeklyEvent.yearTask) {
    await addWeeklyTaskAsChild(
      weeklyEventRef.id,
      weeklyEvent.yearTask.id,
    );
  }

  await weeklyEventRef
    .set(weeklyEventObj, { merge: true })
    .then(() => {
      if (weeklyEventObj.yearTask) {
        recalculateJiseki(weeklyEventObj.yearTask.id.toString());
      }
    });

  return weeklyEventObj;
};

export const deleteWeeklyEvent = async (id) => {
  await removeWeeklyTaskAsChild(id);

  return await db
    .collection(col)
    .doc(id)
    .delete()
    .catch((err) => {
      return err;
    });
};

export const createTemplateEvent = async (templateEvent, selDate) => {
  const weeklyEventRef = db.collection(col).doc();

  const evt = cleanEventData(templateEvent);
  const yearTask = evt.yearTask || null;

  const taskStart = moment(evt.start);
  const taskEnd = moment(evt.end);

  const start = moment(selDate).set({
    hour: taskStart.get('hour'),
    minute: taskStart.get('minute'),
    second: taskStart.get('second'),
  });
  const end = moment(selDate).set({
    hour: taskEnd.get('hour'),
    minute: taskEnd.get('minute'),
    second: taskEnd.get('second'),
  });

  const weeklyEventObj = {
    id: weeklyEventRef.id,
    user: templateEvent.user,
    title: 'タスクを選択して下さい。',
    created: new Date(),
    updated: new Date(),
    start: start.toDate(),
    end: end.toDate(),
    progress: 0,
    allDay: false,
    yearTask: null,
    sentMessageToUser: false,
  };

  //set if yearly task exists within range
  if (yearTask) {
    const yearTaskLatestData = await getYearlyEvent(yearTask.id).then(
      (data) => {
        return data;
      },
    );
    if (yearTaskLatestData) {
      const cleanYTData = cleanTaskData(yearTaskLatestData);
      const ytRange = moment.range(
        cleanYTData.start_date,
        cleanYTData.end_date,
      );
      const taskRange = moment.range(start, end);
      if (taskRange.overlaps(ytRange)) {
        weeklyEventObj.yearTask = yearTaskLatestData;
        weeklyEventObj.title = yearTaskLatestData.text;
      }
    }
  }

  await weeklyEventRef.set(weeklyEventObj);
  return weeklyEventObj;
};

export const updateTaskParentData = async (id, parent) => {
  const weeklyEventRef = db.collection(col).doc(id);
  let weeklyEventData = await getWeeklyEvent(id);

  if (!weeklyEventData) return false;

  if (parent) {
    weeklyEventData.yearTask = parent;
    weeklyEventData.title = parent?.text || weeklyEventData.title;
    await weeklyEventRef
      .set(weeklyEventData, { merge: true })
      .then(() => {
        recalculateJiseki(parent.id.toString());
      });
  } else {
    weeklyEventData = await nullifyTask(id);
  }
  return weeklyEventData;
};

export const nullifyTask = async (id) => {
  const weeklyEventRef = db.collection(col).doc(id);
  const weeklyEventData = await getWeeklyEvent(id);

  if (!weeklyEventData) return false;

  weeklyEventData.title = 'タスクを選択して下さい。';
  weeklyEventData.yearTask = null;
  weeklyEventData.progress = 0;
  weeklyEventData.sentMessageToUser = true;
  await weeklyEventRef.set(weeklyEventData, { merge: true });
  return weeklyEventData;
};

export const checkAndFixTaskParentData = async (id) => {
  
  let taskData = await db
  .collection(col)
  .doc(id)
  .get()
  .then((doc) => {
    if (!doc.exists) {
      return false;
    }
    return doc.data();
  })
  .catch((err) => {
    return err;
  });

  if(!taskData) return false;

  if(!taskData.yearTask) return taskData;

  const parentData = await getYearlyEvent(taskData.yearTask.id);

  if(!parentData){
    taskData = nullifyTask(taskData.id);
  }

  if(!isEqual(taskData.yearTask, parentData)){
    taskData = await updateTaskParentData(taskData.id, parentData);
  }

  return taskData;
}