import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import NewTagsDialogue from './NewTagsDialogue';
import GenericDeleteDialogue from './GenericDeleteDialogue';
import { Checkbox } from '@material-ui/core';
import './menues.scss';

export default props => {
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [newLocDialogueOpen, setNewLocDialogueOpen] = React.useState(
    false,
  );
  const [isEditLoc, setIsEditLoc] = React.useState(false);
  const [editingLoc, setEditingLoc] = React.useState(null);

  const [deleteAlertOpen, setDeleteAlertOpen] = React.useState(false);

  const handleListItemClick = (event, index) => {
    props.departmentSelected(props.locs[index].id);
    props.handleClose();
  };

  const resetLoc = () => {
    setNewLocDialogueOpen(false);
    setIsEditLoc(false);
    setEditingLoc(null);
    props.close();
  };

  const handleLocEditClick = index => {
    setNewLocDialogueOpen(true);
    setIsEditLoc(true);
    setEditingLoc(props.locs[index]);
  };

  const handleLocDeleteClick = index => {
    setDeleteAlertOpen(true);
    setEditingLoc(props.locs[index]);
  };

  const handleDeleteSubmit = id => {
    props.deleteLocSubmit(id);
    setEditingLoc(null);
    resetLoc();
  };

  const editLocSubmit = (id, name) => {
    props.editLocSubmit(id, name);
    resetLoc();
  };

  const newLocSubmit = name => {
    props.newLocSubmit(name);
    resetLoc();
  };

  return (
    <>
      <Menu
        anchorEl={props.anchorEl}
        open={Boolean(props.anchorEl)}
        onClose={props.handleClose}
        keepMounted={false}
        classes={{
          paper: 'pop-menu',
          list: 'pop-menu-list',
        }}
      >
        {props.locs.map((loc, index) => (
          <ListItem key={loc.id} selected={selectedIndex === index}>
            <ListItemText primary={loc.title} />
            <ListItemSecondaryAction>
              <button
                className="edit"
                onClick={() => handleLocEditClick(index)}
              >
                編集
              </button>
              <button
                className="delete"
                onClick={() => handleLocDeleteClick(index)}
              >
                削除
              </button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        <ListItem button onClick={() => setNewLocDialogueOpen(true)}>
          <AddCircleIcon />
          　新規追加
        </ListItem>
      </Menu>
      <NewTagsDialogue
        isEdit={isEditLoc}
        editingLoc={editingLoc}
        show={newLocDialogueOpen}
        close={() => resetLoc()}
        editLocSubmit={editLocSubmit}
        newLocSubmit={newLocSubmit}
      />
      <GenericDeleteDialogue
        open={deleteAlertOpen}
        close={() => setDeleteAlertOpen(false)}
        submit={handleDeleteSubmit}
        id={editingLoc ? editingLoc.id : null}
      />
    </>
  );
};
