import React from 'react';
import NumberFormat from 'react-number-format';

import './Chip.scoped.scss';

const Chip = (props) => {
    const {tJiseki, tWari} = props;
    let diff = tJiseki - tWari;

    let checker = Math.sign(diff);

    let mark = '±';
    let classVal = 'chip';

    if (checker === -1) {
      mark = '';
      classVal = 'chip minus';
    }

    if (checker === 1) {
      mark = '+';
      classVal = 'chip plus';
    }
    return (
      <>
        <strong className={classVal}>
          <NumberFormat value={(isNaN(diff) ? 0 : diff)} prefix={mark} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} />
        </strong>
      </>
    );
  };
  
  export default Chip;