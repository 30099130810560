import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {useParams} from "react-router-dom";
import { Link } from 'react-router-dom';

import StudentCalendar from '../components/Common/Calendar/StudentCalendar';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function StudentWeekly(props) {

    const classes = useStyles();
    let { id } = useParams();

    return (
        <>
           <AppBar className={classes.appBar}  id="student-toolbar-calendar">
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            週間 実行 カレンダー
                        </Typography>
                        <IconButton edge="start" color="inherit" component={Link} to={`/console/students/${id}`} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className="gant-modal-body">
                    <StudentCalendar user={id} />
                </div>
        </>
    );
}