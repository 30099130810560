import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import './dialogues.scoped.scss';

export default props => {
  const handleSubmit = () => {
    props.deleteLocSubmit(props.editingLoc.id);
    props.close();
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.close}>
        <DialogTitle id="alert-dialog-title">
          この部門を削除してよろしいですか？
        </DialogTitle>
        <DialogActions>
          <Button onClick={props.close} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleSubmit} color="secondary" autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
