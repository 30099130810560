import React, { useState, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../TabPanel';
import { useLiff } from 'react-liff';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {
  getWeeklyInfo,
  updateWeeklyInfo,
  createWeeklyInfo,
} from '../../../api/weeklyinfo';

const moment = extendMoment(Moment);

import SlideTransition from '../SlideTransition';
import './FurikaeruModal.scoped.scss';

function weekOfMonth(m) {
  var day = m.day();
  var nthOfMoth = Math.ceil(m.date() / 7);
  return nthOfMoth;
}

const FurikaeruModal = (props) => {
  const { weeklyInfo, fetchWeeklyInfo, teacher } = props;
  const { error, liff, isLoggedIn, ready } = useLiff();
  const context = liff.getContext();
  const lineId = context.userId;
  const { open, close, date } = props;

  const [tabVal, setTabVal] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const [info, setInfo] = useState(['', '', '']);
  const [prevInfo, setPrevInfo] = useState(['', '', '']);
  const [inputError, setInputError] = useState(['', '', '']);

  useEffect(() => {
    if (!weeklyInfo) {
      setInfo(['', '', '']);
      setPrevInfo(['', '', '']);
    } else {
      setInfo(weeklyInfo?.lookBack?.now || ['', '', '']);
      setPrevInfo(weeklyInfo?.lookBack?.previous || ['', '', '']);
    }
  }, [weeklyInfo]);

  const postWeeklyMessage = async () => {
     const lb1 = info[0] ? info[0] : '';
    const lb2 = info[1] ? info[1] : '';
    const lb3 = info[2] ? info[2] : '';

    if (weeklyInfo.id) {

      await updateWeeklyInfo({
        id: weeklyInfo.id,
        user: lineId,
        message: weeklyInfo.message ? weeklyInfo.message : '',
        lookBack: [lb1, lb2, lb3],
      }).then(async () => {
        await fetchWeeklyInfo();
      });
    } else {
      await createWeeklyInfo({
        user: lineId,
        message: '',
        lookBack: [lb1, lb2, lb3],
      }, date).then(async () => {
        await fetchWeeklyInfo();
      });
    }
  };

  const onClose = () => {
    if (!teacher) {
      fetchWeeklyInfo().then(() => {
        setTabVal(0);
        setSubmitted(false)
      });
    }
    close()
  };
  const onTabValChange = (v) => {
    setTabVal(v);
  };

  const handleChangeText = (index, val) => {
    const oldInfo = [...info];
    oldInfo[index] = val;
    setInfo(oldInfo);
  };

  const submit = () => {
    let errObj = ['', '', ''];
    let isError = false;
    if (info[0] === '') {
      errObj[0] = 'この項目は必須です。';
      isError = true;
    }
    if (info[1] === '') {
      errObj[1] = 'この項目は必須です。';
      isError = true;
    }
    if (info[2] === '') {
      errObj[2] = 'この項目は必須です。';
      isError = true;
    }
    if (isError) {
      setInputError(errObj);
      return false;
    } else {
      postWeeklyMessage().then(() => {
        setTabVal(2);
        setInputError(['', '', '']);
        setSubmitted(true)
      });
    }
  };

  const Error = ({ children }) => (
    <p style={{ color: 'red', fontSize: 11, marginTop: 3 }}>
      {children}
    </p>
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => onClose()}
      TransitionComponent={SlideTransition}
    >
      {!submitted && !teacher ? (
        <AppBar position="static">
          <Tabs value={tabVal} onChange={(e, v) => onTabValChange(v)}>
            <Tab label="先週の確認" />
            <Tab label="今週の入力" />
          </Tabs>
        </AppBar>
      ) : null}
      <DialogContent>
        <TabPanel value={tabVal} index={1}>
          <p className="large">
            この１週間を振り返って
            <br />
            みましょう！
          </p>
          <p style={{ fontSize: 11, textAlign: 'center' }}>
            {moment(date).startOf('week').format('YYYY年MM月DD日')} -{' '}
            {moment(date).endOf('week').format('YYYY年MM月DD日')}
          </p>
          <div className="input-obj">
            <label>【成果】：何がうまくいきましたか？</label>
            <div className="input-item">
              <textarea
                onChange={(e) => handleChangeText(0, e.target.value)}
                value={info[0] ? info[0] : ''}
              />
              {inputError[0] ? <Error>{inputError[0]}</Error> : null}
            </div>
          </div>
          <div className="input-obj">
            <label>【反省】：何がうまくいきませんでしたか？</label>
            <textarea
              onChange={(e) => handleChangeText(1, e.target.value)}
              value={info[1] ? info[1] : ''}
            />
            {inputError[1] ? <Error>{inputError[1]}</Error> : null}
          </div>
          <div className="input-obj">
            <label>【改善】：次週どのような違いを作りますか？</label>
            <textarea
              onChange={(e) => handleChangeText(2, e.target.value)}
              value={info[2] ? info[2] : ''}
            />
            {inputError[2] ? <Error>{inputError[2]}</Error> : null}
          </div>

          <div className="input-submit">
            <button onClick={() => submit()}>保存</button>
          </div>
        </TabPanel>
        <TabPanel value={tabVal} index={0}>
          {!teacher ? <p className="large">
          先週を振り返って
            <br />
            みましょう！
          </p> : <p className="large">
            今週のセルフコーチング
          </p>}

          {!teacher ? <p style={{ fontSize: 11, textAlign: 'center' }}>
            {moment(date)
              .clone()
              .startOf('week')
              .subtract(1, 'week')
              .format('YYYY年MM月DD日')}{' '}
            -{' '}
            {moment(date)
              .clone()
              .endOf('week')
              .subtract(1, 'week')
              .format('YYYY年MM月DD日')}
          </p> : <p style={{ fontSize: 11, textAlign: 'center' }}>
            {moment(date)
              .clone()
              .startOf('week')
              .format('YYYY年MM月DD日')}{' '}
            -{' '}
            {moment(date)
              .clone()
              .endOf('week')
              .format('YYYY年MM月DD日')}
          </p>}

          <div className="past-obj">
            <h2>【成果】：何がうまくいきましたか？</h2>
            {!teacher ? <p>
              {prevInfo[0] ? prevInfo[0] : '内容はまだございません。'}
            </p> : <p>
              {info[0] ? info[0] : '内容はまだございません。'}
            </p>}
          </div>
          <div className="past-obj">
            <h2>【反省】：何がうまくいきませんでしたか？</h2>
            {!teacher ? <p>
              {prevInfo[1] ? prevInfo[1] : '内容はまだございません。'}
            </p> : <p>
              {info[1] ? info[1] : '内容はまだございません。'}
            </p>}
          </div>
          <div className="past-obj">
            <h2>【改善】：次週どのような違いを作りますか？</h2>
            {!teacher ? <p>
              {prevInfo[2] ? prevInfo[2] : '内容はまだございません。'}
            </p> : <p>
              {info[2] ? info[2] : '内容はまだございません。'}
            </p>}
          </div>
        </TabPanel>
        <TabPanel value={tabVal} index={2}>
          <div className="icon">
            <i class="fa fa-check-square-o" aria-hidden="true"></i>
            <p className="otsu">
              この1週間も、お疲れ様でした！
              <br />
              次の1週間も目標達成に向けて、がんばりましょう！
            </p>
          </div>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
          color="primary"
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FurikaeruModal;
