import React, { useState, useEffect } from 'react';
import App from '../components/Global/App';

import { withAuthorization } from '../components/Session';

import TitleWithSearch from '../components/Common/TitleWithSearch';
import TeacherList from '../components/Teachers/TeacherList';

import { getUsers, createMultiUsers, updateUser, deleteUser } from '../api/users';

import { getDepartments } from '../api/departments';
import { getSchools } from '../api/schools';
import { getClassifications } from '../api/classifications';
import { getCourses } from '../api/courses';

import NewUserDialogue from '../components/Users/NewUserDialogue.js';

const Teachers = props => {

  function find(items, text) {
    text = text.split(' ');
    return items.filter(function(item) {
      return text.every(function(el) {
        const hasEmail = item.email.indexOf(el) > -1;
        const hasName = item.nameSei.indexOf(el) > -1;
        const hasName2 = item.nameMei.indexOf(el) > -1;
        return hasEmail || hasName || hasName2
      });
    });
  }

  const [teachers, setTeachers] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [loadingUsers, setLoadingUsers] = useState(true);

  const [showUserDialogue, setShowUserDialogue] = useState(false);

  const [dialogueLoading, SetDialogueLoading] = useState(false);

  const [fireData, setFireData] = useState({
    departments: [],
    schools: [],
    classifications: [],
    courses: [],
  });

  useEffect(() => {
    getUsers().then(data => {
      setTeachers(data);
        setLoadingUsers(false);
    });

    const fData = { ...fireData };

    getDepartments().then(data => {
      fData.departments.push(...data);
      getSchools().then(data => {
        fData.schools.push(...data);
        getClassifications().then(data => {
          fData.classifications.push(...data);
          setFireData(fData);
        });
      });
    });
  }, []);

  useEffect(() => {
    setFilteredUsers(find(teachers, search));
  },[search])

  const reloadTeachers = async () =>{
    await getUsers().then(data => {
      setTeachers(data);
    });
  }

  const reloadFData = () => {
    const fData = {
      departments: [],
      schools: [],
      classifications: [],
      courses: [],
    };

    getDepartments().then(data => {
      fData.departments.push(...data);
      getSchools().then(data => {
        fData.schools.push(...data);
        getClassifications().then(data => {
          fData.classifications.push(...data);
          setFireData(fData);
        });
      });
    });
  };

  const handleClose = () => {
    setShowUserDialogue(false);
  };

  const handleInvite = async (userData) => {
    SetDialogueLoading(true);
    const newUserRes = await createMultiUsers(userData).then((res) => {
        SetDialogueLoading(false);
        setShowUserDialogue(false);
        loadUsers();
        return res;
    });
    return newUserRes;
}

  const loadUsers = async () => {
    setLoadingUsers(true);
    getUsers().then(data => {
      setTeachers(data);
      setLoadingUsers(false);
    });
  };


  const handleDeleteUser = async (user) =>{
    setLoadingUsers(true);
    await deleteUser(user).then(()=>{
      reloadFData();
      reloadTeachers().then(()=>{
        setLoadingUsers(false);
      })
    });
  }

  const handleDeleteMultiple = async (userList)=>{
    userList.map(async user => {
      await deleteUser(user).then(()=>{
        reloadFData();
        reloadTeachers().then(()=>{
          setLoadingUsers(false);
        })
      });
    })
  }

  return (
    <App>
      <TitleWithSearch
        title="講師権限管理"
        placeholder={'ユーザー名/メールアドレスで検索'}
        updateHandler={setSearch}
        icon={['fas', 'users']}
      />
      <TeacherList
        reload={reloadFData}
        reloadUsers={reloadTeachers}
        users={(search.length > 0) ? filteredUsers : teachers}
        isSearch={(search.length > 0) ? true : false}
        fireData={fireData}
        newUserOn={() => setShowUserDialogue(true)}
        updateUser={updateUser}
        deleteUser={handleDeleteUser}
        handleDeleteMultiple={handleDeleteMultiple}
        loading={loadingUsers}
      />
      <NewUserDialogue
        loading={dialogueLoading}
        submit={handleInvite}
        close={handleClose}
        show={showUserDialogue}
        departments={fireData.departments}
        schools={fireData.schools}
      />
    </App>
  );
};

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Teachers);
