import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';

import moment from 'moment';
import 'moment/locale/ja';

if (!firebase.apps.length) {
  const CLIENT_CONFIG = {
    apiKey: "AIzaSyDeV1gVgtuJzbKfdmdIMXDu2Fy6XUmxTq4",
    authDomain: "future-log-67799.firebaseapp.com",
    projectId: "future-log-67799",
    storageBucket: "future-log-67799.appspot.com",
    messagingSenderId: "337593555009",
    appId: "1:337593555009:web:6bc12bf96d00005cf48ec0",
    measurementId: "G-DSJRD2QR53"
  };


  firebase.initializeApp(CLIENT_CONFIG);
}

const db = firebase.firestore();
const col = 'templates';
const auth = firebase.auth();

export const getTemplates = async (uid) => {
  const userId = uid ? uid : auth.currentUser.uid
  const templateData = await db
    .collection(col)
    .where('userId', '==', uid)
    .get()
    .then(snap => {
      const data = [];
      snap.forEach(doc => {
        data.push(doc.data());
      });
      return data;
    })
    .catch(err => {
      return err;
    });
  return templateData;
};

export const getTemplate = async id => {
  const data = await db
    .collection(col)
    .doc(id)
    .get()
    .then(doc => {
      if(!doc.exists){
        return false
      }
      return doc.data()
    }).catch(err => {
      return err
    })
  return data;
};

export const createTemplate = async template => {
  const templateRef = db.collection(col).doc();

  const templateObj = {
    id : templateRef.id,
    userId : template.lineId,
    name : template.name,
    events : template.events,
    created : new Date(),
    updated : new Date()
  }
  await templateRef.set(templateObj);
  return templateObj;
};

export const updateTemplate = async template => {
  const templateRef = db.collection(col).doc(template.id);
  const templateObj = {...template};
  templateObj.updated = new Date();
  await templateRef.set(templateObj, {merge : false});
  return templateObj;
};

export const deleteTemplate = async id => {
  await db
    .collection(col)
    .doc(id)
    .delete().catch(err => {
      return err
    });
  return true;
};
