import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import "./dialogues.scoped.scss";

export default (props) => {

    const [nameSei, setNameSei] = useState(props.nameSei);
    const [nameMei, setNameMei] = useState(props.nameMei);

    useEffect(() => {
        setNameSei(props.nameSei)
        setNameMei(props.nameMei)
    }, [
        props.nameSei, props.nameMei
    ])

    return (
        <>
            <Dialog
                open={props.show}
                onClose={props.close}
                aria-labelledby="form-dialog-title"
                className="user-diag"
            >
                <DialogTitle id="form-dialog-title">氏名を変更</DialogTitle>
                <DialogContent>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField label="姓" variant="outlined" defaultValue={nameSei} inputProps={{
                                maxLength: 10,
                            }} onChange={(e) => setNameSei(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="名" variant="outlined" defaultValue={nameMei} inputProps={{
                                maxLength: 10,
                            }} onChange={(e) => setNameMei(e.target.value)} />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} color="primary">キャンセル</Button>
                    <Button onClick={() => props.submit(nameSei, nameMei)} color="primary">保存</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

