import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);
moment.locale('ja', {
  week: {
    dow: 1,
    doy: 1,
  },
});

if (!firebase.apps.length) {
  const CLIENT_CONFIG = {
    apiKey: 'AIzaSyDeV1gVgtuJzbKfdmdIMXDu2Fy6XUmxTq4',
    authDomain: 'future-log-67799.firebaseapp.com',
    projectId: 'future-log-67799',
    storageBucket: 'future-log-67799.appspot.com',
    messagingSenderId: '337593555009',
    appId: '1:337593555009:web:6bc12bf96d00005cf48ec0',
    measurementId: 'G-DSJRD2QR53',
  };

  firebase.initializeApp(CLIENT_CONFIG);
}

const db = firebase.firestore();
const col = 'weeklyInfo';
const auth = firebase.auth();

export const getWeeklyInfos = async uid => {
  const courseData = await db
    .collection(col)
    .where('user', '==', uid)
    .get()
    .then(snap => {
      const data = [];
      snap.forEach(doc => {
        data.push(doc.data());
      });
      return data;
    })
    .catch(err => {
      return err;
    });
  return courseData;
};

export const getWeeklyInfo = async (userId,date) => {

  let info = {};

  const key = moment(date).startOf('week').format('YYYY-MM-DD');
  let prevKey = moment(date).startOf('week').subtract(1, 'week').format('YYYY-MM-DD');

  const dataNow = await db
    .collection(col)
    .where('key', '==', key)
    .where('user', '==', userId)
    .get()
    .then(snap => {
      const data = [];
      snap.forEach(doc => {
        data.push(doc.data());
      });
      return data;
    });

    const dataPrev = await db
      .collection(col)
      .where('key', '==', prevKey)
      .where('user', '==', userId)
      .get()
      .then(snap => {
        const data = [];
        snap.forEach(doc => {
          data.push(doc.data());
        });
        return data;
      });

      if(dataNow.length){
        info = {...dataNow[0]};
  
        info.lookBack = {
          now : info.lookBack ? info.lookBack : ['','',''],
          previous : ['','','']
        }
      }

      if(dataPrev.length){
        if(dataNow.length){
          info.lookBack.previous = dataPrev[0]?.lookBack || ['','',''];
        }else{
          info = {...dataPrev[0]}
          info.key = key
          info.id = null
          info.message = ''
          info.lookBack = {
            now : ['','',''],
            previous : info.lookBack ? info.lookBack : ['','','']
          }
        }
        
      }
    
  return info;
};

export const createWeeklyInfo = async (info, date) => {
  const key = moment(date).startOf('week').format('YYYY-MM-DD');
  //alert(key);
  const courseRef = db.collection(col).doc();
  const {message, lookBack, user} = info;
  let lookback = lookBack ? lookBack : ['','',''];
  if(lookback.now) lookback = lookback.now;
  const courseObj = {
    id: courseRef.id,
    message: message ? message : '',
    key:key,
    lookBack : lookback ? lookback : ['','',''],
    user : user
  };
  await courseRef.set(courseObj);
  return courseObj;
};

export const updateWeeklyInfo = async info => {
  const courseRef = db.collection(col).doc(info.id);
  const courseObj = { ...info };
  await courseRef.set(courseObj, { merge: true });
  return courseObj;
};

export const deleteWeeklyInfo = async id => {
  await db
    .collection(col)
    .doc(id)
    .delete()
    .catch(err => {
      return err;
    });
  return true;
};
