import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

import './TaskEventDiffTotal.scoped.scss';
import Chip from './Chip';
const TaskProgressTotal = props => {
    const { tasks } = props;

    const [figures, setFigures] = useState({
        totalWari : 0,
        progress : 0
    })

    useEffect(()=>{
        const totals = {
            totalWari : 0,
            progress : 0
        };

        tasks.map(item => {
            const oldFig = {...figures};
            totals.totalWari += item.totalMinutes;
            totals.progress += item.totalProgress;
        })
        setFigures(totals);
    },[tasks])

    return (
        <tr>
            <th>合計</th>
            <td><NumberFormat value={figures.totalWari} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
            <td>
                <Chip tJiseki={figures.progress} tWari={figures.totalWari} />
            </td>
            <td><NumberFormat value={figures.progress} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
        </tr>
    );
};

export default TaskProgressTotal;