Array.prototype.sum = function (prop) {
  var total = 0;
  for (var i = 0, _len = this.length; i < _len; i++) {
    total += this[i][prop];
  }
  return total;
};

import React, { useState, useEffect } from 'react';
import { cleanTaskData } from '../../../api/weeklyEvents';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import FormHelperText from '@material-ui/core/FormHelperText';

const moment = extendMoment(Moment);
moment.locale('ja', {
  week: {
    dow: 1,
    doy: 1,
  },
});
import TabPanel from '../TabPanel';
import TaskEventDiffTotal from '../TaskEventDiffTotal/TaskEventDiffTotal';

import JisekiPreview from './JisekiPreview';

const EditProgressPanel = (props) => {
  const {
    overflowError,
    tabVal,
    progress,
    onProgressChange,
    eventStart,
    eventEnd,
    events,
    tasksS,
    filteredTaskEvents,
    event,
    selected,
    selDate,
    tasks,
    selectedTask,
  } = props;
  const taskSumArray = () => {
    const rows = [];
    tasks
      ?.filter((item) => {
        return item.id === selectedTask;
      })
      .map((task) => {
        const hasEvent = rows.findIndex((x) => x.id === task.id);
        const gantEventWeeks = Math.ceil(
          moment(task.end_date).diff(
            moment(task.start_date),
            'weeks',
            true,
          ),
        );
        if (hasEvent == -1) {
          rows.push({
            id: task.id,
            title: task.text,
            totalProgress: 0,
            totalScheduled: (task.totalHours * 60) / gantEventWeeks,
            parentText: task.parentText ? task.parentText : '',
            totalMinutes: 0,
          });
        } else {
          rows[hasEvent].id = task.id;
          rows[hasEvent].totalScheduled =
            (task.totalHours * 60) / gantEventWeeks;
          rows[hasEvent].parentText = task.parentText
            ? task.parentText
            : '';
        }
      });
    events?.mon?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id?.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    events?.tue?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id?.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    events?.wed?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id?.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    events?.thur?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id?.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    events?.fri?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id?.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    events?.sat?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id?.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    events?.sun?.events
      ?.filter((item) => {
        return (
          item?.yearTask?.id?.toString() === selectedTask.toString()
        );
      })
      .map((event) => {
        const hasEvent = rows.findIndex(
          (x) => x.id === event?.yearTask?.id,
        );
        const yt = cleanTaskData(event.yearTask);
        const gantEventWeeks = event.yearTask
          ? Math.ceil(
              moment(yt.end_date).diff(yt.start_date, 'weeks', true),
            )
          : 1;
        if (hasEvent !== -1) {
          rows[hasEvent].totalProgress += event.progress;
          rows[hasEvent].totalMinutes += Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          );
        } else {
          rows.push({
            id: event?.yearTask?.id || 0,
            title: event.title,
            totalProgress: event.progress,
            totalMinutes: Math.ceil(
              moment(event.end).diff(
                moment(event.start),
                'minutes',
                true,
              ),
            ),
            parentText: event.yearTask
              ? event.yearTask.parentText
              : '',
          });
        }
      });
    return rows;
  };
  const [oldProgress, setOldProgress] = useState(0);

  useEffect(() => {
    setOldProgress(progress);
  }, [selected]);

  return (
    <TabPanel value={tabVal} index={1}>
      <TaskEventDiffTotal
        events={events}
        tasksS={tasksS}
        selDate={selDate}
        tasks={tasks}
      />
      <Grid container justify="space-around">
        <Grid item xs={12}>
          <p style={{textAlign:'center',marginBottom:0}}>{selected?.yearTask?.parentText ? selected.yearTask.parentText + ' - ' : ''}{selected.title}</p>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            style={{
              marginTop: 20,
              marginBottom: 20,
              width: '100%',
            }}
            error={overflowError}
          >
            <InputLabel htmlFor="standard-adornment-password">
              実績入力
            </InputLabel>
            <Input
              type="number"
              value={progress}
              onChange={onProgressChange}
              errorMessage={'日付を跨ぐ計画割当/実績は作成できません'}
              endAdornment={
                <InputAdornment position="end">分</InputAdornment>
              }
            />
            {overflowError ? (
              <FormHelperText id="component-error-text">
                日付を跨ぐ計画割当/実績は作成できません
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justify="space-around">
        <Grid item xs={12}>
          <JisekiPreview
            real={() => {
              return (
                taskSumArray().sum('totalProgress') +
                progress -
                (selected ? selected.progress : 0)
              );
            }}
            about={() => {
              return (
                taskSumArray().sum('totalMinutes') +
                Math.ceil(
                  moment(eventEnd).diff(
                    moment(eventStart),
                    'minutes',
                    true,
                  ),
                ) -
                (selected
                  ? Math.ceil(
                      moment(selected.end).diff(
                        moment(selected.start),
                        'minutes',
                        true,
                      ),
                    )
                  : 0)
              );
            }}
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default EditProgressPanel;
