import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Marquee  from 'react-double-marquee';

import Chip from './Chip';

const moment = extendMoment(Moment);

import './TaskEventDiffTotal.scoped.scss';

const TaskEventSchedRow = (props) => {

    const { text, parent, planned, scheduled, progress } = props;

    return (<tr>
        <th className="mar-th"><Marquee direction="left" delay={0} speed="0.02" scrollWhen="overflow">{parent ? parent + " - " : ''}{text}</Marquee></th>
        <td><NumberFormat value={planned} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
        <td>
            <Chip tJiseki={scheduled} tWari={planned} />
        </td>
        <td><NumberFormat value={scheduled} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
        <td>
            <Chip tJiseki={progress} tWari={scheduled} />
        </td>
        <td><NumberFormat value={progress} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
    </tr>);
}

export default TaskEventSchedRow;