import * as React from 'react';
import { useState, useEffect } from 'react';
import ReactStopWatch from 'react-stopwatch';

import './Stopwatch.scoped.scss';

const Stopwatch = (props) => {

    const { close, submit, onChange } = props;

    const [toggle, setToggle] = useState(false);
    const [time, setTime] = useState({
        seconds: 0,
        hours: 0,
        minutes: 0,
    });

    const handleClose = () => {
        close();
        setTime({
            seconds: 0,
            hours: 0,
            minutes: 0,
        })
    }

    const save = () => {
        submit(time).then(() => {
            handleClose();
        })
    }

    const handleReset = () => {
        setToggle(false);
        setTime({
            seconds: 0,
            hours: 0,
            minutes: 0,
        })
    }


    const stopWatchTicker = (toggleval) => {

        let sec = time.seconds;
        let min = time.minutes;
        let hr = time.hours;

        if (toggleval) {
            sec = parseInt(sec) + 1;

            if (sec == 60) {
                min = parseInt(min) + 1;
                sec = 0;
            }
            if (min == 60) {
                hr = hr + 1;
                min = 0;
                sec = 0;
            }
           
        }

        return {
            seconds: parseInt(sec),
            minutes: parseInt(min),
            hours: parseInt(hr)
        }

    }

    useEffect(() => {
        const timer = setInterval(() => {
            const timerInfo = stopWatchTicker(toggle);
            setTime(timerInfo)
        }, 1000);
        return () => {
            clearInterval(timer)
        }
    }, [toggle, time])

    const display = () => {
        let dSec = time.seconds;
        let dMin = time.minutes;
        let dHr = time.hours;

        if (time.seconds < 10 || time.seconds == 0) {
            dSec = '0' + parseInt(time.seconds);
        }
        if (time.minutes < 10 || time.minutes == 0) {
            dMin = '0' + parseInt(time.minutes);
        }
        if (time.hours < 10 || time.hours == 0) {
            dHr = '0' + parseInt(time.hours);
        }

        return (<>{dHr}:{dMin}:{dSec}</>)
    }

    const handleSave = () => {
        handleReset();
        save();
    }

    return (
        <div className="stopwatch">
            <div className="close">
                <button onClick={handleClose}>X</button>
            </div>
            <p className="stopwatch-time">{display()}</p>
            <div className="controls">
                <button className="start" onClick={() => { setToggle(true); }}>スタート</button>
                <button className="stop" onClick={() => { setToggle(false) }}>ストップ</button>
                <button className="reset" onClick={() => { handleReset() }}>リセット</button>
            </div>
            <div className="save">
                <button className="save" onClick={() => { handleSave() }}>保存</button>
            </div>
        </div>
    )
}

export default Stopwatch;