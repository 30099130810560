import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CachedIcon from '@material-ui/icons/Cached';
import Slide from '@material-ui/core/Slide';
import moment from 'moment'
import 'moment/locale/ja';

import Gant from './Gant';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'sticky',
        top: 0
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontSize:14
    },
    margin: {
        fontSize:10,
        lineHeight:1.3,
        marginRight:5
      },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function GantModal(props) {
    const {user} = props;
    const classes = useStyles();

    const gantArea = useRef(null);

    const [view, setView] = useState('week')
    const [showDet, setshowDet] = useState(false);
    const [gantObj, setGantObj] = useState(null);

    return (
        <>
            <Dialog fullScreen onEntered={() => {
                setGantObj(gantt);
            }} open={props.open} onClose={()=>{
                props.close();
                props.reload();
            }} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            目標達成管理表
                        </Typography>
                        <Button className={classes.margin} color="secondary" variant="contained" size="small" disableElevation onClick={()=>{
                            props.reload();
                            props.close();
                        }}>週間実行<br/>カレンダー</Button>
                        <IconButton color="inherit" onClick={()=>props.reload()} aria-label="close">
                            <CachedIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Gant events={props.events ? props.events : {data:[]}} gantObj={gantObj} user={user} reload={props.reload}/>
            </Dialog>
        </>
    );
}