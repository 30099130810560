import React from 'react';
import { Link} from 'react-router-dom';

import './StudentHeader.scoped.scss';

const StudentHeader = props => {
  const { student } = props;
  return (
    <>
      {student ? (
        <div className="student-header">
          <div className="name">
            <figure>
              <img
                src={student.avatar || './public/default-avatar.png'}
              />{' '}
              <figcaption>{student.name}</figcaption>
            </figure>
          </div>
          <div className="actions">
            <div className="action">
              <a onClick={() => props.openStudentGant()}>目標達成管理表</a>
            </div>
            <div className="action">
              <Link to={`/console/students/${student.id}/weekly`}>
                週間計画
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default StudentHeader;
