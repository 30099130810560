import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUser, signOut, currentUser } from '../../api/users';
import './Header.scoped.scss';
import { faCogs } from '@fortawesome/free-solid-svg-icons'
const Header = props => {
  const { user } = props;

  const yd = currentUser();

  const isAdmin = () => {
    const role = yd.role;
    if (yd.email === 'admin@admin.com' || role === 'admin')
      return true;
    return false;
  };
  return (
    <>
      <header>
        <div className="fixed-wrapper">
          <figure className="header-logo">
            <Link to="/console/students">
              <span>
                <img src="/flog-logo.png" alt="" />
              </span>
            </Link>
          </figure>
          <nav className="nav-links">
            <ul className="nav-links-wrapper">
              {isAdmin() ? (
                <li className="nav-link-item">
                  <NavLink to="/console/teachers" className="nav-link">
                    <FontAwesomeIcon icon={['fas', 'users']} />
                    <span>講師権限管理</span>
                  </NavLink>
                </li>
              ) : null}
              <li className="nav-link-item">
                <NavLink to="/console/students" className="nav-link">
                  <FontAwesomeIcon icon={['fas', 'users']} />
                  <span>受講生管理</span>
                </NavLink>
              </li>
              <li className="nav-link-item">
                <NavLink to="/console/my-page" className="nav-link">
                  <FontAwesomeIcon icon={['fas', 'user']} />
                  <span>マイ・ページ</span>
                </NavLink>
              </li>
              {isAdmin() ? (
                <li className="nav-link-item">
                  <NavLink to="/console/masters" className="nav-link">
                    <FontAwesomeIcon icon={faCogs} />
                    <span>マスター管理</span>
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </nav>
          <div className="logout">
            <button onClick={() => signOut()}>ログアウト</button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
