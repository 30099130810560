import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import styles from './calendar-jss';
import './calendar.scss';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { sendMessage } from '../../../api/line';

import './StudentCalendar.scoped.scss';

import TeacherToolbar from './TeacherToolbar';
import StudentSlider from '../../Students/StudentSlider';

const moment = extendMoment(Moment);
moment.locale('ja', {
  week: {
    dow: 1,
    doy: 1,
  },
});

import { useSwipeable } from 'react-swipeable';

import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import GantModal from '../Gant/GantModal';
import TemplateSelectModal from '../TemplateModals/TemplateSelectModal';
import TemplateAddModal from '../TemplateModals/TemplateAddModal';
import CreateEventModal from '../EventModals/CreateEventModal';
import ChooseTaskModal from '../EventModals/ChooseTaskModal';

import TaskEventDiffTotalModal from '../TaskEventDiffTotal/TaskEventDiffTotalModal';
import DeleteConfirmModal from '../DeleteConfirmModal';
import {
  getWeeklyEvents,
  createWeeklyEvent,
  updateWeeklyEvent,
  deleteWeeklyEvent,
  defaultWeeklyStructure,
  createTemplateEvent,
  cleanTaskData,
} from '../../../api/weeklyEvents';

import {
  getYearlyEvents,
  searchYearlyEvents,
} from '../../../api/yearlyEvents';
import NumberFormat from 'react-number-format';
import {
  createDailyInfo,
  getDailyInfo,
  updateDailyInfo,
} from '../../../api/dailyinfo';

import {
  getTemplates,
  createTemplate,
  updateTemplate,
  deleteTemplate,
} from '../../../api/templates';

import { getLineUser, updateLineUser } from '../../../api/lineUsers';

const localizer = momentLocalizer(moment);

const hasParent = (event) => {
  const that = event.event;
  if (!that.yearTask) return false;
  if (that.yearTask.type === 'project') return false;
  const parent = cleanTaskData(that.yearTask);
  const st = moment(that.start).startOf('week');
  const en = moment(that.end).endOf('week');
  const taskRange = moment.range(st, en);
  const parentRange = moment.range(
    moment(parent.start_date),
    moment(parent.end_date),
  );

  const overlaps = taskRange.overlaps(parentRange);
  return overlaps;
};

const Event = (event) => {
  function isEventTop() {
    const st = moment(event.start);
    const en = moment(event.end);
    let duration = moment.duration(en.diff(st));
    let difference = duration.asMinutes();
    if (difference < 239) return true;
    return false;
  }

  function jisekiHeight() {
    const eventItem = event.event;

    const eStart = moment(eventItem.start);
    const eEnd = moment(eventItem.end);
    const eDiff = eEnd.diff(eStart, 'minutes', true);
    const jDiff = eventItem.progress;
    return (jDiff / eDiff) * 100;
  }

  return (
    <div
      className={
        !hasParent(event) ? 'has-no-master event-item' : 'event-item'
      }
    >
      <div className={isEventTop() ? 'event-wrap' : 'event-wrap top'}>
        <span className="eventBlock">
          {hasParent(event)
            ? event.title
            : 'タスクを選択して下さい。'}
          <strong
            className="jiseki-bar"
            style={{ display: 'block', height: `${jisekiHeight()}%` }}
          />
        </span>
      </div>
    </div>
  );
};

const DragAndDropCalendar = withDragAndDrop(Calendar);
const StudentCalendar = (props) => {
  const { id } = useParams('id');

  //Construct
  let isMobile = width <= 768;
  const formats = {
    dateFormat: 'dd',
    dayFormat: 'DD(dd)',
    timeGutterFormat: 'HH:mm',
    dayRangeHeaderFormat: ({ start, end }) => {
      return (
        moment(start).format('YYYY/MM/DD') +
        ' - ' +
        moment(end).format('MM/DD')
      );
    },
  };
  const eventStyleGetter = (event) => {
    const backgroundColor = hasParent({ event: event })
      ? '#E26579'
      : '#D6D6D6';
    const style = {
      backgroundColor,
    };
    return {
      style,
    };
  };

  //STATES
  const [isSpeed, setIsSpeed] = useState(false);
  const [isEvent, setIsEvent] = useState(false);
  const [isStopWatch, setIsStopWatch] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selDate, setSelDate] = useState(new Date());
  const [width, setWidth] = useState(window.innerWidth);

  const [gantModalOpen, setGantModalOpen] = useState(false);
  const [
    templateSelectModalOpen,
    setTemplateSelectModalOpen,
  ] = useState(false);
  const [templateAddModalOpen, setTemplateAddModalOpen] = useState(
    false,
  );
  const [entryModalOpen, setEntryModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [
    TaskEventDiffTotalOpen,
    setTaskEventDiffTotalOpen,
  ] = useState(false);
  const [
    DeleteConfirmModalOpen,
    setDeleteConfirmModalOpen,
  ] = useState(false);
  const [LineBotSettingsOpen, setLineBotSettingsOpen] = useState(
    false,
  );

  const [entryModalIsEdit, setEntryModalIsEdit] = useState(false);
  const [entryModalIndex, setEntryModalIndex] = useState(0);
  const [myTemplates, setMyTemplates] = useState([]);

  const [myEventsList, setMyEventsList] = useState({
    ...defaultWeeklyStructure,
  });
  const [allEvents, setAllEvents] = useState([]);

  const [yearlyEvents, setYearlyEvents] = useState([]);
  const [badge1, setBadge1] = useState(null);
  const [badge2, setBadge2] = useState(null);
  const [badge3, setBadge3] = useState(null);
  const [badge4, setBadge4] = useState(null);
  const [badge5, setBadge5] = useState(null);
  const [badge6, setBadge6] = useState(null);
  const [badge7, setBadge7] = useState(null);
  const [day1, setDay1] = useState([]);
  const [day2, setDay2] = useState([]);
  const [day3, setDay3] = useState([]);
  const [day4, setDay4] = useState([]);
  const [day5, setDay5] = useState([]);
  const [day6, setDay6] = useState([]);
  const [day7, setDay7] = useState([]);

  const [OnboardModalOpen, setOnboardModalOpen] = useState(false);
  const [lineUser, setLineUser] = useState(null);
  const [dateMarq, setDateMarq] = useState(null);
  const [isPreviewLock, setIsPreviewLock] = useState(false);
  const [isMovingModal, setIsMovingModal] = useState(false);
  const [yearlyEventsSearched, setYearlyEventsSearched] = useState(
    [],
  );
  const [taskSelectModalOpen, setTaskSelectModalOpen] = useState(
    false,
  );

  //EFFECTS
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    window.scrollTo(0, (document.body.scrollHeight - 400) / 2);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    fetchYearlyEvents();
  }, []);

  useEffect(() => {
    fetchInfos();
    fetchEvents();
    fetchYearlyEvents();
  }, [selDate]);

  //ASYNC DATA
  const fetchTemplates = async () => {
    await getTemplates(id).then((data) => {
      setMyTemplates(data);
    });
  };

  const fetchEvents = async () => {
    await getWeeklyEvents(id, selDate).then((data) => {
      if (data) {
        setMyEventsList(data.filtered);
        setAllEvents(data.all);
      } else {
        setMyEventsList({ ...defaultWeeklyStructure });
        setAllEvents([]);
      }
    });
  };

  const fetchInfos = async () => {
    let lineId = id;
    const defDate = moment(selDate).startOf('week');
    const day1 = await getDailyInfo(
      defDate.format('Y'),
      defDate.startOf('week').format('MM'),
      defDate.startOf('week').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });
    const day2 = await getDailyInfo(
      defDate.clone().add(1, 'day').format('Y'),
      defDate.clone().add(1, 'day').format('MM'),
      defDate.clone().add(1, 'day').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });
    const day3 = await getDailyInfo(
      defDate.clone().add(2, 'days').format('Y'),
      defDate.clone().add(2, 'day').format('MM'),
      defDate.clone().add(2, 'day').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });
    const day4 = await getDailyInfo(
      defDate.clone().add(3, 'days').format('Y'),
      defDate.clone().add(3, 'day').format('MM'),
      defDate.clone().add(3, 'day').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });
    const day5 = await getDailyInfo(
      defDate.clone().add(4, 'days').format('Y'),
      defDate.clone().add(4, 'day').format('MM'),
      defDate.clone().add(4, 'day').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });
    const day6 = await getDailyInfo(
      defDate.clone().add(5, 'days').format('Y'),
      defDate.clone().add(5, 'day').format('MM'),
      defDate.clone().add(5, 'day').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });
    const day7 = await getDailyInfo(
      defDate.clone().add(6, 'days').format('Y'),
      defDate.clone().add(6, 'day').format('MM'),
      defDate.clone().add(6, 'day').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });

    if (day1) {
      setDay1(day1);
      setBadge1(day1.badge);
    } else {
      setDay1(null);
      setBadge1(null);
    }
    if (day2) {
      setDay2(day2);
      setBadge2(day2.badge);
    } else {
      setDay2(null);
      setBadge2(null);
    }
    if (day3) {
      setDay3(day3);
      setBadge3(day3.badge);
    } else {
      setDay3(null);
      setBadge3(null);
    }
    if (day4) {
      setDay4(day4);
      setBadge4(day4.badge);
    } else {
      setDay4(null);
      setBadge4(null);
    }

    if (day5) {
      setDay5(day5);
      setBadge5(day5.badge);
    } else {
      setDay5(null);
      setBadge5(null);
    }

    if (day6) {
      setDay6(day6);
      setBadge6(day6.badge);
    } else {
      setDay6(null);
      setBadge6(null);
    }

    if (day7) {
      setDay7(day7);
      setBadge7(day7.badge);
    } else {
      setDay7(null);
      setBadge7(null);
    }
  };

  const fetchYearlyEvents = async () => {
    await getYearlyEvents(id).then((data) => {
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].start_date && data[i].end_date) {
          data[i].start_date = new Date(
            data[i].start_date.seconds * 1000 +
            data[i].start_date.nanoseconds / 1000000,
          );
          data[i].end_date = new Date(
            data[i].end_date.seconds * 1000 +
            data[i].end_date.nanoseconds / 1000000,
          );
        }
      }
      setYearlyEvents(data);
    });
    await searchYearlyEvents(id, selDate).then((data) => {
      setYearlyEventsSearched(data);
    });
  };



  //METHODS
  const moveEvent = async ({ event, start, end }) => {
    const idx = allEvents.indexOf(event);
    const updatedEvent = { ...event, start, end };
    updatedEvent.sentMessageToUser = false;
    const nextEvents = [...allEvents];
    nextEvents.splice(idx, 1, updatedEvent);
    setAllEvents(nextEvents);
    updatedEvent.sentMessageToUser = false;
    updateWeeklyEvent(updatedEvent).then(() => {
      fetchEvents();
    });
  };
  const resizeEvent = async ({ event, start, end }) => {
    const nextEvents = allEvents.map((existingEvent) => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });
    setAllEvents(nextEvents);
    updateWeeklyEvent({ ...event, start, end }).then(() => {
      fetchEvents();
    });
  };
  const addEvent = async (eventData) => {
    const event = { ...eventData };
    event.progress = eventData.progress || 0;
    event.user = id;
    const eventList = [...allEvents];
    eventList.push(event);
    setAllEvents(eventList);
    createWeeklyEvent(event).then(() => {
      fetchEvents();
    });
  };
  const editEvent = async (eve) => {
    const event = { ...eve };
    const idx = allEvents.indexOf(event);
    const updatedEvent = { ...event };
    const nextEvents = [...allEvents];
    nextEvents.splice(idx, 1, updatedEvent);
    setAllEvents(nextEvents);
    updateWeeklyEvent(updatedEvent).then(() => {
      fetchEvents();
    });
  };
  const copyEvent = async () => {
    const list = [...allEvents];
    const newEvent = { ...selected };
    newEvent.progress = 0;
    addEvent(newEvent);
    setSelected(null);
    handleClose();
  };
  const toggleSpeedDial = () => {
    setSelected(null);
    if (isSpeed) {
      setIsSpeed(false);
    } else {
      setIsSpeed(true);
    }
  };
  const toggleEventBg = () => {
    if (isEvent) {
      setIsEvent(false);
    } else {
      setIsEvent(true);
    }
  };
  const toggleGantModal = () => {
    handleClose();
    setGantModalOpen(true);
  };
  const toggleAddEventModal = () => {
    handleClose();
    setEntryModalOpen(true);
  };
  const entryModalEdit = (tabIndex) => {
    handleClose();
    setEntryModalOpen(true);
    setEntryModalIsEdit(true);
    setEntryModalIndex(tabIndex);
  };
  const toggleTemplateSelectModal = () => {
    handleClose();
    setTemplateSelectModalOpen(true);
  };
  const toggleBotModal = () => {
    handleClose();
    setLineBotSettingsOpen(true);
  };
  const toggleTaskEventDiffTotal = () => {
    handleClose();
    setTaskEventDiffTotalOpen(true);
  };
  const deleteEvent = async () => {
    handleClose();
    setEntryModalOpen(false);
    setTaskSelectModalOpen(false);
    setDeleteConfirmModalOpen(true);
  };
  const removeWeekTasks = async () => {
    const currentWeekTasks = { ...myEventsList };
    currentWeekTasks?.mon?.events.map((item) => {
      deleteWeeklyEvent(item.id);
    });
    currentWeekTasks?.tue?.events.map((item) => {
      deleteWeeklyEvent(item.id);
    });
    currentWeekTasks?.wed?.events.map((item) => {
      deleteWeeklyEvent(item.id);
    });
    currentWeekTasks?.thur?.events.map((item) => {
      deleteWeeklyEvent(item.id);
    });
    currentWeekTasks?.fri?.events.map((item) => {
      deleteWeeklyEvent(item.id);
    });
    currentWeekTasks?.sat?.events.map((item) => {
      deleteWeeklyEvent(item.id);
    });
    currentWeekTasks?.sun?.events.map((item) => {
      deleteWeeklyEvent(item.id);
    });
    fetchEvents();
  };
  const selectTemplate = async (template) => {
    setAllEvents([]);
    const templateEvents = { ...template.events };
    await removeWeekTasks();
    templateEvents?.mon?.events.map(async (evt) => {
      evt.progress = 0;
      await createWeeklyEvent(evt);
      fetchEvents();
    });
    templateEvents?.tue?.events.map(async (evt) => {
      evt.progress = 0;
      await createWeeklyEvent(evt);
    });

    templateEvents?.wed?.events.map(async (evt) => {
      evt.progress = 0;
      await createWeeklyEvent(evt);
    });

    templateEvents?.thur?.events.map(async (evt) => {
      evt.progress = 0;
      await createWeeklyEvent(evt);
    });

    templateEvents?.fri?.events.map(async (evt) => {
      evt.progress = 0;
      await createWeeklyEvent(evt);
    });

    templateEvents?.sat?.events.map(async (evt) => {
      evt.progress = 0;
      await createWeeklyEvent(evt);
    });

    templateEvents?.sun?.events.map(async (evt) => {
      evt.progress = 0;
      await createWeeklyEvent(evt);
    });

    setTemplateSelectModalOpen(false);
    fetchEvents();
  };
  const closeAndHideMover = async (hide) => {
    if (hide === true) {
      const vLineUser = { ...lineUser };
      vLineUser.showMover = false;
      await updateLineUser(vLineUser).then(() => {
        setOnboardModalOpen(false);
      });
    } else {
      setOnboardModalOpen(false);
    }
  };
  const closeWithGant = (hide) => {
    setGantModalOpen(true);
    if (hide === true) {
      closeAndHideMover(hide);
    }
    setOnboardModalOpen(false);
  };
  const saveTemplate = (templateName) => {
    const templateEvents = { ...myEventsList };

    const templateData = {
      name: templateName,
      events: templateEvents,
      lineId: context.userId,
    };

    createTemplate(templateData);
    fetchTemplates();
    setTemplateAddModalOpen(false);
    setTemplateSelectModalOpen(true);
  };
  const submitDeleteTemplate = async (id) => {
    await deleteTemplate(id).then(async () => {
      await fetchTemplates();
    });
  };
  const submitOverrideTemplate = async (id) => {
    const oldTemplate = { ...id };
    const templateEvents = { ...myEventsList };
    oldTemplate.events = templateEvents;
    updateTemplate(oldTemplate);
  };

  //HANDLERS
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setSelDate(moment(selDate).add(1, 'week').toDate());
    },
    onSwipedRight: () => {
      setSelDate(moment(selDate).subtract(1, 'week').toDate());
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  });

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const handleClose = () => {
    setIsEvent(false);
    setIsSpeed(false);
    setIsStopWatch(false);
    setEntryModalIsEdit(false);
    setEntryModalIndex(0);
  };
  const handleEventClick = (event) => {
    setEntryModalIndex(0);
    setSelected(event);
    if (!hasParent({ event: event })) {
      setTaskSelectModalOpen(true);
    } else {
      toggleEventBg();
    }
  };
  const handleGantModal = () => {
    getYearlyEvents(id).then((data) => {
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].start_date && data[i].end_date) {
          data[i].start_date = new Date(
            data[i].start_date.seconds * 1000 +
              data[i].start_date.nanoseconds / 1000000,
          );
          data[i].end_date = new Date(
            data[i].end_date.seconds * 1000 +
              data[i].end_date.nanoseconds / 1000000,
          );
        }
      }
      setYearlyEvents(data);
    });
    fetchEvents();
    fetchInfos();
    setGantModalOpen(false);
  };
  const handleSelectMarq = (slotInfo) => {
    const { start, end } = slotInfo;
    setDateMarq([start, end]);
    setEntryModalOpen(true);
  };

  //COMPUTED

  const returnTotalDifferenceChip = (tJiseki, tWari) => {
    let diff = tJiseki - tWari;

    let checker = Math.sign(diff);

    let mark = '±';
    let classVal = null;

    if (checker === -1) {
      mark = '';
      classVal = 'minus';
    }

    if (checker === 1) {
      mark = '+';
      classVal = 'plus';
    }
    return (
      <>
        <strong className={classVal}>
          <NumberFormat
            value={diff}
            prefix={mark}
            displayType={'text'}
            decimalScale={0}
            thousandSeparator={true}
            suffix={'分'}
          />
        </strong>
      </>
    );
  };

  const [message, setMessage] = useState('');

  const handleSendMessage = async () => {
    await sendMessage(id, message).then(() => {
      setMessage('');
    });
  };

  const submitBadge = async (val) => {
    if (val.id) {
      updateDailyInfo({
        id: val.id,
        year: val.year,
        month: val.month,
        day: val.day,
        badge: val.badge,
        user: val.user,
      }).then(() => {
        fetchInfos();
      });
    } else {
      createDailyInfo({
        user: val.user,
        year: val.year,
        month: val.month,
        day: val.day,
        badge: val.badge,
      }).then(() => {
        fetchInfos();
      });
    }
  };

  return (
    <>
      <div className="cal-wrap">
        <div className={props.classes.root}>
          <DragAndDropCalendar
            localizer={localizer}
            draggableAccessor={(event) => {
              return !isMobile ? true : false;
            }}
            resizeable={(event) => {
              return !isMobile ? true : false;
            }}
            className={props.classes.calendarWrap}
            selectable
            resizable
            events={allEvents}
            defaultView="week"
            views={['week']}
            onEventDrop={moveEvent}
            onEventResize={resizeEvent}
            date={selDate}
            onNavigate={(date) => setSelDate(date)}
            eventPropGetter={eventStyleGetter}
            onSelectEvent={(selectedEvent) =>
              handleEventClick(selectedEvent)
            }
            selected={selected}
            min={moment('24:00', 'HH:mm:ss').toDate()}
            max={moment('23:59', 'HH:mm:ss').toDate()}
            components={{
              toolbar: TeacherToolbar,
              week: {
                event: Event,
              },
            }}
            step={15}
            timeslots={4}
            formats={formats}
            onSelectSlot={handleSelectMarq}
          />
          <div className="actions">
            <button
              className="shu-kei"
              onClick={() => setTaskEventDiffTotalOpen(true)}
            >
              タスク別集計
            </button>
            <StudentSlider />
          </div>
        </div>
        <div className="line-calendar-footer kanri">
          <table>
            <tbody>
              <tr>
                <th className="base">
                  計画割当
                  <br />
                  <NumberFormat
                    value={myEventsList.current.totals.plannedMinutes}
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                </th>
                <td>
                  <NumberFormat
                    defaultValue={0}
                    value={
                      myEventsList.current.mon.totals.plannedMinutes
                    }
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                </td>
                <td>
                  <NumberFormat
                    defaultValue={0}
                    value={
                      myEventsList.current.tue.totals.plannedMinutes
                    }
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                </td>
                <td>
                  <NumberFormat
                    defaultValue={0}
                    value={
                      myEventsList.current.wed.totals.plannedMinutes
                    }
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                </td>
                <td>
                  <NumberFormat
                    defaultValue={0}
                    value={
                      myEventsList.current.thur.totals.plannedMinutes
                    }
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                </td>
                <td>
                  <NumberFormat
                    defaultValue={0}
                    value={
                      myEventsList.current.fri.totals.plannedMinutes
                    }
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                </td>
                <td>
                  <NumberFormat
                    defaultValue={0}
                    value={
                      myEventsList.current.sat.totals.plannedMinutes
                    }
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                </td>
                <td>
                  <NumberFormat
                    defaultValue={0}
                    value={
                      myEventsList.current.sun.totals.plannedMinutes
                    }
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                </td>
              </tr>
              <tr>
                <th className="real">
                  <strong className="chip">
                    <NumberFormat
                      value={
                        myEventsList.current.totals.progress -
                        myEventsList.current.totals.plannedMinutes
                      }
                      displayType={'text'}
                      decimalScale={0}
                      thousandSeparator={true}
                      suffix={'分'}
                    />
                  </strong>
                  実績
                  <br />
                  <NumberFormat
                    value={myEventsList.current.totals.progress}
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                </th>
                <td>
                  <NumberFormat
                    value={myEventsList.current.mon.totals.progress}
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                  {returnTotalDifferenceChip(
                    myEventsList.current.mon.totals.progress,
                    myEventsList.current.mon.totals.plannedMinutes,
                  )}
                </td>
                <td>
                  <NumberFormat
                    value={myEventsList.current.tue.totals.progress}
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                  {returnTotalDifferenceChip(
                    myEventsList.current.tue.totals.progress,
                    myEventsList.current.tue.totals.plannedMinutes,
                  )}
                </td>
                <td>
                  <NumberFormat
                    value={myEventsList.current.wed.totals.progress}
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                  {returnTotalDifferenceChip(
                    myEventsList.current.wed.totals.progress,
                    myEventsList.current.wed.totals.plannedMinutes,
                  )}
                </td>
                <td>
                  <NumberFormat
                    value={myEventsList.current.thur.totals.progress}
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                  {returnTotalDifferenceChip(
                    myEventsList.current.thur.totals.progress,
                    myEventsList.current.thur.totals.plannedMinutes,
                  )}
                </td>
                <td>
                  <NumberFormat
                    value={myEventsList.current.fri.totals.progress}
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                  {returnTotalDifferenceChip(
                    myEventsList.current.fri.totals.progress,
                    myEventsList.current.fri.totals.plannedMinutes,
                  )}
                </td>
                <td>
                  <NumberFormat
                    value={myEventsList.current.sat.totals.progress}
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                  {returnTotalDifferenceChip(
                    myEventsList.current.sat.totals.progress,
                    myEventsList.current.sat.totals.plannedMinutes,
                  )}
                </td>
                <td>
                  <NumberFormat
                    value={myEventsList.current.sun.totals.progress}
                    displayType={'text'}
                    decimalScale={0}
                    thousandSeparator={true}
                    suffix={'分'}
                  />
                  {returnTotalDifferenceChip(
                    myEventsList.current.sun.totals.progress,
                    myEventsList.current.sun.totals.plannedMinutes,
                  )}
                </td>
              </tr>
              <tr className="badges">
                <th />
                <td>
                  <div className="badges-grid">
                    <div className="buttons">
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day1 ? day1.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .format('DD'),
                            badge: badge1 === 1 ? null : 1,
                          });
                        }}
                        className={badge1 === 1 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge4.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day1 ? day1.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .format('DD'),
                            badge: badge1 === 2 ? null : 2,
                          });
                        }}
                        className={badge1 === 2 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge2.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day1 ? day1.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .format('DD'),
                            badge: badge1 === 3 ? null : 3,
                          });
                        }}
                        className={badge1 === 3 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge3.svg" />
                        </figure>
                      </button>
                    </div>
                    <div className="check">
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => {
                            submitBadge({
                              id: day1.id || null,
                              user: props.user,
                              year: moment(selDate)
                                .startOf('week')
                                .format('Y'),
                              month: moment(selDate)
                                .startOf('week')
                                .format('MM'),
                              day: moment(selDate)
                                .startOf('week')
                                .format('DD'),
                              badge: badge1 === 4 ? null : 4,
                            });
                          }}
                          checked={badge1 !== null}
                        />{' '}
                        確認済
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="badges-grid">
                    <div className="buttons">
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day2 ? day2.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(1, 'day')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(1, 'day')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(1, 'day')
                              .format('DD'),
                            badge: badge2 === 1 ? null : 1,
                          });
                        }}
                        className={badge2 === 1 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge4.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day2 ? day2.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(1, 'day')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(1, 'day')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(1, 'day')
                              .format('DD'),
                            badge: badge2 === 2 ? null : 2,
                          });
                        }}
                        className={badge2 === 2 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge2.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day2 ? day2.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(1, 'day')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(1, 'day')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(1, 'day')
                              .format('DD'),
                            badge: badge2 === 3 ? null : 3,
                          });
                        }}
                        className={badge2 === 3 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge3.svg" />
                        </figure>
                      </button>
                    </div>
                    <div className="check">
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => {
                            submitBadge({
                              id: day2 ? day2.id : null,
                              user: props.user,
                              year: moment(selDate)
                                .startOf('week')
                                .add(1, 'day')
                                .format('Y'),
                              month: moment(selDate)
                                .startOf('week')
                                .add(1, 'day')
                                .format('MM'),
                              day: moment(selDate)
                                .startOf('week')
                                .add(1, 'day')
                                .format('DD'),
                              badge: badge2 === 4 ? null : 4,
                            });
                          }}
                          checked={badge2 !== null}
                        />{' '}
                        確認済{' '}
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="badges-grid">
                    <div className="buttons">
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day3 ? day3.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(2, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(2, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(2, 'days')
                              .format('DD'),
                            badge: badge3 === 1 ? null : 1,
                          });
                        }}
                        className={badge3 === 1 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge4.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day3 ? day3.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(2, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(2, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(2, 'days')
                              .format('DD'),
                            badge: badge3 === 2 ? null : 2,
                          });
                        }}
                        className={badge3 === 2 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge2.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day3 ? day3.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(2, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(2, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(2, 'days')
                              .format('DD'),
                            badge: badge3 === 3 ? null : 3,
                          });
                        }}
                        className={badge3 === 3 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge3.svg" />
                        </figure>
                      </button>
                    </div>
                    <div className="check">
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => {
                            submitBadge({
                              id: day3 ? day3.id : null,
                              user: props.user,
                              year: moment(selDate)
                                .startOf('week')
                                .add(2, 'days')
                                .format('Y'),
                              month: moment(selDate)
                                .startOf('week')
                                .add(2, 'days')
                                .format('MM'),
                              day: moment(selDate)
                                .startOf('week')
                                .add(2, 'days')
                                .format('DD'),
                              badge: badge3 === 4 ? null : 4,
                            });
                          }}
                          checked={badge3 !== null}
                        />{' '}
                        確認済{' '}
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="badges-grid">
                    <div className="buttons">
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day4 ? day4.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(3, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(3, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(3, 'days')
                              .format('DD'),
                            badge: badge4 === 1 ? null : 1,
                          });
                        }}
                        className={badge4 === 1 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge4.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day4 ? day4.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(3, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(3, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(3, 'days')
                              .format('DD'),
                            badge: badge4 === 2 ? null : 2,
                          });
                        }}
                        className={badge4 === 2 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge2.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day4 ? day4.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(3, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(3, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(3, 'days')
                              .format('DD'),
                            badge: badge4 === 3 ? null : 3,
                          });
                        }}
                        className={badge4 === 3 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge3.svg" />
                        </figure>
                      </button>
                    </div>
                    <div className="check">
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => {
                            submitBadge({
                              id: day4 ? day4.id : null,
                              user: props.user,
                              year: moment(selDate)
                                .startOf('week')
                                .add(3, 'days')
                                .format('Y'),
                              month: moment(selDate)
                                .startOf('week')
                                .add(3, 'days')
                                .format('MM'),
                              day: moment(selDate)
                                .startOf('week')
                                .add(3, 'days')
                                .format('DD'),
                              badge: badge4 === 4 ? null : 4,
                            });
                          }}
                          checked={badge4 !== null}
                        />{' '}
                        確認済
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="badges-grid">
                    <div className="buttons">
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day5 ? day5.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(4, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(4, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(4, 'days')
                              .format('DD'),
                            badge: badge5 === 1 ? null : 1,
                          });
                        }}
                        className={badge5 === 1 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge4.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day5 ? day5.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(4, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(4, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(4, 'days')
                              .format('DD'),
                            badge: badge5 === 2 ? null : 2,
                          });
                        }}
                        className={badge5 === 2 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge2.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day5 ? day5.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(4, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(4, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(4, 'days')
                              .format('DD'),
                            badge: badge5 === 3 ? null : 3,
                          });
                        }}
                        className={badge5 === 3 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge3.svg" />
                        </figure>
                      </button>
                    </div>
                    <div className="check">
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => {
                            submitBadge({
                              id: day5 ? day5.id : null,
                              user: props.user,
                              year: moment(selDate)
                                .startOf('week')
                                .add(4, 'days')
                                .format('Y'),
                              month: moment(selDate)
                                .startOf('week')
                                .add(4, 'days')
                                .format('MM'),
                              day: moment(selDate)
                                .startOf('week')
                                .add(4, 'days')
                                .format('DD'),
                              badge: badge5 === 4 ? null : 4,
                            });
                          }}
                          checked={badge5 !== null}
                        />{' '}
                        確認済
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="badges-grid">
                    <div className="buttons">
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day6 ? day6.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(5, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(5, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(5, 'days')
                              .format('DD'),
                            badge: badge6 === 1 ? null : 1,
                          });
                        }}
                        className={badge6 === 1 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge4.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day6 ? day6.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(5, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(5, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(5, 'days')
                              .format('DD'),
                            badge: badge6 === 2 ? null : 2,
                          });
                        }}
                        className={badge6 === 2 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge2.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day6 ? day6.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(5, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(5, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(5, 'days')
                              .format('DD'),
                            badge: badge6 === 3 ? null : 3,
                          });
                        }}
                        className={badge6 === 3 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge3.svg" />
                        </figure>
                      </button>
                    </div>
                    <div className="check">
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => {
                            submitBadge({
                              id: day6 ? day6.id : null,
                              user: props.user,
                              year: moment(selDate)
                                .startOf('week')
                                .add(5, 'days')
                                .format('Y'),
                              month: moment(selDate)
                                .startOf('week')
                                .add(5, 'days')
                                .format('MM'),
                              day: moment(selDate)
                                .startOf('week')
                                .add(5, 'days')
                                .format('DD'),
                              badge: badge6 === 4 ? null : 4,
                            });
                          }}
                          checked={badge6 !== null}
                        />{' '}
                        確認済{' '}
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="badges-grid">
                    <div className="buttons">
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day7 ? day7.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(6, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(6, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(6, 'days')
                              .format('DD'),
                            badge: badge7 === 1 ? null : 1,
                          });
                        }}
                        className={badge7 === 1 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge4.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day7 ? day7.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(6, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(6, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(6, 'days')
                              .format('DD'),
                            badge: badge7 === 2 ? null : 2,
                          });
                        }}
                        className={badge7 === 2 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge2.svg" />
                        </figure>
                      </button>
                      <button
                        onClick={() => {
                          submitBadge({
                            id: day7 ? day7.id : null,
                            user: props.user,
                            year: moment(selDate)
                              .startOf('week')
                              .add(6, 'days')
                              .format('Y'),
                            month: moment(selDate)
                              .startOf('week')
                              .add(6, 'days')
                              .format('MM'),
                            day: moment(selDate)
                              .startOf('week')
                              .add(6, 'days')
                              .format('DD'),
                            badge: badge7 === 3 ? null : 3,
                          });
                        }}
                        className={badge7 === 3 ? 'active' : null}
                      >
                        <figure className="badge">
                          <img src="/badges/badge3.svg" />
                        </figure>
                      </button>
                    </div>
                    <div className="check">
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => {
                            submitBadge({
                              id: day7 ? day7.id : null,
                              user: props.user,
                              year: moment(selDate)
                                .startOf('week')
                                .add(6, 'days')
                                .format('Y'),
                              month: moment(selDate)
                                .startOf('week')
                                .add(6, 'days')
                                .format('MM'),
                              day: moment(selDate)
                                .startOf('week')
                                .add(6, 'days')
                                .format('DD'),
                              badge: badge7 === 4 ? null : 4,
                            });
                          }}
                          checked={badge7 !== null}
                        />{' '}
                        確認済
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className="studen-message">
                <td colSpan="6" className="student-message-input">
                  <textarea
                    className="message-input"
                    type="text"
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="受講者へのメッセージ"
                    value={message}
                  />
                </td>
                <td colSpan="2" className="student-message-submit">
                  <button
                    className="message-button"
                    onClick={handleSendMessage}
                  >
                    メッセージ送信
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {isSpeed || isEvent ? (
          <div className="modal" onClick={() => handleClose()} />
        ) : null}
      </div>
      {isEvent ? (
        <div className="event-buttons">
          <ul>
            <li>
              <button onClick={() => entryModalEdit(0)}>
                計画割当編集
              </button>
            </li>
            <li>
              <button onClick={() => entryModalEdit(1)}>
                実績入力
              </button>
            </li>
            <li>
              <button onClick={() => copyEvent()}>コピー</button>
            </li>
            <li>
              <button onClick={() => deleteEvent()}>削除</button>
            </li>
          </ul>
        </div>
      ) : null}
      <GantModal
        open={gantModalOpen}
        user={id}
        close={() => handleGantModal()}
        onClose={() => handleGantModal()}
      />

      <TemplateSelectModal
        open={templateSelectModalOpen}
        close={() => setTemplateSelectModalOpen(false)}
        new={() => {
          setTemplateSelectModalOpen(false);
          setTemplateAddModalOpen(true);
        }}
        templates={myTemplates}
        templateSelected={selectTemplate}
        weekTasks={myEventsList}
        submitDelete={submitDeleteTemplate}
        submitOverride={submitOverrideTemplate}
      />
      <TemplateAddModal
        open={templateAddModalOpen}
        close={() => setTemplateAddModalOpen(false)}
        onCreate={saveTemplate}
        onDelete={deleteTemplate}
      />
      <CreateEventModal
        open={entryModalOpen}
        close={() => {
          setEntryModalOpen(false);
          setSelected(null);
          fetchEvents();
          setDateMarq(null);
        }}
        onClose={() => {
          setEntryModalOpen(false);
          setSelected(null);
          fetchEvents();
          setDateMarq(null);
        }}
        isEdit={entryModalIsEdit}
        dateMarq={dateMarq}
        selected={selected}
        save={(obj) => {
          addEvent(obj);
        }}
        edit={(obj) => {
          editEvent(obj);
        }}
        tabIndex={entryModalIndex}
        events={myEventsList}
        tasksS={yearlyEventsSearched}
        tasks={yearlyEvents.filter((task) => {
          return task.type === 'task' || task.type === 'routine';
        })}
        gantModalStatus={gantModalOpen}
        selDate={selDate}
        deleteTask={() => deleteEvent()}
      />
      <TaskEventDiffTotalModal
        open={TaskEventDiffTotalOpen}
        close={() => setTaskEventDiffTotalOpen(false)}
        events={myEventsList}
        tasks={yearlyEvents.filter((task) => {
          return task.type === 'task' || task.type === 'routine';
        })}
        selDate={selDate}
      />
      <ChooseTaskModal
        events={myEventsList}
        tasksS={yearlyEventsSearched}
        tasks={yearlyEvents.filter((task) => {
          return task.type === 'task' || task.type === 'routine';
        })}
        selected={selected}
        deleteTask={() => deleteEvent()}
        edit={(obj) => {
          editEvent(obj);
        }}
        open={taskSelectModalOpen}
        close={() => {
          setTaskSelectModalOpen(false);
          setSelected(null);
          fetchEvents();
          setDateMarq(null);
        }}
        onClose={() => {
          setTaskSelectModalOpen(false);
          setSelected(null);
          fetchEvents();
          setDateMarq(null);
        }}
        selDate={selDate}
      />
      <DeleteConfirmModal
        open={selected && DeleteConfirmModalOpen}
        close={() => setDeleteConfirmModalOpen(false)}
        onClose={() => {
          setEditModalOpen(false);
          setSelected(null);
          fetchEvents();
        }}
        submit={() => {
          deleteWeeklyEvent(selected.id).then(async () => {
            setSelected(null);
            await fetchEvents();
          });
        }}
      />
      
    </>
  );
};

export default withStyles(styles)(StudentCalendar);
