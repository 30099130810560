import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './PageTitle.scoped.scss';

const PageTitle = (props) => {
  const {title, icon} = props;

  return (
    <h1 className="page-title">{(icon) ? <FontAwesomeIcon icon={icon} color="#B6263D" /> : null}<span>{title}</span></h1>
  );
}

export default PageTitle;