import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

const config = {
  apiKey: "AIzaSyDeV1gVgtuJzbKfdmdIMXDu2Fy6XUmxTq4",
  authDomain: "future-log-67799.firebaseapp.com",
  projectId: "future-log-67799",
  storageBucket: "future-log-67799.appspot.com",
  messagingSenderId: "337593555009",
  appId: "1:337593555009:web:6bc12bf96d00005cf48ec0",
  measurementId: "G-DSJRD2QR53"
};


class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config);
    }


    this.auth = app.auth();
    //this.db = app.database();
    this.fs = app.firestore();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => {return this.auth.sendPasswordResetEmail(email)};

  confirmPasswordReset = (code, password) => this.auth.confirmPasswordReset(code, password);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** User API ***

  currentUser = () => {
    return this.auth.currentUser
  };

  //user = uid => this.db.ref(`users/${uid}`);

  //users = () => this.db.ref('users');


  /** LINE API */

  addLineUser = (id, profile) => {
    const ref = this.fs.collection(`/lineUsers`).doc(id);
    ref.set(profile);
  }
}

export default Firebase;
