import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { withFirebase } from '../components/Firebase';

import "./scss/PasswordForgot.scoped.scss";

const translateError = (err) => {
  switch (err) {
    case "The email address is badly formatted.": return "メールアドレスは正式ではありません。"; break;
    case "There is no user record corresponding to this identifier. The user may have been deleted.": return "このメールアドレスのアカウントは存在しません。"
    default: return err;
  }
}

const RenderForm = (props) => {
  return (
    <div className="login-form">

      <div className="form-item">
        <label>メールアドレス</label>
        <div className="input-wrapper">
          <input
            type="email"
            value={props.email}
            onChange={(e) => props.setEmail(e.target.value)}
            placeholder={'name@example.com'}
          />

        </div>
        {props.error ? (<p style={{ color: "red", fontSize: 12, marginTop: 5 }}>{translateError(props.error)}</p>) : ''}
      </div>
      <div className="form-submit">
        <button
          className={!props.email ? 'grad-button disabled' : 'grad-button'}
          onClick={() => props.submit()}
        >
          送信
        </button>
      </div>
      <p className="form-action">
        <Link to="/console">ログインに戻る</Link>
      </p>
    </div>
  )
}

const MailSent = () => {
  return (
    <div className="login-form">
      <p className="sent">パスワード再設定のメールを送信しました。</p>
      <p className="form-action">
        <Link to="/console" className="grad-button">ログインに戻る</Link>
      </p>
    </div>
  )
}

const PasswordForgot = (props) => {
  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [mailError, setMailError] = useState(null);

  const handleSubmit = async () => {
    await props.firebase.doPasswordReset(email).then((data) => {
      setMailSent(true);
    }).catch(err => {
      setIsError(err.message);
    })

  }

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <figure className="login-logo">
          <img src="/flog-logo.png" alt="" />
          <figcaption>
            パスワード再発行
          </figcaption>
        </figure>
        {!mailSent ? (<RenderForm error={mailError} submit={handleSubmit} email={email} setEmail={setEmail} error={isError} />) : (<MailSent />)}
      </div>
    </div>
  );
};

export default withFirebase(PasswordForgot);