import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import './dialogues.scoped.scss';

export default props => {
  const [isError, setIsError] = useState(false);
  const [locName, setLocName] = useState('');

  useEffect(() => {
    setLocName(props.editingLoc ? props.editingLoc.title : '');
  }, [props.editingLoc]);

  const handleSubmit = () => {
    if (locName === '') {
      setIsError(true);
      return false;
    }

    if (props.isEdit) {
      props.editLocSubmit({
        id: props.editingLoc.id,
        title: locName,
      });
    } else {
      props.newLocSubmit(locName);
    }
  };

  return (
    <>
      <Dialog
        open={props.show}
        onClose={props.close}
        aria-labelledby="form-dialog-title"
        className="user-diag"
      >
        <DialogTitle id="form-dialog-title">
          {props.isEdit ? '校舎の編集' : '校舎の新規作成'}
        </DialogTitle>
        <DialogContent>
          <label className="form-label">校舎名</label>
          <input
            className={isError ? 'form-input error' : 'form-input'}
            value={locName}
            onChange={e => setLocName(e.target.value)}
          />
          {isError ? (
            <p className="error">校舎名を入力して下さい。</p>
          ) : null}
          <div className="button-wrap">
            <button
              className="grad-button"
              onClick={() => handleSubmit()}
            >
              {props.isEdit ? '校舎を編集する' : '校舎を追加する'}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
