import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';

import './YearLeveler.scoped.scss';
import './Masters.scoped.scss';

const YearLeveler = (props) => {
    const {addYearToStudents} = props;
    
    return (
        <div className="master-panel">
            <h2 className="master-panel-title">属性/学年更新</h2>
            <div className="year-leveler">
                <p className="title">受講者の属性/学年更新</p>
                <p className="button"><Button variant="contained" color="secondary" onClick={addYearToStudents}>属性/学年を上げる</Button></p>
            </div>
        </div>
    )
}

export default YearLeveler;