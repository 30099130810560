import React, { useState, useEffect } from 'react';
import { useLiff } from 'react-liff';
import Marquee  from 'react-double-marquee';

import moment from 'moment';
import 'moment/locale/ja';

import './FurikaeruMessage.scoped.scss';

import {
    getWeeklyInfo,
    updateWeeklyInfo,
    createWeeklyInfo,
} from '../../../api/weeklyinfo';

import './FurikaeruMessage.scoped.scss';

const FurikaeruMessage = props => {
    const { error, liff, isLoggedIn, ready } = useLiff();
    const context = liff.getContext();
    const lineId = context.userId;

    const { weeklyInfo, fetchWeeklyInfo, openFurikaeri, date, onMessageChange } = props;

    const [isEdit, setIsEdit] = useState(false);
    const [message, setMessage] = useState('');
    const [info, setInfo] = useState(null);

    useEffect(() => {
        if (!weeklyInfo) {
            setInfo(null)
        } else {
            setInfo(weeklyInfo);
        }
    }, [weeklyInfo])

    useEffect(() => {
        fetchWeeklyInfo();
    }, [date])

    const postWeeklyMessage = async () => {
        if (info?.id) {
            await updateWeeklyInfo({
                id: info.id,
                message: info?.message ? info.message : '',
                key:info.key
            }).then(async () => {
                await fetchWeeklyInfo()
            });
        } else {
            const infoObjItem = {
                user: lineId,
                message: info?.message ? info.message : '',
                lookBack: ['', '', '']
            }
            await createWeeklyInfo(infoObjItem, date).then(async () => {
                await fetchWeeklyInfo();
            });
        }
    };

    const toggleMessageEdit = () => {
        if (isEdit) {
            postWeeklyMessage().then(() => {
                setIsEdit(false);
            });

        } else {
            setIsEdit(true);
        }
    };

    return (
        <>
            <div className="teacherInput">
                {isEdit ? (
                    <input
                        placeholder="次の1週間で、あなたは何を成し遂げますか？(先週の反省を参照しましょう)"
                        onChange={(e) => {
                            const infoObj = (info) ? {...info, message: e.target.value} : {message:e.target.value}
                            setInfo(infoObj)}}
                        disabled={!isEdit}
                        type="text"
                        value={info?.message}
                    />
                ) : (
                    <div className="marq-message-wrapper">
                        <Marquee direction="left" delay={0} scrollWhen="overflow">
                            {info?.message
                                ? info?.message
                                : '次の1週間で、あなたは何を成し遂げますか？(先週の反省を参照しましょう)'}
                        </Marquee>
                    </div>
                )}
                <button className="button" onClick={() => toggleMessageEdit()}>
                    {isEdit ? '保存' : '編集'}
                </button>
                <button className="button furikaeri" style={{ backgroundColor: "#e26579" }} onClick={() => openFurikaeri()}>振り返り</button>
            </div>
                                
        </>
    );
};

export default FurikaeruMessage;
