import * as React from 'react';
import { useLiff } from 'react-liff';
import Marquee  from 'react-double-marquee';

import moment from 'moment';
import 'moment/locale/ja';

import './TeacherToolbar.scoped.scss';

export interface ICustomTooolbarProps {
  view: string;
  views: string[];
  label: any;
  localizer: any;
  onNavigate: (action: any) => void;
  onView: (view: any) => void;
  onViewChange: (view: any) => void;
  messages: any;
}

export const navigateContants = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
};

export const views = {
  MONTH: 'month',
  WEEK: 'week',
  WORK_WEEK: 'work_week',
  DAY: 'day',
  AGENDA: 'agenda',
};

function weekOfMonth(m) {
  var day = m.day();
  var nthOfMoth = Math.ceil(m.date() / 7);
  return nthOfMoth;
}

const CustomToolbar: React.SFC<ICustomTooolbarProps> = props => {

  function navigate(action) {
    props.onNavigate(action);
  }

  function viewItem(view) {
    props.onViewChange(view);
  }

  return (
    <>
      <div className="rbc-toolbar" style={{ padding: '20px' }}>
        <span className="rbc-btn-group">
          <button
            type="button"
            onClick={navigate.bind(null, navigateContants.TODAY)}
          >
            今週
          </button>
          <button
            type="button"
            onClick={navigate.bind(null, navigateContants.PREVIOUS)}
          >
            前
          </button>
          <button
            type="button"
            onClick={navigate.bind(null, navigateContants.NEXT)}
          >
            次
          </button>
        </span>

        <span className="rbc-toolbar-label">{props.label}</span>
      </div>
    </>
  );
};

export default CustomToolbar;
