import React from 'react';

import PageTitle from './PageTitle';
import SearchBox from './SearchBox';

import './TitleWithSearch.scoped.scss';

const PageWrapper = props => {
  const { title, icon, value, updateHandler, placeholder } = props;

  return (
    <div className="title-search-wrapper">
      <PageTitle title={title} icon={icon} />
      <SearchBox
        value={value}
        updateHandler={updateHandler}
        placeholder={placeholder}
      />
    </div>
  );
};

export default PageWrapper;
