import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { currentUser } from '../../api/users';
import { getLineUser, getLineUsers } from '../../api/lineUsers';

import './StudentSlider.scoped.scss';

const StudentSlider = props => {
  const { id } = useParams()
  const [students, setStudents] = useState([]);
  const [pIndex, setPIndex] = useState(0);
  const [before, setBefore] = useState();
  const [after, setAfter] = useState();
  const [lineUser, setLineUser] = useState();
  const cu = currentUser();

  useEffect(() => {
    if (!cu) return;
    getLineUsers(cu).then(data => {
      const ids = [];
      data.map(item => {
        ids.push(item.id);
      });
      setStudents(ids);
    });
  }, [cu])

  useEffect(() => {
    getLineUser(id).then(data => {
      setLineUser(data)
    });
  }, [id]);


  useEffect(() => {
    setPIndex(students.indexOf(id));
  }, [students, id])

  useEffect(() => {

    if (students.length === 1 || students.length === 0) {
      setAfter(pIndex);
      setBefore(pIndex);
    }

    if (pIndex === (students.length - 1)) {
      setAfter(0);
    } else {
      setAfter(pIndex + 1);
    }

    if (pIndex === 0) {
      setBefore(students.length - 1)
    } else {
      setBefore(pIndex - 1);
    }

  }, [pIndex])

  return (
    <div className="slider-wrapper">
      <p>{(lineUser) ? lineUser.name : null}</p>
      {(students.length) ? <div className="student-slider">
        <a onClick={() => window.location.href = `/console/students/${students[before]}/weekly`}>＜</a>
        <a onClick={() => window.location.href = `/console/students/${students[after]}/weekly`}>＞</a>
      </div> : null}
    </div>
  );
};

export default StudentSlider;
