import React, { useState, useEffect } from 'react';
import { withFirebase } from '../../components/Firebase';
import { useLiff } from 'react-liff';

import { useHistory } from "react-router-dom";

import { getDepartments } from '../../api/departments';
import { getClassifications } from '../../api/classifications';
import { getCourses } from '../../api/courses';
import { getSchools } from '../../api/schools';

import { createLineUser } from '../../api/lineUsers';

import './LineRegister.scoped.scss';

const LineRegister = props => {
  const [displayName, setDisplayName] = useState('');
  const { error, liff, isLoggedIn, ready } = useLiff();

  const [courses, setCourses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [schools, setSchools] = useState([]);

  const [name, setName] = useState('');
  const [studentId, setStudentId] = useState('');
  const [department, setDepartment] = useState(0);
  const [school, setSchool] = useState(0);
  const [course, setCourse] = useState(0);
  const [year, setYear] = useState(0);
  const [classification, setClassification] = useState(0);

  const history = useHistory();

  const getdepfromid = id => {
    const team = departments.filter(tm => tm.id === id);
    if (team.length) {
      return team[0];
    }
    return null;
  }

  useEffect(() => {
    (async () => {
      getDepartments().then(data => {
        setDepartments(data);
        setDepartment(data[0].id)
      });
      getClassifications().then(data => {
        setClassifications(data);
        setClassification(data[0].id)
      });
      getCourses().then(data => {
        setCourse(data[0].id)
        setCourses(data);
      });
      getSchools().then(data => {
        setSchool(data[0].id)
        setSchools(data);
      });
      setYear(1)
    })();
  }, []);

  const submit = async () => {
    const context = await liff.getContext();
    const profile = await liff.getDecodedIDToken();

    const lineUserObj = {
      lineId : context.userId,
      lineId : context.userId,
      name : name,
      avatar : profile.picture,
      displayName : profile.name,
      schoolYear : year,
      school : school,
      department : department,
      studentId : studentId
    }
    await createLineUser(lineUserObj).then(() => {
      history.push('/lineapp')
    });
  };

  return (
    <div className="new-user-form">
      <div className="new-user-form-inner">
        <figure>
          <img src="./flog-logo.png" />
          <figcaption>新規登録(ユーザー)</figcaption>
        </figure>
        <div className="form">
          <div className="input-item">
            <label>氏名</label>
            <input type="text" value={name} onChange={(e)=>setName(e.target.value)} />
          </div>
          <div className="input-item">
            <label>ID</label>
            <input type="text" value={studentId} onChange={(e)=>setStudentId(e.target.value)} />
          </div>
          <div className="input-item">
            <label>部門</label>
            <select value={department} onChange={(e) => setDepartment(e.target.value)}>
              {departments.map(item => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.title}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="input-item">
            <label>校舎</label>
            {department ? <select value={school} onChange={(e) => setSchool(e.target.value)}>
              {schools.filter(item=>{return getdepfromid(department)?.schools?.includes(item.id)}).map(item => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.title}
                  </option>
                );
              })}
            </select> : <p>部門を選択して下さい。</p>}
            
          </div>
          
          <div className="input-item">
            <label>属性/学年</label>
            <select value={year} onChange={(e) => setYear(e.target.value)}>
            <option value="1">社会人</option>
            <option value="2">高卒・浪人生</option>
            <option value="3">高校3年生</option>
            <option value="4">高校2年生</option>
            <option value="5">高校1年生</option>
            <option value="6">中学3年生</option>
            <option value="7">中学2年生</option>
            <option value="8">中学1年生</option>
            <option value="9">小学6年生</option>
            <option value="10">小学5年生</option>
            <option value="11">小学4年生</option>
            <option value="12">小学3年生</option>
            <option value="13">小学2年生</option>
            <option value="14">小学1年生</option>
            <option value="15">その他</option>
            </select>
          </div>
          <div className="submit">
            <button className="grad-button" onClick={submit}>
              登録
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(LineRegister);
