import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {
    cleanTaskData
} from '../../../api/weeklyEvents';

const moment = extendMoment(Moment);
moment.locale('ja', {
    week: {
        dow: 1,
        doy: 1,
    },
});

const EventModalTabsHeader = (props) => {
    const hasParent = (event) => {
        const that = event.event;
        if (!that?.yearTask) return false;
        const parent = cleanTaskData(that.yearTask)
        const st = moment(that.start);
        const en = moment(that.end);
        const taskRange = moment.range(st, en);
        const parentRange = moment.range(moment(parent.start_date), moment(parent.end_date));
        const overlaps = taskRange.overlaps(parentRange)

        return overlaps;
    }

    const { value, onChange, selected, dateMarq } = props;

    const showJisekiTab = () => {
        if(dateMarq) return true;
        return hasParent({ event: selected })
    }

    return (<AppBar position="static">
        <Tabs
            value={value}
            onChange={onChange}

        >
            <Tab label="計画割当" />
            {showJisekiTab() ? <Tab label="実績" /> : null}

        </Tabs>
    </AppBar>)
}

export default EventModalTabsHeader;