import React, {
  useState,
} from 'react';

import {useParams} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CachedIcon from '@material-ui/icons/Cached';
import Gant from './Gant';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'sticky',
    top:0
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GantModal(props) {
  const [gantObj, setGantObj] = useState(null);
const {id} = useParams();
  const classes = useStyles();

  return (
    <>
      <Dialog
        id="student-gant-modal"
        fullScreen
        onEntered={() => {
          setGantObj(gantt);
        }}
        open={props.open}
        onClose={props.close}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              目標達成管理表
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={()=>{props.reload()}}
              aria-label="close"
            >
              <CachedIcon />
            </IconButton>
            <IconButton
            style={{marginRight:20}}
              edge="start"
              color="inherit"
              onClick={props.close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Gant
          events={props.events ? props.events : { data: [] }}
          gantObj={gantObj}
          user={id}
          reload={props.reload}
        />
      </Dialog>
    </>
  );
}
