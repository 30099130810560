import React, { useState, useEffect } from 'react';
import StudentListItem from './StudentListItem';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';

import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteStudentDialogue from './DeleteStudentDialogue';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import './StudentList.scoped.scss';

export default props => {
  const [selected, setSelected] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [deleteDia, setDeleteDia] = React.useState(false);
  const [filteredUsers, setFilteredUsers] = useState(props.users);
  const [anchorElTeam, setAnchorElTeam] = React.useState(null);
  const [anchorElLoc, setAnchorElLoc] = React.useState(null);

  const [depFilters, setDepFilters] = React.useState([]);
  const [schoolFilters, setSchoolFilters] = React.useState([]);
  const [tagFilters, setTagFilters] = React.useState([]);

  const [depAnchor, setDepAnchor] = React.useState(null);
  const [schoolAnchor, setSchoolAnchor] = React.useState(null);
  const [tagAnchor, setTagAnchor] = React.useState(null);

  useEffect(() => {
    const userList = [...props.users];

    const filUserList = userList.filter(item => {
      let hasDep = false;
      let hasSchool = false;
      let hasTag = false;

      if (depFilters.length !== 0) {
        if (depFilters.includes(item.department)) hasDep = true;
      } else {
        hasDep = true;
      }


      if (schoolFilters.length !== 0) {
        if (schoolFilters.includes(item.school)) hasSchool = true;
      } else {
        hasSchool = true;
      }

      if (tagFilters.length !== 0) {
        for (let i = 0; i <= item.tags.length; i++) {
          if (tagFilters.includes(item.tags[i]))
            hasTag = true;
        }
      } else {
        hasTag = true;
      }

      return hasDep && hasSchool && hasTag;
    });

    setFilteredUsers(filUserList);
  }, [props.users, depFilters, schoolFilters, tagFilters]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = props.users.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelect = id => {
    const sel = [...selected];
    const hasItem = sel.indexOf(id);
    if (hasItem !== -1) {
      sel.splice(hasItem, 1);
    } else {
      sel.push(id);
    }
    setSelected(sel);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const renderDummyCells = () => {
    const dummyCells = [];
    for (let i = 0; i < 10; i++) {
      dummyCells.push(
        <TableRow key={i}>
          <TableCell />
        </TableRow>,
      );
    }
    return dummyCells;
  };

  const renderDepFilter = () => {
    return (
      <>
        <IconButton
          size="small"
          onClick={e => setDepAnchor(e.currentTarget)}
        >
          <FilterListIcon fontSize="small" />
        </IconButton>
        <Menu
          anchorEl={depAnchor}
          keepMounted
          open={Boolean(depAnchor)}
          onClose={() => {
            setDepAnchor(null);
          }}
        >
          {props.fireData.departments.map(item => (
            <ListItem
              key={item.id}
              role={undefined}
              dense
              button
              onClick={handleDepToggle(item.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={depFilters.indexOf(item.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </Menu>
      </>
    );
  };

  const renderSchoolFilter = () => {
    return (
      <>
        <IconButton
          size="small"
          onClick={e => setSchoolAnchor(e.currentTarget)}
        >
          <FilterListIcon fontSize="small" />
        </IconButton>
        <Menu
          anchorEl={schoolAnchor}
          keepMounted
          open={Boolean(schoolAnchor)}
          onClose={() => {
            setSchoolAnchor(null);
          }}
        >
          {props.fireData.schools.map(item => (
            <ListItem
              key={item.id}
              role={undefined}
              dense
              button
              onClick={handleSchoolToggle(item.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={schoolFilters.indexOf(item.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </Menu>
      </>
    );
  };

  const renderTagFilter = () => {
    return (
      <>
        <IconButton
          size="small"
          onClick={e => setTagAnchor(e.currentTarget)}
        >
          <FilterListIcon fontSize="small" />
        </IconButton>
        <Menu
          anchorEl={tagAnchor}
          keepMounted
          open={Boolean(tagAnchor)}
          onClose={() => {
            setTagAnchor(null);
          }}
        >
          {props.fireData.tags.map(item => (
            <ListItem
              key={item.id}
              role={undefined}
              dense
              button
              onClick={handleTagToggle(item.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={tagFilters.indexOf(item.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </Menu>
      </>
    );
  };

  const handleDepToggle = value => () => {
    const currentIndex = depFilters.indexOf(value);
    const newChecked = [...depFilters];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setDepFilters(newChecked);
  };

  const handleSchoolToggle = value => () => {
    const currentIndex = schoolFilters.indexOf(value);
    const newChecked = [...schoolFilters];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSchoolFilters(newChecked);
  };

  const handleTagToggle = value => () => {
    const currentIndex = tagFilters.indexOf(value);
    const newChecked = [...tagFilters];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setTagFilters(newChecked);
  };

  return (
    <div className="list-wrapper">
      <div
        className={props.loading ? 'loading user-list' : 'user-list'}
      >
        <div className="user-list-header">
          <Checkbox onChange={handleSelectAllClick} />
          {selected.length > 0 ? (
            <Button onClick={() => setDeleteDia(true)}>
              <DeleteIcon />
            </Button>
          ) : null}
        </div>
        <TableContainer component={Paper}>
          <Table className="user-table" aria-label="ユーザー">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>ユーザー名</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>部門 {renderDepFilter()}</TableCell>
                <TableCell>校舎 {renderSchoolFilter()}</TableCell>
                <TableCell>タグ {renderTagFilter()}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {props.users.length === 0 && !props.isSearch
                ? renderDummyCells()
                : filteredUsers.map((item, index) => {
                    return (
                      <StudentListItem
                        key={item.id}
                        user={item}
                        selected={isSelected(item.id)}
                        id={((selected.length > 1) ? selected : [selected[0]])}
                        select={handleSelect}
                        fireData={props.fireData}
                        handleDeleteUser={props.handleDeleteUser}
                        handleDeleteMultiple={props.handleDeleteMultiple}
                      />
                    );
                  })}
              {props.users.length === 0 && props.isSearch ? (
                <TableRow>
                  <TableCell colspan="8">
                    検索結果はございませんでした。
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
        <DeleteStudentDialogue
          id={((selected.length > 1) ? selected : [selected[0]])}
          open={deleteDia}
          handleDeleteUser={props.handleDeleteUser}
          handleDeleteMultiple={props.handleDeleteMultiple}
          close={() => {setDeleteDia(false); setSelected([]);}}
        />
      </div>
      {props.loading ? (
        <div className="loader">
          <CircularProgress size={124} />
        </div>
      ) : null}
    </div>
  );
};
