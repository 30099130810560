import React, { useState, useEffect } from 'react';
import { withAuthorization } from '../components/Session';

import App from '../components/Global/App';
import TitleWithSearch from '../components/Common/TitleWithSearch';
import StudentList from '../components/Students/StudentList';

import {currentUser} from '../api/users';

import { getLineUsers, deleteLineUser } from '../api/lineUsers';

import {getDepartments} from '../api/departments';
import {getSchools} from '../api/schools';
import{ getClassifications} from '../api/classifications';
import {getCourses} from '../api/courses';
import { getTags } from '../api/tags';

const Students = props => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [search, setSearch] = useState('');
  const [loadingUsers, setLoadingUsers] = useState(true);

  function find(items, text) {
    text = text.split(' ');
    return items.filter(function(item) {
      return text.every(function(el) {
        const hasEmail = item.displayName.indexOf(el) > -1;
        const hasName = item.name.indexOf(el) > -1;
        const hasId = item.studentId.indexOf(el) > -1;
        return hasEmail || hasName || hasId
      });
    });
  }

  const [fireData, setFireData] = useState({
    departments:[],
    schools:[],
    classifications:[],
    courses:[],
    tags:[]
  })

  const reloadFData = () => {
    setLoadingUsers(true)
    const cu = currentUser();
    getLineUsers(cu).then(data => {
      setStudents(data);
      setLoadingUsers(false)
    });

    const fData = {
      departments:[],
      schools:[],
      classifications:[],
      courses:[],
      tags:[]
    }

   getDepartments().then(data=>{
      fData.departments.push(...data)
      getSchools().then(data=>{
        fData.schools.push(...data)
        getClassifications().then(data=>{
          fData.classifications.push(...data)
          getTags().then(data=>{
            fData.tags.push(...data)
            getCourses().then(data=>{
              fData.courses.push(...data)
              setFireData(fData);
            })
          })
        });
      });
    });
  };

  const handleDeleteUser = async (user) =>{
    setLoadingUsers(true)
    await deleteLineUser(user).then(()=>{
      reloadFData();
    });
  }

  const handleDeleteMultiple = async (userList)=>{
    setLoadingUsers(true)
    userList.map(async user => {
      await deleteLineUser(user).then(()=>{
        reloadFData();
      });
    })
  }

  useEffect(() => {
    const cu = currentUser();
    getLineUsers(cu).then(data => {
      setStudents(data);
      setLoadingUsers(false)
    });

    const fData = {...fireData};

    getDepartments().then(data=>{
      fData.departments.push(...data)
      getSchools().then(data=>{
        fData.schools.push(...data)
        getClassifications().then(data=>{
          fData.classifications.push(...data)
          getTags().then(data=>{
            fData.tags.push(...data)
            getCourses().then(data=>{
              fData.courses.push(...data)
              setFireData(fData);
            })
          })
        });
      });
    });
  }, []);

  useEffect(() => {
    setFilteredStudents(find(students, search));
  },[search])

  return (
    <App>
      <TitleWithSearch
        title="受講生管理"
        placeholder={'ユーザー名/メールアドレスで検索'}
        updateHandler={setSearch}
        icon={['fas', 'users']}
      />
      <StudentList users={(search.length > 0) ? filteredStudents : students} loading={loadingUsers} isSearch={(search.length > 0) ? true : false} fireData={fireData} handleDeleteUser={handleDeleteUser} handleDeleteMultiple={handleDeleteMultiple} />
    </App>
  );
};

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Students);
