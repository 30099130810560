import React, {useEffect, useState} from 'react'
import Header from './Header'

import "./App.scoped.scss";

import { signOut, currentUser, getUser } from '../../api/users'

const App = ({children }) => {
  const { uid } = currentUser();
  const [user, setUser] = useState({role:'teacher'});

  useEffect(() => {
    getUser(uid).then((data) => {
      setUser(data);
    });
  }, [])

  return (
  <div id="app">
    <Header user={user} signOut={signOut}/>
    <main className="app-wrapper" style={{maxWidth:1300,margin:"auto"}}>
      {children}
    </main>
  </div>
)}

export default App