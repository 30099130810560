import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { withFirebase } from '../components/Firebase';

import "./scss/PasswordReset.scoped.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const PasswordReset = (props) => {
  let query = useQuery();
  const [passCheck, setPassCheck] = useState('');
  const [pass, setPass] = useState('');
  const [isError, setIsError] = useState(false);

  const [finished, setFinished] = useState(false);

  const submit = () => {
    let code = query.get('oobCode');
    props.firebase.confirmPasswordReset(code, pass).then(function () {
      setFinished(true);
    })
      .catch(function () {
        setIsError(true);
      })
  }

  useEffect(()=>{
    let code = query.get('oobCode');
    let apiKey = query.get('apiKey');

    if(!code && !apiKey) {
      window.location.replace('https://f-log.app/')
    }

  },[])

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <figure className="login-logo">
        <img src="/flog-logo.png" alt="" />
          <figcaption>
            パスワード再設定
          </figcaption>
        </figure>
        <div className="login-form">
          {
            (isError) ? (<p className="form-error">パスワードリセットできませんでした。</p>) : null
          }

          {!finished ? (
            <>
              <div className="form-item">
                <label>パスワード</label>
                <div className="input-wrapper">
                  <input
                    type={'password'}
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    placeholder={'新しいパスワード'}
                    onClick={
                      () => setIsError(false)
                    }
                  />
                </div>
              </div>
              <div className="form-item">
                <label>パスワード(確認)</label>
                <div className="input-wrapper">
                  <input
                    type={'password'}
                    value={passCheck}
                    onChange={(e) => setPassCheck(e.target.value)}
                    onClick={
                      () => setIsError(false)
                    }
                    placeholder={'パスワードの再入力'}
                  />
                </div>
              </div>
              <div className="form-submit">
                <button
                  className={!passCheck || !pass || passCheck !== pass ? 'grad-button disabled' : 'grad-button'}
                  onClick={()=>submit()}
                >
                  リセットする
        </button>
              </div>
            </>
          ) : (
              <div className="login-form">
                <p className="sent">パスワード再設定できました。</p>
                <p className="form-action">
                  <Link to="/console" className="grad-button">ログインに戻る</Link>
                </p>
              </div>
            )}

        </div>
      </div>
    </div>
  );
};

export default withFirebase(PasswordReset);