import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../components/Firebase';

import "./scss/Home.scoped.scss";

const HomePage = (props) => {
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [isError, setIsError] = useState(false);

  const handleLogin = async () => {
    try {
      await props.firebase.doSignInWithEmailAndPassword(email, pass);
      window.location.href = '/console/students';
    } catch (err) {
      setIsError(true);
    }

  }
  return (
    <div className="login-wrapper">
      <div className="login-container">
        <figure className="login-logo">
          <img src="/flog-logo.png" alt="" />
          <figcaption>ログイン(講師)</figcaption>
        </figure>
        <div className="login-form">
          {
            (isError) ? (<p className="form-error">パスワードまたメールアドレスは正しくありません。</p>) : null
          }

          <div className="form-item">
            <label>メールアドレス</label>
            <div className="input-wrapper">
              <input
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder={'name@example.com'}
                onClick={
                  () => setIsError(false)
                }
              />
            </div>
          </div>
          <div className="form-item">
            <label>パスワード</label>
            <div className="input-wrapper">
              <input
                type={'password'}
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                onClick={
                  () => setIsError(false)
                }
                placeholder={'パスワードを入力してください'}
              />
            </div>
          </div>
          <div className="form-submit">
            <button
              className={!email || !pass ? 'grad-button disabled' : 'grad-button'}
              onClick={handleLogin}
            >
              ログイン
        </button>
          </div>
          <p className="form-action">
            <Link to="/console/password-forgot">パスワードをお忘れですか？</Link>
          </p>
        </div>
      </div>
    </div>

  );
};
export default withFirebase(HomePage);
