//Common
export const HOME = '/console';
export const TEACHERS = '/console/teachers';
export const STUDENTS = '/console/students';
export const MY_PAGE = '/console/my-page';
export const MASTERS = '/console/masters';

//LINE
export const LINE_HOME = '/lineapp';
export const LINE_CALENDAR = '/lineapp-calendar';
export const LINE_GANT = '/lineapp-gant';
export const LINE_REGISTER = '/lineapp-register';
export const LINE_BLANK = '/blank';

//OTHER
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/console/password-forgot';
export const PASSWORD_RESET = '/console/password-reset';