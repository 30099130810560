import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';

import { getUser, currentUser } from './users';

if (!firebase.apps.length) {
  const CLIENT_CONFIG = {
    apiKey: 'AIzaSyDeV1gVgtuJzbKfdmdIMXDu2Fy6XUmxTq4',
    authDomain: 'future-log-67799.firebaseapp.com',
    projectId: 'future-log-67799',
    storageBucket: 'future-log-67799.appspot.com',
    messagingSenderId: '337593555009',
    appId: '1:337593555009:web:6bc12bf96d00005cf48ec0',
    measurementId: 'G-DSJRD2QR53',
  };

  firebase.initializeApp(CLIENT_CONFIG);
}

const db = firebase.firestore();
const col = 'lineUsers';
const auth = firebase.auth();

export const getLineUsers = async (cur) => {

  const userData = await getUser(cur.uid).then(
    (data) => {
      return data;
    },
  );

  if (userData.role === 'teacher') {
    const lineUserData = await db
      .collection(col)
      .where('school', 'in', userData.school)
      .get()
      .then((snap) => {
        const data = [];
        snap.forEach((doc) => {
          data.push(doc.data());
        });
        return data;
      })
      .catch((err) => {
        return err;
      });
    return lineUserData.filter((item) => {
      return userData.department.includes(item.department);
    });
  }


  const lineUserData = await db
    .collection(col)
    .get()
    .then((snap) => {
      const data = [];
      snap.forEach((doc) => {
        data.push(doc.data());
      });
      return data;
    })
    .catch((err) => {
      return err;
    });

  return lineUserData;
};

export const getLineUser = async (id) => {
  const data = await db
    .collection(col)
    .doc(id)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        return false;
      }
      return doc.data();
    })
    .catch((err) => {
      return err;
    });
  return data;
};

export const createLineUser = async (lineUser) => {
  const lineUserRef = db.collection(col).doc(lineUser.lineId);
  const lineUserObj = {
    id: lineUser.lineId,
    lineId: lineUser.lineId,
    studentId: (lineUser.studentId) ? lineUser.studentId : '',
    name: (lineUser.name) ? lineUser.name : '',
    avatar: (lineUser.avatar) ? lineUser.avatar : '',
    displayName: lineUser.displayName,
    schoolYear: Number(lineUser.schoolYear),
    school: lineUser.school,
    department: lineUser.department,
    classifications: lineUser.classifications
      ? [lineUser.classifications]
      : [],
    courses: lineUser.courses ? [lineUser.courses] : [],
    tags: [],
    memo: '',
    lineSetting: ["1", "2", "10"],
    showMover: true,
    created: new Date(),
    updated: new Date(),
  };
  await lineUserRef.set(lineUserObj);
  return lineUserObj;
};

export const updateLineUser = async (lineUser) => {
  const lineUserRef = db.collection(col).doc(lineUser.id);
  const lineUserObj = { ...lineUser };
  lineUserObj.updated = new Date();
  await lineUserRef.set(lineUserObj, { merge: true });
  return lineUserObj;
};

export const deleteLineUser = async (id) => {
  await db
    .collection(col)
    .doc(id)
    .delete()
    .catch((err) => {
      return err;
    });
  return true;
};


export const addYear = async () => {
  const allUsers = await db
    .collection(col)
    .get()
    .then((snap) => {
      const data = [];
      snap.forEach((doc) => {
        data.push(doc.data());
      });
      return data;
    })
    .catch((err) => {
      return err;
    });

    allUsers.map((item)=>{
      const newUserData = {...item}
      if(parseInt(newUserData.schoolYear) !== 1 && parseInt(newUserData.schoolYear) !== 15){
        newUserData.schoolYear = newUserData.schoolYear - 1;
        updateLineUser(newUserData);
      }
    })
}
