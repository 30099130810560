import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { searchYearlyEvents } from '../../../api/yearlyEvents';

const moment = extendMoment(Moment);
import { cleanTaskData } from '../../../api/weeklyEvents';
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

import EventModalActions from './EventModalActions';
import EventModalTabsHeader from './EventModalTabsHeader';
import NewEventPanel from './NewEventPanel';
import FullProgressPanel from './FullProgressPanel';
import EditProgressPanel from './EditProgressPanel';
import SlideTransition from '../SlideTransition';

const CreateEventModal = (props) => {
  const classes = useStyles();
  const {
    open,
    selected,
    close,
    edit,
    save,
    tabIndex,
    tasksS,
    events,
    dateMarq,
    selDate,
    tasks,
    deleteTask,
  } = props;

  const [tabVal, setTabVal] = useState(tabIndex ? tabIndex : 0);
  const [eventStart, setEventStart] = useState(
    dateMarq ? moment(dateMarq[0]) : moment(),
  );
  const [eventEnd, setEventEnd] = useState(
    dateMarq ? moment(dateMarq[1]) : moment().add(1, 'hour'),
  );
  const [yearlyEvents, setYearlyEvents] = useState([]);
  const [selectedTask, setSelectedTask] = useState(
    selected?.yearTask ? selected.yearTask.id : '',
  );
  const [progress, setProgress] = useState(0);

  const [overflowError, setOverflowError] = useState(false);

  const isOverflowing = () => {
    const timeDiff = moment(eventEnd).diff(
      eventStart,
      'minutes',
      true,
    );
    let overflowMinutes =
      progress > timeDiff ? progress - timeDiff : 0;
    const overflow = moment(eventEnd)
      .clone()
      .add(overflowMinutes, 'minutes');
    const isOverflow = overflow.isSame(moment(eventStart), 'day');

    if (!isOverflow) return true;

    return false;
  };

  useEffect(() => {
    setOverflowError(isOverflowing());
  }, [progress, eventEnd]);

  const [filteredTaskEvents, setFilteredTaskEvents] = useState([]);

  useEffect(() => {
    const rows = [];

    const calcTotalScheduled = (event) => {
      if(!event.yearTask) return 0;
      let totalSched = 0;
      const yTask = cleanTaskData(event.yearTask);
      const gantEventWeeks = event.yearTask
        ? Math.ceil(
            moment(yTask.end_date).diff(yTask.start_date, 'weeks', true),
          )
        : 1;
      if (yTask?.type === 'routine') {
        totalSched = Math.ceil(((yTask.hpd * yTask.dpw) / 60) * gantEventWeeks);
      } else {
        totalSched = Math.ceil(
          (yTask.totalHours * 60) / gantEventWeeks,
        );
      }
      return totalSched;
    };

    events?.current?.mon?.events?.map((event) => {
      const hasEvent = rows.findIndex(
        (x) => x.id === event?.yearTask?.id,
      );
      const yt = cleanTaskData(event.yearTask);
      const gantEventWeeks = event.yearTask
        ? Math.ceil(
            moment(yt.end_date).diff(yt.start_date, 'weeks', true),
          )
        : 1;
      if (hasEvent !== -1) {
        rows[hasEvent].totalProgress += event.progress;
        rows[hasEvent].totalMinutes += Math.ceil(
          moment(event.end).diff(
            moment(event.start),
            'minutes',
            true,
          ),
        );
      } else {
        rows.push({
          id: event?.yearTask?.id || 0,
          title: event.title,
          totalProgress: event.progress,
          totalMinutes: Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          ),
          totalScheduled: calcTotalScheduled(event),
        });
      }
    });
    events?.current?.tue?.events?.map((event) => {
      const hasEvent = rows.findIndex(
        (x) => x.id === event?.yearTask?.id,
      );
      const yt = cleanTaskData(event.yearTask);
      const gantEventWeeks = event.yearTask
        ? Math.ceil(
            moment(yt.end_date).diff(yt.start_date, 'weeks', true),
          )
        : 1;
      if (hasEvent !== -1) {
        rows[hasEvent].totalProgress += event.progress;
        rows[hasEvent].totalMinutes += Math.ceil(
          moment(event.end).diff(
            moment(event.start),
            'minutes',
            true,
          ),
        );
      } else {
        rows.push({
          id: event?.yearTask?.id || 0,
          title: event.title,
          totalProgress: event.progress,
          totalMinutes: Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          ),
          totalScheduled: calcTotalScheduled(event),
        });
      }
    });
    events?.current?.wed?.events?.map((event) => {
      const hasEvent = rows.findIndex(
        (x) => x.id === event?.yearTask?.id,
      );
      const yt = cleanTaskData(event.yearTask);
      const gantEventWeeks = event.yearTask
        ? Math.ceil(
            moment(yt.end_date).diff(yt.start_date, 'weeks', true),
          )
        : 1;
      if (hasEvent !== -1) {
        rows[hasEvent].totalProgress += event.progress;
        rows[hasEvent].totalMinutes += Math.ceil(
          moment(event.end).diff(
            moment(event.start),
            'minutes',
            true,
          ),
        );
      } else {
        rows.push({
          id: event?.yearTask?.id || 0,
          title: event.title,
          totalProgress: event.progress,
          totalMinutes: Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          ),
          totalScheduled: calcTotalScheduled(event),
        });
      }
    });
    events?.current?.thur?.events?.map((event) => {
      const hasEvent = rows.findIndex(
        (x) => x.id === event?.yearTask?.id,
      );
      const yt = cleanTaskData(event.yearTask);
      const gantEventWeeks = event.yearTask
        ? Math.ceil(
            moment(yt.end_date).diff(yt.start_date, 'weeks', true),
          )
        : 1;
      if (hasEvent !== -1) {
        rows[hasEvent].totalProgress += event.progress;
        rows[hasEvent].totalMinutes += Math.ceil(
          moment(event.end).diff(
            moment(event.start),
            'minutes',
            true,
          ),
        );
      } else {
        rows.push({
          id: event?.yearTask?.id || 0,
          title: event.title,
          totalProgress: event.progress,
          totalMinutes: Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          ),
          totalScheduled: calcTotalScheduled(event),
        });
      }
    });
    events?.current?.fri?.events?.map((event) => {
      const hasEvent = rows.findIndex(
        (x) => x.id === event?.yearTask?.id,
      );
      const yt = cleanTaskData(event.yearTask);
      const gantEventWeeks = event.yearTask
        ? Math.ceil(
            moment(yt.end_date).diff(yt.start_date, 'weeks', true),
          )
        : 1;
      if (hasEvent !== -1) {
        rows[hasEvent].totalProgress += event.progress;
        rows[hasEvent].totalMinutes += Math.ceil(
          moment(event.end).diff(
            moment(event.start),
            'minutes',
            true,
          ),
        );
      } else {
        rows.push({
          id: event?.yearTask?.id || 0,
          title: event.title,
          totalProgress: event.progress,
          totalMinutes: Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          ),
          totalScheduled: calcTotalScheduled(event),
        });
      }
    });
    events?.current?.sat?.events?.map((event) => {
      const hasEvent = rows.findIndex(
        (x) => x.id === event?.yearTask?.id,
      );
      const yt = cleanTaskData(event.yearTask);
      const gantEventWeeks = event.yearTask
        ? Math.ceil(
            moment(yt.end_date).diff(yt.start_date, 'weeks', true),
          )
        : 1;
      if (hasEvent !== -1) {
        rows[hasEvent].totalProgress += event.progress;
        rows[hasEvent].totalMinutes += Math.ceil(
          moment(event.end).diff(
            moment(event.start),
            'minutes',
            true,
          ),
        );
      } else {
        rows.push({
          id: event?.yearTask?.id || 0,
          title: event.title,
          totalProgress: event.progress,
          totalMinutes: Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          ),
          totalScheduled: calcTotalScheduled(event),
        });
      }
    });
    events?.current?.sun?.events?.map((event) => {
      const hasEvent = rows.findIndex(
        (x) => x.id === event?.yearTask?.id,
      );
      const yt = cleanTaskData(event.yearTask);
      const gantEventWeeks = event.yearTask
        ? Math.ceil(
            moment(yt.end_date).diff(yt.start_date, 'weeks', true),
          )
        : 1;
      if (hasEvent !== -1) {
        rows[hasEvent].totalProgress += event.progress;
        rows[hasEvent].totalMinutes += Math.ceil(
          moment(event.end).diff(
            moment(event.start),
            'minutes',
            true,
          ),
        );
      } else {
        rows.push({
          id: event?.yearTask?.id || 0,
          title: event.title,
          totalProgress: event.progress,
          totalMinutes: Math.ceil(
            moment(event.end).diff(
              moment(event.start),
              'minutes',
              true,
            ),
          ),
          totalScheduled: calcTotalScheduled(event),
        });
      }
    });

    setFilteredTaskEvents(
      rows.filter((item) => {
        if (!selectedTask) {
          return false;
        }
        return selectedTask.id === item.id;
      }),
    );
  }, [selectedTask]);

  useEffect(() => setTabVal(tabIndex), [tabIndex]);

  useEffect(() => {
    if (!selected) return;
    setEventStart(moment(selected.start));
    setEventEnd(moment(selected.end));
    setSelectedTask(selected?.yearTask?.id || '');
    setProgress(selected.progress);
  }, [selected]);

  useEffect(() => {
    if (!dateMarq) return;
    setEventStart(moment(dateMarq[0]));
    setEventEnd(moment(dateMarq[1]));
    setSelectedTask('');
    setProgress(0);
  }, [dateMarq]);

  const onTabChange = (event, newValue) => {
    setTabVal(newValue);
  };

  const onStartDateChange = (date) => {
    setEventStart(date);
    setEventEnd(moment(date).add(1, 'hour').toDate());
  };

  const onEndDateChange = (date) => {
    setEventEnd(date);
  };

  const onProgressChange = (e) => {
    setProgress(parseInt(e.target.value));
  };

  const onTaskChange = (e) => {
    setSelectedTask(e.target.value);
  };

  const handleAdd = () => {
    let taskId =
      typeof selectedTask === 'string' ||
      selectedTask instanceof String
        ? selectedTask
        : selectedTask.id;
    const yearTaskSel = tasksS.filter((item) => {
      return item.id === taskId;
    });
   
    props.save({
      title: yearTaskSel[0].text,
      start: moment(eventStart).toDate() || moment().toDate(),
      end: moment(eventEnd).toDate() || moment().toDate(),
      progress: 0,
      yearTask: yearTaskSel[0],
    });
    props.close();
    setTabVal(0);
  };

  const handleEdit = () => {
    let taskId =
      typeof selectedTask === 'string' ||
      selectedTask instanceof String
        ? selectedTask
        : selectedTask.id;
    const yearTaskSel = tasksS.filter((item) => {
      return item.id === taskId;
    });
    props.edit({
      id: selected.id,
      title: yearTaskSel[0].text,
      start: moment(eventStart).toDate() || moment().toDate(),
      end: moment(eventEnd).toDate() || moment().toDate(),
      progress: progress,
      yearTask: yearTaskSel[0],
    });
    props.close();
  };

  const handleAddprogress = () => {
    let taskId =
      typeof selectedTask === 'string' ||
      selectedTask instanceof String
        ? selectedTask
        : selectedTask.id;
    const yearTaskSel = tasksS.filter((item) => {
      return item.id === taskId;
    });
    props.save({
      title: yearTaskSel[0].text,
      start: moment(eventStart).toDate() || moment().toDate(),
      end: moment(eventEnd).toDate() || moment().toDate(),
      progress: Math.ceil(
        Number(
          moment(eventEnd).diff(moment(eventStart), 'minutes', true),
        ),
      ),
      yearTask: yearTaskSel[0],
    });
    props.close();
  };

  const handleSubmit = () => {
    {
      if (selected) {
        handleEdit();
      } else {
        if (tabVal === 1) {
          handleAddprogress();
        } else {
          handleAdd();
        }
      }

      setTabVal(0);
    }
  };

  const onClose = () => {
    setTabVal(0);
    setEventStart(moment());
    setEventEnd(moment().add(1, 'hour'));
    setYearlyEvents([]);
    setSelectedTask('');
    setProgress(0);
    close();
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      TransitionComponent={SlideTransition}
    >
      <EventModalTabsHeader
        value={tabVal}
        onChange={onTabChange}
        selected={selected}
        dateMarq={dateMarq}
      />
      <DialogContent>
        <NewEventPanel
          tabVal={tabVal}
          tasksS={tasksS || []}
          tasks={tasks || []}
          events={events || []}
          selectedTask={selectedTask}
          onTaskChange={onTaskChange}
          eventStart={eventStart}
          eventEnd={eventEnd}
          onEventStartChange={onStartDateChange}
          onEventEndChange={onEndDateChange}
          filteredTaskEvents={filteredTaskEvents}
          selDate={selDate}
          selected={selected}
        />
        {selected ? (
          <EditProgressPanel
            eventStart={eventStart}
            eventEnd={eventEnd}
            tabVal={tabVal}
            progress={progress}
            overflowError={overflowError}
            onProgressChange={onProgressChange}
            tasksS={tasksS || []}
            events={events || []}
            filteredTaskEvents={filteredTaskEvents}
            selected={selected}
            selDate={selDate}
            tasks={tasks || []}
            selectedTask={selectedTask}
          />
        ) : (
          <FullProgressPanel
            tabVal={tabVal}
            tasksS={tasksS || []}
            tasks={tasks || []}
            events={events || []}
            selectedTask={selectedTask}
            onTaskChange={onTaskChange}
            eventStart={eventStart}
            eventEnd={eventEnd}
            onEventStartChange={onStartDateChange}
            onEventEndChange={onEndDateChange}
            filteredTaskEvents={filteredTaskEvents}
            selDate={selDate}
            selected={selected}
          />
        )}
      </DialogContent>
      <EventModalActions
        cancel={() => {
          onClose();
        }}
        submit={handleSubmit}
        tabVal={tabVal}
        disabled={
          !selectedTask || eventEnd <= eventStart || overflowError
        }
        onDelete={() => {
          onClose();
        }}
        deleteTask={deleteTask}
        hasDelete={selected && !selected?.yearTask ? true : false}
        filteredTaskEvents={filteredTaskEvents}
      />
    </Dialog>
  );
};

export default CreateEventModal;
