import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';

import axios from 'axios';

axios.defaults.baseURL = ' https://asia-northeast1-future-log-67799.cloudfunctions.net/webApi/api/v1';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

if (!firebase.apps.length) {
    const CLIENT_CONFIG = {
        apiKey: "AIzaSyDeV1gVgtuJzbKfdmdIMXDu2Fy6XUmxTq4",
        authDomain: "future-log-67799.firebaseapp.com",
        projectId: "future-log-67799",
        storageBucket: "future-log-67799.appspot.com",
        messagingSenderId: "337593555009",
        appId: "1:337593555009:web:6bc12bf96d00005cf48ec0",
        measurementId: "G-DSJRD2QR53"
    };

    firebase.initializeApp(CLIENT_CONFIG);
}

const db = firebase.firestore();
const col = 'line';
const auth = firebase.auth();

export const sendMessage = async (to, message) => {
    return axios.post('lineUsers/sendMessage', {
        to:to, message:message
      })
        .then((response) => {
            console.log(response);
        })
        .catch(err => {
          console.error(err)
        });
};