import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Checkbox } from '@material-ui/core';

import NewSchoolDialogue from './NewSchoolDialogue';
import DeleteSchoolDialogue from './DeleteSchoolDialogue';

import './menues.scss';

export default props => {
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [newLocDialogueOpen, setNewLocDialogueOpen] = React.useState(
    false,
  );
  const [isEditLoc, setIsEditLoc] = React.useState(false);
  const [editingLoc, setEditingLoc] = React.useState(null);
  const [checked, setChecked] = React.useState([...props.sels]);

  const [deleteAlertOpen, setDeleteAlertOpen] = React.useState(false);

  const handleListItemClick = (event, index) => {
    props.schoolSelected(props.locs[index].id);
    props.handleClose();
  };

  useEffect(() =>{
    setChecked(props.sels)
  },[props.sels])

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    props.schoolSelected(value);
    setChecked(newChecked);
  };

  const resetLoc = () => {
    setNewLocDialogueOpen(false);
    setIsEditLoc(false);
    setEditingLoc(null);
  };

  const handleLocEditClick = index => {
    setNewLocDialogueOpen(true);
    setIsEditLoc(true);
    setEditingLoc(props.locs[index]);
  };

  const handleLocDeleteClick = index => {
    setDeleteAlertOpen(true);
    setEditingLoc(props.locs[index]);
  };

  const handleDeleteSubmit = id => {
    props.deleteLocSubmit(id);
    setEditingLoc(null);
  };

  const editLocSubmit = (id, name) => {
    props.editLocSubmit(id, name);
    resetLoc();
  };

  const newLocSubmit = name => {
    props.newLocSubmit(name);
    resetLoc();
  };

  return (
    <>
      <Menu
        anchorEl={props.anchorEl}
        open={Boolean(props.anchorEl)}
        onClose={props.handleClose}
        variant="menu"
        classes={{
          paper: 'pop-menu',
          list: 'pop-menu-list',
        }}
      >
        {props.locs.map((loc, index) => (
          <ListItem
            key={loc.id}
            selected={selectedIndex === index}
            button
            onClick={handleToggle(loc.id)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(loc.id) !== -1}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={loc.title} />
            <ListItemSecondaryAction>
              <button
                className="edit"
                onClick={() => handleLocEditClick(index)}
              >
                編集
              </button>
              <button
                className="delete"
                onClick={() => handleLocDeleteClick(index)}
              >
                削除
              </button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        <ListItem button onClick={() => setNewLocDialogueOpen(true)}>
          <AddCircleIcon />
          　新規追加
        </ListItem>
      </Menu>
      <NewSchoolDialogue
        isEdit={isEditLoc}
        editingLoc={editingLoc}
        show={newLocDialogueOpen}
        close={() => resetLoc()}
        editLocSubmit={editLocSubmit}
        newLocSubmit={newLocSubmit}
      />
      <DeleteSchoolDialogue
        open={deleteAlertOpen}
        close={() => setDeleteAlertOpen(false)}
        deleteLocSubmit={handleDeleteSubmit}
        editingLoc={editingLoc}
      />
    </>
  );
};
