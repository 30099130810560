import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import './DeleteStudentDialogue.scoped.scss';

export default props => {
  const handleSubmit = () => {
    props.handleDeleteUser(props.id[0]).then(()=>{
      props.close();
    });
  };

  const handleSubmitMulti = () => {
    props.handleDeleteMultiple(props.id).then(()=>{
      props.close();
    })
  }

  return (
    <>
      <Dialog open={props.open} onClose={props.close}>
        <DialogTitle id="alert-dialog-title">
          このユーザーを削除してよろしいですか？
        </DialogTitle>
        <DialogActions>
          <Button onClick={props.close} color="primary">
            キャンセル
          </Button>
          <Button onClick={()=> {props.id.length > 1 ? handleSubmitMulti() : handleSubmit()}} color="secondary" autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
