import * as React from 'react';
import {useState, useEffect} from 'react';

import {
  getWeeklyInfo,
  updateWeeklyInfo,
  createWeeklyInfo,
} from '../../../api/weeklyinfo';

import {
  useParams
} from "react-router-dom";

import moment from 'moment';
import 'moment/locale/ja';

import FurikaeruModal from '../FurikaeruModal/FurikaeruModal';
import './TeacherToolbar.scoped.scss';
export interface ICustomTooolbarProps {
  view: string;
  views: string[];
  label: any;
  localizer: any;
  onNavigate: (action: any) => void;
  onView: (view: any) => void;
  onViewChange: (view: any) => void;
  messages: any;
}

export const navigateContants = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
};

export const views = {
  MONTH: 'month',
  WEEK: 'week',
  WORK_WEEK: 'work_week',
  DAY: 'day',
  AGENDA: 'agenda',
};

function weekOfMonth(m) {
  var day = m.day();
  var nthOfMoth = Math.ceil(m.date() / 7);
  return nthOfMoth;
}


const CustomToolbar: React.SFC<ICustomTooolbarProps> = props => {

  let { id } = useParams();

  
  const [weeklyInfo, setWeeklyInfo] = React.useState(null);
  const [weeklyInfoModal, setWeeklyInfoModal] = React.useState(false);

  const fetchWeeklyInfo = async () => {
    await getWeeklyInfo(
      id,
      props.date
    ).then((data) => {
      setWeeklyInfo(data);
    });
  };

  React.useEffect(() => {
    fetchWeeklyInfo();
  }, [props.date]);

  function navigate(action) {
    props.onNavigate(action);
  }

  function viewItem(view) {
    props.onViewChange(view);
  }

  function viewNamesGroup(messages) {
    const viewNames = props.views;
    const view = props.view;

    if (viewNames.length > 1) {
      return viewNames.map(name => (
        <button
          type="button"
          key={name}
          className={css({ 'rbc-active': view === name })}
          onClick={viewItem.bind(null, name)}
        >
          {messages[name]}
        </button>
      ));
    }
  }

  return (
    <>
      <div className="rbc-toolbar" style={{ padding: '20px' }}>
        <span className="rbc-btn-group">
          <button
            type="button"
            onClick={navigate.bind(null, navigateContants.TODAY)}
          >
            今週
          </button>
          <button
            type="button"
            onClick={navigate.bind(null, navigateContants.PREVIOUS)}
          >
            前
          </button>
          <button
            type="button"
            onClick={navigate.bind(null, navigateContants.NEXT)}
          >
            次
          </button>
        </span>

        <span className="rbc-toolbar-label">{props.label}</span>

        <span className="rbc-btn-group">
          {viewNamesGroup(props.messages)}
        </span>
      </div>
      <div className="teacherInput">
        <div
        className="teacherinput-wrapper"
        >
          {weeklyInfo?.message ? weeklyInfo.message : '抱負はまだありません。'}<button class="button furikaeri" onClick={()=>setWeeklyInfoModal(true)}>振り返り</button>
        </div>
        
      </div>
      <FurikaeruModal open={weeklyInfoModal} close={()=>setWeeklyInfoModal(false)} date={moment(props.date)} weeklyInfo={weeklyInfo} teacher={true}/>
    </>
  );
};

export default CustomToolbar;
