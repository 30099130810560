import React from 'react';

//material
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

const EventModalActions = (props) => {

    const {cancel, submit, disabled, deleteTask, onDelete, hasDelete} = props;

    return (
        <DialogActions>
            <Button onClick={cancel} color="primary">
                キャンセル
          </Button>
          {hasDelete ? <Button onClick={deleteTask} color="secondary">
                削除
          </Button> : null}
            <Button
                disabled={disabled}
                onClick={submit}
                color="primary"
            >
                保存
          </Button>
        </DialogActions>
    );

}

export default EventModalActions;