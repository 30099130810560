import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';

if (!firebase.apps.length) {
  const CLIENT_CONFIG = {
    apiKey: "AIzaSyDeV1gVgtuJzbKfdmdIMXDu2Fy6XUmxTq4",
    authDomain: "future-log-67799.firebaseapp.com",
    projectId: "future-log-67799",
    storageBucket: "future-log-67799.appspot.com",
    messagingSenderId: "337593555009",
    appId: "1:337593555009:web:6bc12bf96d00005cf48ec0",
    measurementId: "G-DSJRD2QR53"
  };


  firebase.initializeApp(CLIENT_CONFIG);
}

const db = firebase.firestore();
const col = 'schools';
const auth = firebase.auth();

export const getSchools = async () => {
  const schoolData = await db
    .collection(col)
    .get()
    .then(snap => {
      const data = [];
      snap.forEach(doc => {
        data.push(doc.data());
      });
      return data;
    })
    .catch(err => {
      return err;
    });
  return schoolData;
};

export const getSchool = async id => {
  const data = await db
    .collection(col)
    .doc(id)
    .get()
    .then(doc => {
      if(!doc.exists){
        return false
      }
      return doc.data()
    }).catch(err => {
      return err
    })
  return data;
};

export const createSchool = async school => {
  const schoolRef = db.collection(col).doc();
  const schoolObj = {
    id : schoolRef.id,
    title : school.title,
    created : new Date(),
    updated : new Date()
  }
  await schoolRef.set(schoolObj);
  return schoolObj;
};

export const updateSchool = async school => {
  const schoolRef = db.collection(col).doc(school.id);
  const schoolObj = {...school};
  schoolObj.updated = new Date();
  await schoolRef.set(schoolObj, {merge : true});
  return schoolObj;
};

export const deleteSchool = async id => {
  await db
    .collection(col)
    .doc(id)
    .delete().catch(err => {
      return err
    });
  return true;
};
