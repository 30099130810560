import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import './LineBotSettings.scoped.scss';
import { useLiff } from 'react-liff';
import { getLineUser, updateLineUser } from '../../../api/lineUsers';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function LineBotSettings(props) {

    const classes = useStyles();

    const [ud, setUd] = useState({});

    const [setting1, setSetting1] = useState("0");
    const [setting2, setSetting2] = useState("0");
    const [setting3, setSetting3] = useState('');

    const fetchUser = async () => {
        getLineUser(props.user).then(data => {
            setUd(data);
            setSetting1(data.lineSetting ? data.lineSetting[0] : '0')
            setSetting2(data.lineSetting ? data.lineSetting[1] : '0')
            setSetting3(data.lineSetting ? data.lineSetting[2] : '')
        })
    }
    useEffect(() => {
        fetchUser();
    }, [])

    const submit = () => {
        const oldUser = { ...ud };
        oldUser.lineSetting = [setting1, setting2, setting3];
        updateLineUser(oldUser).then(() => {
            props.close();
        });

    }

    return (
        <>
            <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            通知設定
                        </Typography>
                        <IconButton edge="start" color="inherit" onClick={props.close} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className="gant-modal-body">
                    <div className="new-user-form">
                        <div className="new-user-form-inner">
                            <div className="form">
                                <div className="input-item" style={{ marginBottom: 30 }}>
                                    <label>直近１週間を振り返ることを通知するタイミング</label>
                                    <select onChange={(e) => { setSetting1(e.target.value) }}>
                                        <option value="1" selected={(setting1 === "1")}>土曜日の晩 18:00</option>
                                        <option value="2" selected={(setting1 === "2")}>日曜日の晩 18:00</option>
                                        <option value="3" selected={(setting1 === "3")}>日曜日の晩 21:00</option>
                                        <option value="4" selected={(setting1 === "4")}>月曜日の朝 09:00</option>
                                    </select>
                                </div>
                                <div className="input-item">
                                    <label>次の１週間の計画を立てること通知するタイミング</label>
                                    <select onChange={(e) => { setSetting2(e.target.value) }}>
                                        <option value="1" selected={(setting2 === "1")}>土曜日の晩 18:00</option>
                                        <option value="2" selected={(setting2 === "2")}>日曜日の晩 18:00</option>
                                        <option value="3" selected={(setting2 === "3")}>日曜日の晩 21:00</option>
                                        <option value="4" selected={(setting2 === "4")}>月曜日の朝 09:00</option>
                                    </select>
                                </div>
                                <div className="input-item">
                                    <label>計画割当の時間通知するタイミング</label>
                                    <div className="input-wrap">
                                        <input type="number" value={setting3} onChange={(e) => { setSetting3(e.target.value) }} /> <span>分前</span>
                                    </div>
                                </div>
                                <div className="submit">
                                    <button
                                        className='grad-button'
                                        onClick={() => submit()}
                                    >
                                        保存
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
}