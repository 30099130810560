import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import DeleteTeacherDialogue from './DeleteTeacherDialogue';

import SchoolMenu from './SchoolMenu';
import DepartmentMenu from './DepartmentMenu';

import './TeacherListItem.scoped.scss';

import {
  createDepartment,
  deleteDepartment,
  updateDepartment,
} from '../../api/departments';

import {
  createSchool,
  deleteSchool,
  updateSchool,
} from '../../api/schools';

export default props => {
  const { user, fireData } = props;
  const [isEdit, setIsEdit] = React.useState(false);

  const [userDepartment, setUserDepartment] = React.useState(
    user.department,
  );
  const [userSchool, setUserSchool] = React.useState(user.school);

  const [userSei, setUserSei] = React.useState(user.nameSei);
  const [userMei, setUserMei] = React.useState(user.nameMei);

  const [anchorElDepartment, setAnchorElDepartment] = React.useState(
    null,
  );
  const [anchorElSchool, setAnchorElSchool] = React.useState(null);

  const [deleteDia, setDeleteDia] = React.useState(false);

  const handleDelete = () => {
    setDeleteDia(true);
  };

  React.useEffect(() => {
    setUserDepartment(user.department);
    setUserSchool(user.school);
  }, [props.user]);

  const getSchoolFromId = id => {
    const team = fireData.schools.filter(tm => tm.id === id);
    if (team.length) {
      return team[0].title;
    }
    return null;
  };

  const getDepartmentFromId = id => {
    const team = fireData.departments.filter(tm => tm.id === id);
    if (team.length) {
      return team[0].title;
    }
    return null;
  };

  const getdepfromid = id => {
    const team = fireData.departments.filter(tm => tm.id === id);
    if (team.length) {
      return team[0];
    }
    return null;
  }

  const handleSubmit = () => {
    const newUserData = { ...user };
    newUserData.department = userDepartment;
    newUserData.school = userSchool;
    newUserData.nameMei = userMei;
    newUserData.nameSei = userSei;
    props.updateUser(newUserData);
    setIsEdit(!isEdit);
  };

  const newDepartmentSubmit = title => {
    createDepartment({ title: title });
    props.reload();
  };

  const departmentEditSubmit = department => {
    updateDepartment(department);
    props.reload();
  };

  const departmentDelete = id => {
    deleteDepartment(id);
    props.reload();
  };

  const newSchoolSubmit = title => {
    createSchool({ title: title });
    props.reload();
  };

  const schoolEditSubmit = school => {
    updateSchool(school);
    props.reload();
  };

  const schoolDelete = id => {
    deleteSchool(id);
    props.reload();
  };

  const renderDepartmentList = () => {
    const items = [];
    userDepartment.map((dep, index) => {
      if (getDepartmentFromId(dep) !== null) {
        items.push(
          <React.Fragment key={index}>
            {getDepartmentFromId(dep)}
            {index != userDepartment.length - 1 ? ', ' : null}
          </React.Fragment>,
        );
      }
    });
    if (!items.length) return '未設定';
    return items;
  };

  const renderSchoolList = () => {
    const items = [];
    userSchool.map((dep, index) => {
      if (getSchoolFromId(dep) !== null) {
        items.push(
          <React.Fragment key={index}>
            {getSchoolFromId(dep)}
            {index != userSchool.length - 1 ? ', ' : null}
          </React.Fragment>,
        );
      }
    });
    if (!items.length) return '未設定';
    return items;
  };

  const renderDepartment = id => {
    if (!isEdit) {
      return userDepartment.length
        ? renderDepartmentList()
        : '未確定';
    } else {
      return (
        <>
          <button
            className="modal-button"
            onClick={e => {
              setAnchorElDepartment(e.currentTarget);
            }}
          >
            {userDepartment.length
              ? renderDepartmentList()
              : '部門を選択'}
          </button>
          <DepartmentMenu
            anchorEl={anchorElDepartment}
            locs={fireData.departments}
            handleClose={() => setAnchorElDepartment(null)}
            editLocSubmit={department =>
              departmentEditSubmit(department)
            }
            newLocSubmit={title => newDepartmentSubmit(title)}
            deleteLocSubmit={departmentDelete}
            departmentSelected={departmentSelectClick}
            sels={userDepartment}
          />
        </>
      );
    }
  };

  const schoolSelectClick = loc => {
    const oldUserSchool = [...userSchool];
    if (oldUserSchool.includes(loc)) {
      const i = oldUserSchool.indexOf(loc);
      oldUserSchool.splice(i, 1);
    } else {
      oldUserSchool.push(loc);
    }
    setUserSchool(oldUserSchool);
  };

  const departmentSelectClick = loc => {
    const oldUserDepartment = [...userDepartment];
    if (oldUserDepartment.includes(loc)) {
      const i = oldUserDepartment.indexOf(loc);
      oldUserDepartment.splice(i, 1);
    } else {
      oldUserDepartment.push(loc);
    }
    setUserDepartment(oldUserDepartment);
  };

  const renderSchool = () => {
    if (!isEdit) {
      return userSchool.length ? renderSchoolList() : '未確定';
    } else {
      return (
        <>
          <button
            className="modal-button"
            onClick={e => {
              setAnchorElSchool(e.currentTarget);
            }}
          >
            {userSchool.length ? renderSchoolList() : '校舎を選択'}
          </button>
          <SchoolMenu
            anchorEl={anchorElSchool}
            locs={fireData.schools.filter((school) => {
              let hasSchool = false;
              userDepartment.map(item=>{
                const dep = getdepfromid(item);
                if(dep?.schools?.includes(school.id)) hasSchool = true;
              })
              return hasSchool;
            })}
            handleClose={() => setAnchorElSchool(null)}
            editLocSubmit={school => schoolEditSubmit(school)}
            newLocSubmit={title => newSchoolSubmit(title)}
            deleteLocSubmit={schoolDelete}
            schoolSelected={schoolSelectClick}
            sels={userSchool}
          />
        </>
      );
    }
  };

  const renderEditArea = () => {
    if (!isEdit) {
      return (
        <>
          <button
            className="user-edit"
            onClick={() => setIsEdit(!isEdit)}
          >
            編集
          </button>
        </>
      );
    } else {
      return (
        <>
          <button
            className="user-save"
            onClick={() => handleSubmit()}
          >
            完了
          </button>
        </>
      );
    }
  };

  const renderNameForm = () => {
    return (
      <div className="name-form">
        <input
          type="text"
          value={userSei}
          onChange={e => setUserSei(e.target.value)}
        />{' '}
        <input
          type="text"
          value={userMei}
          onChange={e => setUserMei(e.target.value)}
        />
      </div>
    );
  };

  const renderName = () => {
    if (isEdit) {
      return renderNameForm();
    } else {
      return (
        <div className="avatar-name">
          {userSei} {userMei}
        </div>
      );
    }
  };

  return (
      <TableRow className="user-list-item">
        <TableCell padding="checkbox">
          <Checkbox
            checked={props.selected}
            inputProps={{ 'aria-labelledby': props.labelId }}
            onClick={() => props.select(user.id)}
          />
        </TableCell>
        <TableCell>{renderName()}</TableCell>
        <TableCell>
          <div className="avatar-name">{user.email}</div>
        </TableCell>
        <TableCell>
          {user.department
            ? renderDepartment(user.department)
            : 'ERR'}
        </TableCell>
        <TableCell>
          {user.school ? renderSchool(user.school) : 'ERR'}
        </TableCell>
        <TableCell>
          <div className="avatar-name">
            {user.role !== 'admin' ? '講師' : '管理者'}
          </div>
        </TableCell>
        <TableCell>{renderEditArea()}</TableCell>
      </TableRow>
  );
};
