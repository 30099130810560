import React, {useEffect} from 'react';

import './SearchBox.scoped.scss';

const SearchBox = props => {
  const { value, updateHandler, placeholder } = props;

  const handleUpdate = (e)=>{
    updateHandler(e.target.value)
  }

  return (
    <div className="search-box">
      <input
        className="search-input"
        type="text"
        onChange={(e)=>handleUpdate(e)}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchBox;
