import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Marquee  from 'react-double-marquee';

const moment = extendMoment(Moment);

import './TaskEventDiffTotal.scoped.scss';
import Chip from './Chip';
const TaskProgressDiffRow = props => {
    const { text, totalWari, progress, parent } = props;

    return (
        <tr>
            <th className="mar-th"><Marquee direction="left" delay={0} speed="0.02" scrollWhen="overflow">{parent ? parent + " - " : ''}{text}</Marquee></th>
            <td><NumberFormat value={totalWari} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
            <td>
                <Chip tJiseki={parseInt(progress)} tWari={totalWari} />
            </td>
            <td><NumberFormat value={parseInt(progress)} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
        </tr>
    );
};

export default TaskProgressDiffRow;