import React, { useState, useEffect } from 'react';
import { withAuthorization } from '../components/Session';

//imports
import { faCogs } from '@fortawesome/free-solid-svg-icons'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

//api
import { getDepartments } from '../api/departments';
import { getSchools } from '../api/schools';
import { currentUser } from '../api/users';
import {addYear} from '../api/lineUsers';

//components
import App from '../components/Global/App';
import PageTitle from '../components/Common/PageTitle';
import YearLeveler from '../components/Masters/YearLeveler';
import DepartmentSchoolLinker from '../components/Masters/DepartmentSchoolLinker';


const Student = props => {

  const { uid } = currentUser();

  const [snackMessage, setSnackMessage] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);

  const [departments,setDepartments] = useState([]);
  const [schools, setSchools] = useState([]);

  useEffect(()=>{
    fetchAll();
  },[])

  const  fetchAll = async () => {
    await getDepartments().then((data)=>{
      setDepartments(data)
      getSchools().then((data)=>{
        setSchools(data);
      })
    });
  }
  const addYearToStudents = () => {
    addYear().then(()=>{
        openSnack('受講者の属性/学年が更新されました')
    })
  }

  //snack
  const openSnack = (message) => {
      setSnackMessage(message);
      setSnackOpen(true);
  }
  const closeSnack = () => {
      setSnackMessage('');
      setSnackOpen(false);
  }

  return (
    <App>
      <PageTitle title="マスター管理" icon={faCogs} />
      <DepartmentSchoolLinker departments={departments} schools={schools} reload={fetchAll} />
      <YearLeveler addYearToStudents={addYearToStudents} />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={closeSnack}
        message={snackMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnack}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </App>
  );
};

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Student);
