import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import moment from 'moment';
import {
  deleteWeeklyEvent,
  getWeeklyEvent,
  updateWeeklyEvent,
  updateTaskParentData,
  nullifyTask,
} from './weeklyEvents';

if (!firebase.apps.length) {
  const CLIENT_CONFIG = {
    apiKey: 'AIzaSyDeV1gVgtuJzbKfdmdIMXDu2Fy6XUmxTq4',
    authDomain: 'future-log-67799.firebaseapp.com',
    projectId: 'future-log-67799',
    storageBucket: 'future-log-67799.appspot.com',
    messagingSenderId: '337593555009',
    appId: '1:337593555009:web:6bc12bf96d00005cf48ec0',
    measurementId: 'G-DSJRD2QR53',
  };

  firebase.initializeApp(CLIENT_CONFIG);
}

const db = firebase.firestore();
const col = 'yearlyEvents';
const auth = firebase.auth();

export const getYearlyEvents = async (uid) => {
  const yearlyEventData = await db
    .collection(col)
    .where('user', '==', uid)
    .get()
    .then((snap) => {
      const data = [];
      snap.forEach((doc) => {
        data.push(doc.data());
      });
      return data;
    })
    .catch((err) => {
      return err;
    });
  return yearlyEventData;
};

export const searchYearlyEvents = async (uid, date) => {
  const yearlyEventData = await db
    .collection(col)
    .where('user', '==', uid)
    .where('type', 'in', ['task', 'routine'])
    .get()
    .then((snap) => {
      const data = [];
      snap.forEach((doc) => {
        data.push(doc.data());
      });
      return data;
    })
    .catch((err) => {
      return err;
    });
  let taskData = [...yearlyEventData];

  for (let i = 0; i <= taskData.length - 1; i++) {
    if (taskData[i].start_date && taskData[i].end_date) {
      taskData[i].start_date = new Date(
        taskData[i].start_date.seconds * 1000 +
          taskData[i].start_date.nanoseconds / 1000000,
      );
      taskData[i].end_date = new Date(
        taskData[i].end_date.seconds * 1000 +
          taskData[i].end_date.nanoseconds / 1000000,
      );
    }
  }
  const filteredTasks = taskData.filter((item) => {
    const weekRange = moment.range(
      moment(date).startOf('week'),
      moment(date).endOf('week'),
    );
    const taskRange = moment.range(item.start_date, item.end_date);
    return taskRange.overlaps(weekRange);
  });
  return filteredTasks;
};

export const getYearlyEvent = async (id) => {
  const data = await db
    .collection(col)
    .doc(id)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        return false;
      }
      return doc.data();
    })
    .catch((err) => {
      return err;
    });
  return data;
};

export const createYearlyEvent = async (yearlyEvent) => {
  const yearlyEventRef = db
    .collection(col)
    .doc(yearlyEvent.id.toString());
  const yearlyEventObj = {
    ...yearlyEvent,
    created: new Date(),
    updated: new Date(),
    eventProgressTotal: 0,
    weekTask: [],
  };
  await yearlyEventRef.set(yearlyEventObj);
  return yearlyEventObj;
};

export const updateYearlyEvent = async (yearlyEvent) => {
  if (!yearlyEvent.id) return false;

  const yearlyEventRef = db
    .collection(col)
    .doc(yearlyEvent.id.toString());
  const yearlyEventObj = { ...yearlyEvent };
  yearlyEventObj.updated = new Date();

  if (yearlyEventObj.weekTask) {
    if (yearlyEventObj.type === 'project') {
      yearlyEventObj.weekTask.map(async (item) => {
        await nullifyTask(item);
      });
      yearlyEventObj.weekTask = [];
    } else {
      yearlyEventObj.weekTask.map((item) => {
        updateTaskParentData(item, yearlyEventObj);
      });
    }
  }

  await yearlyEventRef
    .set(yearlyEventObj, { merge: true })
    .then(() => {
      recalculateJiseki(yearlyEventObj.id.toString());
    });

  return yearlyEventObj;
};

export const addWeeklyTaskAsChild = async (child, yearlyEventId) => {
  const yearlyEventRef = db
    .collection(col)
    .doc(yearlyEventId.toString());

  const yearlyEventData = await getYearlyEvent(
    yearlyEventId.toString(),
  );

  if (!yearlyEventData) return false;

  if (!yearlyEventData?.weekTask?.includes(child)) {
    yearlyEventData.weekTask.push(child);
    yearlyEventRef.set(yearlyEventData, { merge: true }).then(() => {
      recalculateJiseki(yearlyEventData.id.toString());
    });
  }
};

export const removeWeeklyTaskAsChild = async (child) => {
  const childEvent = await getWeeklyEvent(child);

  if (!childEvent) return false;

  const yearlyEventData = await getYearlyEvent(
    childEvent?.yearTask?.id.toString(),
  );

  if (!yearlyEventData) return false;

  yearlyEventData.weekTask = yearlyEventData.weekTask.filter(
    (task) => task !== child,
  );

  const yearlyEventRef = db
    .collection(col)
    .doc(childEvent?.yearTask?.id.toString());

  yearlyEventRef.set(yearlyEventData, { merge: true }).then(() => {
    recalculateJiseki(yearlyEventData.id.toString());
  });
};

export const deleteYearlyEvent = async (id) => {
  const evData = await getYearlyEvent(id).then((data) => {
    return data;
  });

  if (evData.weekTask) {
    if (evData.weekTask.length) {
      evData.weekTask.map((item) => {
        nullifyTask(item);
      });
    }
  }

  await db
    .collection(col)
    .doc(id)
    .delete()
    .catch((err) => {
      return err;
    });
  return true;
};

export const recalculateJiseki = async (id) => {
  const yearlyEventRef = db.collection(col).doc(id);

  const yearlyEvent = await getYearlyEvent(id);

  if (!yearlyEvent) return false;

  if (yearlyEvent?.type === 'project') return false;

  const yearlyEventObj = { ...yearlyEvent };

  const weeklyTasks = yearlyEventObj?.weekTask || [];

  yearlyEventObj.progress = 0;

  if(yearlyEventObj.start_date){
    yearlyEventObj.start_date = new Date(
      yearlyEventObj.start_date.seconds * 1000 +
        yearlyEventObj.start_date.nanoseconds / 1000000,
    );
  }


if(yearlyEventObj.end_date){
  yearlyEventObj.end_date = new Date(
    yearlyEventObj.end_date.seconds * 1000 +
      yearlyEventObj.end_date.nanoseconds / 1000000,
  );
}


  if (weeklyTasks.length) {
    let taskProgressTotal = 0;
    for (let i = 0; i < weeklyTasks.length; i++) {
      const weeklyEvent = await getWeeklyEvent(weeklyTasks[i]);
      taskProgressTotal += weeklyEvent.progress;
    }

    yearlyEventObj.eventProgressTotal = taskProgressTotal;
    yearlyEventObj.progress = getProgressRate(yearlyEventObj) / 100;
  } else {
    yearlyEventObj.progress = 0;
    yearlyEventObj.eventProgressTotal = 0;
  }

  await yearlyEventRef.set(yearlyEventObj, { merge: true });
};

export const getTaskTotalHours = (task) => {
  let sD = moment(task.start_date);
  let eD = moment(task.end_date);
  let overlapedWeekNum = Math.ceil(
    eD
      .clone()
      .subtract(1, 'day')
      .endOf('week')
      .diff(sD.clone().startOf('week'), 'weeks', true),
  );
  return task.type === 'routine'
    ? ((task.hpd * task.dpw) / 60) * overlapedWeekNum //hpd = minutes per day
    : parseInt(task.totalHours);
};

export const getProgressRate = (task, gantObj) => {
  switch (task.type) {
    case 'routine':
    case 'task': {
      let progressRate = parseFloat(
        (task.eventProgressTotal / (getTaskTotalHours(task) * 60)) * 100,
      ).toFixed(3);
      if (isNaN(progressRate)) return 0;
      return progressRate;
    }

    case 'project': {
      let tasksTotalHours = 0;
      let tasksTotalProgress = 0;
      gantObj.eachTask(function (task) {
        if(task.type === 'project')return;
        tasksTotalHours += getTaskTotalHours(task);
        tasksTotalProgress += task.eventProgressTotal;
      }, task.id);
      let progressRate = parseFloat(
        (tasksTotalProgress / (tasksTotalHours * 60)) * 100,
      ).toFixed(3);
      if (isNaN(progressRate)) return 0;
      return progressRate;
    }

    default: {
      console.error('detected unknown task type:' + task.type);
      break;
    }
  }
};
