import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          /*this.props.firebase.auth.currentUser.getIdToken().then((idToken) => {
            const payload = JSON.parse(b64DecodeUnicode(idToken.split('.')[1]));
            var role = payload['role'];
            alert(role);
            if (role !== 'admin') {
              this.props.history.push(ROUTES.HOME);
            }
          })*/
          if (!condition(authUser)) {
            this.props.history.push(ROUTES.HOME);
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            condition(authUser) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(
    withRouter,
    withFirebase,
  )(WithAuthorization);
};

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}


export default withAuthorization;
