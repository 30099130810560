import React, { useState, useEffect } from 'react';
import { useLiff } from 'react-liff';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../components/Common/Calendar/calendar-jss';
import '../../components/Common/Calendar/calendar.scss';
import LineAuthCheck from '../../components/Line/LineAuthCheck/LineAuthCheck';
import Toolbar from '../../components/Common/Calendar/Toolbar';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);
moment.locale('ja', {
  week: {
    dow: 1,
    doy: 1,
  },
});

import { useSwipeable } from 'react-swipeable';

import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import GantModal from '../../components/Common/Gant/GantModal';
import TemplateSelectModal from '../../components/Common/TemplateModals/TemplateSelectModal';
import TemplateAddModal from '../../components/Common/TemplateModals/TemplateAddModal';
import CreateEventModal from '../../components/Common/EventModals/CreateEventModal';
import ChooseTaskModal from '../../components/Common/EventModals/ChooseTaskModal';

import TaskEventDiffTotalModal from '../../components/Common/TaskEventDiffTotal/TaskEventDiffTotalModal';
import DeleteConfirmModal from '../../components/Common/DeleteConfirmModal';
import LineBotSettings from '../../components/Line/LineBotSettings/LineBotSettings';
import OnboardModal from '../../components/Line/OnboardModal/OnboardModal';
import MoveEventModal from '../../components/Line/EventMoveModal/MoveEventModal';
import {
  getWeeklyEvents,
  createWeeklyEvent,
  updateWeeklyEvent,
  deleteWeeklyEvent,
  defaultWeeklyStructure,
  createTemplateEvent,
  cleanTaskData,
} from '../../api/weeklyEvents';
import {
  getWeeklyInfo,
  updateWeeklyInfo,
  createWeeklyInfo,
} from '../../api/weeklyinfo';
import {
  getYearlyEvents,
  searchYearlyEvents,
} from '../../api/yearlyEvents';
import NumberFormat from 'react-number-format';
import { getDailyInfo } from '../../api/dailyinfo';

import {
  getTemplates,
  createTemplate,
  updateTemplate,
  deleteTemplate,
} from '../../api/templates';

import { getLineUser, updateLineUser } from '../../api/lineUsers';

import TaskEventDiffTotal from '../../components/Common/TaskEventDiffTotal/TaskEventDiffTotal';

import Stopwatch from '../../components/Line/Stopwatch/Stopwatch';

const localizer = momentLocalizer(moment);

const BadgeItem = (data) => {
  const dayData = data.day ? data.day : null;
  if (!dayData) return null;
  const dayBadge = dayData.badge ? dayData.badge.toString() : null;
  if (!dayBadge) return null;
  if (dayBadge === '4') return null;

  return (
    <figure className="badge">
      <img src={`/badges/badge${dayBadge}.svg`} />
    </figure>
  );
};

const hasParent = (event) => {
  const that = event.event;
  if (!that.yearTask) return false;
  if (that.yearTask.type === 'project') return false;
  const parent = cleanTaskData(that.yearTask);
  const st = moment(that.start).startOf('week');
  const en = moment(that.end).endOf('week');
  const taskRange = moment.range(st, en);
  const parentRange = moment.range(
    moment(parent.start_date),
    moment(parent.end_date),
  );

  const overlaps = taskRange.overlaps(parentRange);

  return overlaps;
};

const Event = (event) => {
  function isEventTop() {
    const st = moment(event.start);
    const en = moment(event.end);
    let duration = moment.duration(en.diff(st));
    let difference = duration.asMinutes();
    if (difference < 239) return true;
    return false;
  }

  function jisekiHeight() {
    const eventItem = event.event;

    const eStart = moment(eventItem.start);
    const eEnd = moment(eventItem.end);
    const eDiff = eEnd.diff(eStart, 'minutes', true);
    const jDiff = eventItem.progress;
    return (jDiff / eDiff) * 100;
  }

  return (
    <div
      className={
        !hasParent(event) ? 'has-no-master event-item' : 'event-item'
      }
    >
      <div className={isEventTop() ? 'event-wrap' : 'event-wrap top'}>
        <span className="eventBlock">
          {hasParent(event)
            ? event.title
            : 'タスクを選択して下さい。'}
          {hasParent(event) ? (
            <strong
              className="jiseki-bar"
              style={{
                display: 'block',
                height: `${jisekiHeight()}%`,
              }}
            />
          ) : null}
        </span>
      </div>
    </div>
  );
};

const DragAndDropCalendar = withDragAndDrop(Calendar);

import FurikaeruModal from '../../components/Common/FurikaeruModal/FurikaeruModal';
import FurikaeruMessage from '../../components/Common/FurikaeruMessage/FurikaeruMessage';

function weekOfMonth(m) {
  var day = m.day();
  var nthOfMoth = Math.ceil(m.date() / 7);
  return nthOfMoth;
}

const LineCalendar = (props) => {
  //Construct
  let isMobile = width <= 768;
  const formats = {
    dateFormat: 'dd',
    dayFormat: 'DD(dd)',
    timeGutterFormat: 'HH:mm',
    dayRangeHeaderFormat: ({ start, end }) => {
      return (
        moment(start).format('YYYY/MM/DD') +
        ' - ' +
        moment(end).format('MM/DD')
      );
    },
  };
  const eventStyleGetter = (event) => {
    const backgroundColor = hasParent({ event: event })
      ? '#E26579'
      : '#D6D6D6';
    const style = {
      backgroundColor,
    };
    return {
      style,
    };
  };

  //LIFF
  const { liff } = useLiff();
  const context = liff.getContext();
  const id = context.userId;

  const [weeklyInfo, setWeeklyInfo] = useState(null);

  //STATES
  const [isSpeed, setIsSpeed] = useState(false);
  const [isEvent, setIsEvent] = useState(false);
  const [isStopWatch, setIsStopWatch] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selDate, setSelDate] = useState(new Date());
  const [width, setWidth] = useState(window.innerWidth);
  const [loadingBadges, setLoadingBadges] = useState(true);


  const [gantModalOpen, setGantModalOpen] = useState(false);
  const [
    templateSelectModalOpen,
    setTemplateSelectModalOpen,
  ] = useState(false);
  const [templateAddModalOpen, setTemplateAddModalOpen] = useState(
    false,
  );
  const [entryModalOpen, setEntryModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [furikaeruModalOpen, setFurikaeruModalOpen] = useState(false);
  const [
    TaskEventDiffTotalOpen,
    setTaskEventDiffTotalOpen,
  ] = useState(false);
  const [
    DeleteConfirmModalOpen,
    setDeleteConfirmModalOpen,
  ] = useState(false);
  const [LineBotSettingsOpen, setLineBotSettingsOpen] = useState(
    false,
  );

  const [entryModalIsEdit, setEntryModalIsEdit] = useState(false);
  const [entryModalIndex, setEntryModalIndex] = useState(0);
  const [myTemplates, setMyTemplates] = useState([]);

  const [myEventsList, setMyEventsList] = useState({
    ...defaultWeeklyStructure,
  });
  const [allEvents, setAllEvents] = useState([]);

  const [yearlyEvents, setYearlyEvents] = useState([]);
  const [badge1, setBadge1] = useState(null);
  const [badge2, setBadge2] = useState(null);
  const [badge3, setBadge3] = useState(null);
  const [badge4, setBadge4] = useState(null);
  const [badge5, setBadge5] = useState(null);
  const [badge6, setBadge6] = useState(null);
  const [badge7, setBadge7] = useState(null);
  const [day1, setDay1] = useState([]);
  const [day2, setDay2] = useState([]);
  const [day3, setDay3] = useState([]);
  const [day4, setDay4] = useState([]);
  const [day5, setDay5] = useState([]);
  const [day6, setDay6] = useState([]);
  const [day7, setDay7] = useState([]);

  const [OnboardModalOpen, setOnboardModalOpen] = useState(false);
  const [lineUser, setLineUser] = useState(null);
  const [dateMarq, setDateMarq] = useState(null);
  const [isPreviewLock, setIsPreviewLock] = useState(false);
  const [isMovingModal, setIsMovingModal] = useState(false);
  const [yearlyEventsSearched, setYearlyEventsSearched] = useState(
    [],
  );
  const [taskSelectModalOpen, setTaskSelectModalOpen] = useState(
    false,
  );

  //EFFECTS
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    window.scrollTo(0, (document.body.scrollHeight - 400) / 2);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    fetchYearlyEvents();
  }, []);

  useEffect(() => {
    setLoadingBadges(true);
    fetchInfos().then(()=>{
      setLoadingBadges(false);
    });
    fetchEvents();
    fetchWeeklyInfo();
    fetchYearlyEvents();
  }, [selDate]);

  useEffect(() => {
    getLineUser(context.userId).then((data) => {
      setLineUser(data);
      if (data.showMover === true) setOnboardModalOpen(true);
    });
  }, []);

  //ASYNC DATA
  const fetchTemplates = async () => {
    await getTemplates(context.userId).then((data) => {
      setMyTemplates(data);
    });
  };

  const fetchEvents = async () => {
    await getWeeklyEvents(id, selDate).then((data) => {
      if (data) {
        setMyEventsList(data.filtered);
        setAllEvents(data.all);
      } else {
        setMyEventsList({ ...defaultWeeklyStructure });
        setAllEvents([]);
      }
    });
  };

  const fetchInfos = async () => {
    let lineId = context.userId;
    const defDate = moment(selDate).startOf('week');
    const day1 = await getDailyInfo(
      defDate.format('Y'),
      defDate.startOf('week').format('MM'),
      defDate.startOf('week').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });
    const day2 = await getDailyInfo(
      defDate.clone().add(1, 'day').format('Y'),
      defDate.clone().add(1, 'day').format('MM'),
      defDate.clone().add(1, 'day').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });
    const day3 = await getDailyInfo(
      defDate.clone().add(2, 'days').format('Y'),
      defDate.clone().add(2, 'day').format('MM'),
      defDate.clone().add(2, 'day').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });
    const day4 = await getDailyInfo(
      defDate.clone().add(3, 'days').format('Y'),
      defDate.clone().add(3, 'day').format('MM'),
      defDate.clone().add(3, 'day').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });
    const day5 = await getDailyInfo(
      defDate.clone().add(4, 'days').format('Y'),
      defDate.clone().add(4, 'day').format('MM'),
      defDate.clone().add(4, 'day').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });
    const day6 = await getDailyInfo(
      defDate.clone().add(5, 'days').format('Y'),
      defDate.clone().add(5, 'day').format('MM'),
      defDate.clone().add(5, 'day').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });
    const day7 = await getDailyInfo(
      defDate.clone().add(6, 'days').format('Y'),
      defDate.clone().add(6, 'day').format('MM'),
      defDate.clone().add(6, 'day').format('DD'),
      lineId,
    ).then((data) => {
      return data[0];
    });

    if (day1) {
      setDay1(day1);
      setBadge1(day1.badge);
    } else {
      setDay1(null);
      setBadge1(null);
    }
    if (day2) {
      setDay2(day2);
      setBadge2(day2.badge);
    } else {
      setDay2(null);
      setBadge2(null);
    }
    if (day3) {
      setDay3(day3);
      setBadge3(day3.badge);
    } else {
      setDay3(null);
      setBadge3(null);
    }
    if (day4) {
      setDay4(day4);
      setBadge4(day4.badge);
    } else {
      setDay4(null);
      setBadge4(null);
    }

    if (day5) {
      setDay5(day5);
      setBadge5(day5.badge);
    } else {
      setDay5(null);
      setBadge5(null);
    }

    if (day6) {
      setDay6(day6);
      setBadge6(day6.badge);
    } else {
      setDay6(null);
      setBadge6(null);
    }

    if (day7) {
      setDay7(day7);
      setBadge7(day7.badge);
    } else {
      setDay7(null);
      setBadge7(null);
    }
  };

  const fetchYearlyEvents = async () => {
    await getYearlyEvents(id).then((data) => {
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].start_date && data[i].end_date) {
          data[i].start_date = new Date(
            data[i].start_date.seconds * 1000 +
            data[i].start_date.nanoseconds / 1000000,
          );
          data[i].end_date = new Date(
            data[i].end_date.seconds * 1000 +
            data[i].end_date.nanoseconds / 1000000,
          );
        }
      }
      setYearlyEvents(data);
    });
    await searchYearlyEvents(id, selDate).then((data) => {
      setYearlyEventsSearched(data);
    });
  };

  const fetchWeeklyInfo = async () => {
    await getWeeklyInfo(id, selDate).then((data) => {
      if (data) {
        setWeeklyInfo(data);
      } else {
        setWeeklyInfo(null);
      }
    });
  };

  //METHODS
  const moveEvent = async ({ event, start, end }) => {
    const idx = allEvents.indexOf(event);
    const updatedEvent = { ...event, start, end };
    const nextEvents = [...allEvents];
    nextEvents.splice(idx, 1, updatedEvent);
    setAllEvents(nextEvents);
    updatedEvent.sentMessageToUser = false;
    updateWeeklyEvent(updatedEvent).then(() => {
      fetchEvents();
    });
  };
  const resizeEvent = async ({ event, start, end }) => {
    const nextEvents = allEvents.map((existingEvent) => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });
    setAllEvents(nextEvents);
    updatedEvent.sentMessageToUser = false;
    updateWeeklyEvent({ ...event, start, end }).then(() => {
      fetchEvents();
    });
  };
  const addEvent = async (eventData) => {
    const event = { ...eventData };
    event.progress = eventData.progress || 0;
    event.user = id;
    const eventList = [...allEvents];
    eventList.push(event);
    setAllEvents(eventList);
    createWeeklyEvent(event).then(() => {
      fetchEvents();
    });
  };
  const editEvent = async (eve) => {
    const event = { ...eve };
    const idx = allEvents.indexOf(event);
    const updatedEvent = { ...event };
    const nextEvents = [...allEvents];
    nextEvents.splice(idx, 1, updatedEvent);
    setAllEvents(nextEvents);
    updateWeeklyEvent(updatedEvent).then(() => {
      fetchEvents();
    });
  };
  const copyEvent = async () => {
    const list = [...allEvents];
    const newEvent = { ...selected };
    newEvent.progress = 0;
    addEvent(newEvent);
    setSelected(null);
    handleClose();
  };
  const toggleSpeedDial = () => {
    setSelected(null);
    if (isSpeed) {
      setSelected(null);
      setIsSpeed(false);
    } else {
      setIsSpeed(true);
    }
  };
  const toggleEventBg = () => {
    if (isEvent) {
      setSelected(null);
      setIsEvent(false);
    } else {
      setIsEvent(true);
    }
  };
  const toggleGantModal = () => {
    handleClose();
    setGantModalOpen(true);
  };
  const toggleAddEventModal = () => {
    handleClose();
    setEntryModalOpen(true);
    setEntryModalIndex(0);
  };
  const entryModalEdit = (tabIndex) => {
    handleClose();
    setEntryModalOpen(true);
    setEntryModalIsEdit(true);
    setEntryModalIndex(tabIndex);
  };
  const toggleTemplateSelectModal = () => {
    handleClose();
    setTemplateSelectModalOpen(true);
  };
  const toggleBotModal = () => {
    handleClose();
    setLineBotSettingsOpen(true);
  };
  const toggleTaskEventDiffTotal = () => {
    handleClose();
    setTaskEventDiffTotalOpen(true);
  };
  const deleteEvent = async () => {
    handleClose();
    setEntryModalOpen(false);
    setTaskSelectModalOpen(false);
    setDeleteConfirmModalOpen(true);
  };
  const selectTemplate = async (template) => {
    const currentWeekTasks = { ...myEventsList.template };
  
    const eventsToDelete = [];

    currentWeekTasks?.mon?.events.map(async (item) => {
      eventsToDelete.push(item.id);
    });
    currentWeekTasks?.tue?.events.map(async (item) => {
      eventsToDelete.push(item.id);
    });
    currentWeekTasks?.wed?.events.map(async (item) => {
      eventsToDelete.push(item.id);
    });
    currentWeekTasks?.thur?.events.map(async (item) => {
      eventsToDelete.push(item.id);
    });
    currentWeekTasks?.fri?.events.map(async (item) => {
      eventsToDelete.push(item.id);
    });
    currentWeekTasks?.sat?.events.map(async (item) => {
      eventsToDelete.push(item.id);
    });
    currentWeekTasks?.sun?.events.map(async (item) => {
      eventsToDelete.push(item.id);
    });

    eventsToDelete.map(async (item) => {
      await deleteWeeklyEvent(item).then(() => {
        fetchEvents();
      });
    });

    const templateEvents = { ...template.events };

    setTemplateSelectModalOpen(false);
    const offset = moment(selDate).startOf('week');
    templateEvents?.mon?.events.map(async (evt) => {
      await createTemplateEvent(evt, offset).then(() => {
        fetchEvents();
      });
    });
    templateEvents?.tue?.events.map(async (evt) => {
      await createTemplateEvent(
        evt,
        offset.clone().add(1, 'day'),
      ).then(() => {
        fetchEvents();
      });
    });
    templateEvents?.wed?.events.map(async (evt) => {
      await createTemplateEvent(
        evt,
        offset.clone().add(2, 'day'),
      ).then(() => {
        fetchEvents();
      });
    });
    templateEvents?.thur?.events.map(async (evt) => {
      await createTemplateEvent(
        evt,
        offset.clone().add(3, 'day'),
      ).then(() => {
        fetchEvents();
      });
    });
    templateEvents?.fri?.events.map(async (evt) => {
      await createTemplateEvent(
        evt,
        offset.clone().add(4, 'day'),
      ).then(() => {
        fetchEvents();
      });
    });
    templateEvents?.sat?.events.map(async (evt) => {
      await createTemplateEvent(
        evt,
        offset.clone().add(5, 'day'),
      ).then(() => {
        fetchEvents();
      });
    });
    templateEvents?.sun?.events.map(async (evt) => {
      await createTemplateEvent(
        evt,
        offset.clone().add(6, 'day'),
      ).then(() => {
        fetchEvents();
      });
    });
  };

  const closeAndHideMover = async (hide) => {
    if (hide === true) {
      const vLineUser = { ...lineUser };
      vLineUser.showMover = false;
      await updateLineUser(vLineUser).then(() => {
        setOnboardModalOpen(false);
      });
    } else {
      setOnboardModalOpen(false);
    }
  };
  const closeWithGant = (hide) => {
    setGantModalOpen(true);
    if (hide === true) {
      closeAndHideMover(hide);
    }
    setOnboardModalOpen(false);
  };
  const saveTemplate = (templateName) => {
    const templateEvents = { ...myEventsList.template };

    const templateData = {
      name: templateName,
      events: templateEvents,
      lineId: context.userId,
    };

    createTemplate(templateData);
    fetchTemplates();
    setTemplateAddModalOpen(false);
    setTemplateSelectModalOpen(true);
  };
  const submitDeleteTemplate = async (id) => {
    await deleteTemplate(id).then(async () => {
      await fetchTemplates();
    });
  };

  const submitOverrideTemplate = async (id) => {
    const oldTemplate = { ...id };
    const templateEvents = { ...myEventsList.template };
    oldTemplate.events = templateEvents;
    await updateTemplate(oldTemplate).then(() => {
      fetchTemplates();
    });
  };

  const fillMaxprogress = () => {
    const currSelected = { ...selected };
    currSelected.progress = Number(
      moment(currSelected.end).diff(
        moment(currSelected.start),
        'minutes',
        true,
      ),
    );
    editEvent(currSelected).then(() => {
      setSelected(null);
      handleClose();
    });
  };
  const togglePreviewLock = () => {
    setIsPreviewLock(!isPreviewLock);
  };
  const submitStopwatch = async (time) => {
    const currSelected = { ...selected };
    const hours = time.hours;
    const minutes = time.minutes;
    const total = Math.trunc(hours * 60) + minutes;
    currSelected.progress += Number(total);
    await editEvent(currSelected).then(() => {
      handleClose();
    });
  };
  const editEventFromMover = async (event) => {
    const idx = allEvents.indexOf(selected);
    const updatedEvent = { ...event };
    const nextEvents = [...allEvents];
    nextEvents.splice(idx, 1, updatedEvent);
    setSelected(nextEvents[idx]);
    setAllEvents(nextEvents);
    updateWeeklyEvent(event);
  };
  const reloadEvents = async () => {
    fetchInfos();
    fetchEvents();
    fetchWeeklyInfo();
    fetchYearlyEvents();
  };

  //HANDLERS
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setSelDate(moment(selDate).add(1, 'week').toDate());
    },
    onSwipedRight: () => {
      setSelDate(moment(selDate).subtract(1, 'week').toDate());
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  });

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const handleClose = () => {
    setIsEvent(false);
    setIsSpeed(false);
    setIsStopWatch(false);
    setEntryModalIsEdit(false);
    setEntryModalIndex(0);
  };
  const handleEventClick = (event) => {
    setEntryModalIndex(0);
    setSelected(event);
    if (!hasParent({ event: event })) {
      setTaskSelectModalOpen(true);
    } else {
      toggleEventBg();
    }
  };
  const handleGantModal = () => {
    getYearlyEvents(id).then((data) => {
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].start_date && data[i].end_date) {
          data[i].start_date = new Date(
            data[i].start_date.seconds * 1000 +
            data[i].start_date.nanoseconds / 1000000,
          );
          data[i].end_date = new Date(
            data[i].end_date.seconds * 1000 +
            data[i].end_date.nanoseconds / 1000000,
          );
        }
      }
      setYearlyEvents(data);
    });
    fetchEvents();
    fetchInfos();
    setGantModalOpen(false);
  };
  const handleSelectMarq = (slotInfo) => {
    const { start, end } = slotInfo;
    setDateMarq([start, end]);
    setEntryModalOpen(true);
  };

  //COMPUTED
  const returnTotalDifferenceChip = (tJiseki, tWari) => {
    let diff = tJiseki - tWari;

    let checker = Math.sign(diff);

    let mark = '±';
    let classVal = null;

    if (checker === -1) {
      mark = '';
      classVal = 'minus';
    }

    if (checker === 1) {
      mark = '+';
      classVal = 'plus';
    }
    return (
      <>
        <strong className={classVal}>
          <NumberFormat
            value={diff}
            prefix={mark}
            displayType={'text'}
            decimalScale={0}
            thousandSeparator={true}
            suffix={'分'}
          />
        </strong>
      </>
    );
  };

  //Search Yearly events

  return (
    <LineAuthCheck>
      <div
        className={
          isMovingModal ? 'move-wrap mover-active' : 'move-wrap'
        }
      >
        <div className="cal-wrap line-cal">
          <div
            className={
              isPreviewLock ? 'preview-lock active' : 'preview-lock'
            }
          >
            <div className="preview-lock-preview">
              <TaskEventDiffTotal
                events={myEventsList}
                tasksS={yearlyEventsSearched}
                selDate={selDate}
                tasks={yearlyEvents.filter((task) => {
                  return (
                    task.type === 'task' || task.type === 'routine'
                  );
                })}
              />
            </div>
            <button
              className="preview-lock-toggle"
              onClick={() => {
                togglePreviewLock();
              }}
            >
              <img src="/icons/speed3.png" alt="" />
            </button>
          </div>
          <FurikaeruMessage
            openFurikaeri={() => setFurikaeruModalOpen(true)}
            date={selDate}
            weeklyInfo={weeklyInfo}
            fetchWeeklyInfo={fetchWeeklyInfo}
          />
          <div {...handlers}>
            <div className={props.classes.root}>
              <div className="line-calendar-wrapper">
                <DragAndDropCalendar
                  localizer={localizer}
                  draggableAccessor={(event) => false}
                  resizeable={false}
                  className={props.classes.calendarWrap}
                  selectable
                  resizable
                  events={allEvents}
                  defaultView="week"
                  views={['week']}
                  onEventDrop={moveEvent}
                  onEventResize={resizeEvent}
                  date={selDate}
                  onNavigate={(date) => setSelDate(date)}
                  eventPropGetter={eventStyleGetter}
                  onSelectEvent={(selectedEvent) =>
                    handleEventClick(selectedEvent)
                  }
                  selected={selected}
                  min={moment('24:00', 'HH:mm:ss').toDate()}
                  max={moment('23:59', 'HH:mm:ss').toDate()}
                  components={{
                    toolbar: Toolbar,
                    week: {
                      event: Event,
                    },
                  }}
                  step={15}
                  timeslots={4}
                  formats={formats}
                  onSelectSlot={handleSelectMarq}
                />
              </div>
            </div>
            <div className="line-calendar-footer">
              <table>
                <tbody>
                  <tr>
                    <th className="base">
                      計画割当
                      <br />
                      <NumberFormat
                        value={
                          myEventsList.current.totals.plannedMinutes
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                    </th>
                    <td>
                      <NumberFormat
                        defaultValue={0}
                        value={
                          myEventsList.current.mon.totals
                            .plannedMinutes
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        defaultValue={0}
                        value={
                          myEventsList.current.tue.totals
                            .plannedMinutes
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        defaultValue={0}
                        value={
                          myEventsList.current.wed.totals
                            .plannedMinutes
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        defaultValue={0}
                        value={
                          myEventsList.current.thur.totals
                            .plannedMinutes
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        defaultValue={0}
                        value={
                          myEventsList.current.fri.totals
                            .plannedMinutes
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        defaultValue={0}
                        value={
                          myEventsList.current.sat.totals
                            .plannedMinutes
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                    </td>
                    <td>
                      <NumberFormat
                        defaultValue={0}
                        value={
                          myEventsList.current.sun.totals
                            .plannedMinutes
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="real">
                      <strong className="chip">
                        <NumberFormat
                          value={
                            myEventsList.current.totals.progress -
                            myEventsList.current.totals.plannedMinutes
                          }
                          displayType={'text'}
                          decimalScale={0}
                          thousandSeparator={true}
                          suffix={'分'}
                        />
                      </strong>
                      実績
                      <br />
                      <NumberFormat
                        value={myEventsList.current.totals.progress}
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                    </th>
                    <td>
                      <NumberFormat
                        value={
                          myEventsList.current.mon.totals.progress
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                      {returnTotalDifferenceChip(
                        myEventsList.current.mon.totals.progress,
                        myEventsList.current.mon.totals
                          .plannedMinutes,
                      )}
                    </td>
                    <td>
                      <NumberFormat
                        value={
                          myEventsList.current.tue.totals.progress
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                      {returnTotalDifferenceChip(
                        myEventsList.current.tue.totals.progress,
                        myEventsList.current.tue.totals
                          .plannedMinutes,
                      )}
                    </td>
                    <td>
                      <NumberFormat
                        value={
                          myEventsList.current.wed.totals.progress
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                      {returnTotalDifferenceChip(
                        myEventsList.current.wed.totals.progress,
                        myEventsList.current.wed.totals
                          .plannedMinutes,
                      )}
                    </td>
                    <td>
                      <NumberFormat
                        value={
                          myEventsList.current.thur.totals.progress
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                      {returnTotalDifferenceChip(
                        myEventsList.current.thur.totals.progress,
                        myEventsList.current.thur.totals
                          .plannedMinutes,
                      )}
                    </td>
                    <td>
                      <NumberFormat
                        value={
                          myEventsList.current.fri.totals.progress
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                      {returnTotalDifferenceChip(
                        myEventsList.current.fri.totals.progress,
                        myEventsList.current.fri.totals
                          .plannedMinutes,
                      )}
                    </td>
                    <td>
                      <NumberFormat
                        value={
                          myEventsList.current.sat.totals.progress
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                      {returnTotalDifferenceChip(
                        myEventsList.current.sat.totals.progress,
                        myEventsList.current.sat.totals
                          .plannedMinutes,
                      )}
                    </td>
                    <td>
                      <NumberFormat
                        value={
                          myEventsList.current.sun.totals.progress
                        }
                        displayType={'text'}
                        decimalScale={0}
                        thousandSeparator={true}
                        suffix={'分'}
                      />
                      {returnTotalDifferenceChip(
                        myEventsList.current.sun.totals.progress,
                        myEventsList.current.sun.totals
                          .plannedMinutes,
                      )}
                    </td>
                  </tr>

                  <tr className="badges">
                    <th />
                    <td>
                      {!loadingBadges ? <BadgeItem day={day1} /> : null}
                    </td>
                    <td>
                      {!loadingBadges ? <BadgeItem day={day2} /> : null}
                    </td>
                    <td>
                      {!loadingBadges ? <BadgeItem day={day3} /> : null}
                    </td>
                    <td>
                      {!loadingBadges ? <BadgeItem day={day4} /> : null}
                    </td>
                    <td>
                      {!loadingBadges ? <BadgeItem day={day5} /> : null}
                    </td>
                    <td>
                      {!loadingBadges ? <BadgeItem day={day6} /> : null}
                    </td>
                    <td>
                      {!loadingBadges ? <BadgeItem day={day7} /> : null}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {isSpeed || isEvent || isStopWatch ? (
            <div
              className="modal"
              onClick={() => {
                setSelected(null);
                handleClose();
              }}
            />
          ) : null}
          <div
            className="speed-dial"
            style={{ zIndex: isSpeed ? 2000 : 500 }}
          >
            {isSpeed ? (
              <ul>
                <li>
                  <button
                    className="speed-sub"
                    onClick={() => toggleBotModal()}
                  >
                    <span>通知設定</span>
                    <i>
                      <img src="/icons/black-cog.png" />
                    </i>
                  </button>
                </li>
                <li>
                  <button
                    className="speed-sub"
                    onClick={() => toggleGantModal()}
                  >
                    <span>目標達成管理表</span>
                    <i>
                      <img src="/icons/speed5.png" />
                    </i>
                  </button>
                </li>
                <li>
                  <button
                    className="speed-sub"
                    onClick={() => toggleTemplateSelectModal()}
                  >
                    <span>テンプレート</span>
                    <i>
                      <img src="/icons/speed1.png" />
                    </i>
                  </button>
                </li>
                <li>
                  <button
                    className="speed-sub"
                    onClick={() => toggleTaskEventDiffTotal()}
                  >
                    <span>タスク別集計</span>
                    <i>
                      <img src="/icons/speed3.png" />
                    </i>
                  </button>
                </li>
              </ul>
            ) : null}

            <button
              className="speed-button"
              onClick={() =>
                isSpeed ? toggleAddEventModal() : toggleSpeedDial()
              }
            >
              <span>{isSpeed ? '計画割当/実績入力' : null}</span>
              <i>
                {isSpeed ? (
                  <img src="/icons/speed4.png" />
                ) : (
                  <img src="/icons/hamb.png" />
                )}
              </i>
            </button>
          </div>
        </div>
        {isEvent ? (
          <div className="event-buttons">
            <ul>
              <li>
                <button
                  onClick={() => {
                    handleClose();
                    setIsMovingModal(true);
                  }}
                >
                  移動・伸縮
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    handleClose();
                    setIsStopWatch(1);
                  }}
                >
                  ストップウォッチ
                </button>
              </li>
              <li>
                <button onClick={() => entryModalEdit(0)}>
                  計画割当編集
                </button>
              </li>
              <li>
                <button onClick={() => entryModalEdit(1)}>
                  実績入力
                </button>
              </li>
              <li>
                <button onClick={() => copyEvent()}>コピー</button>
              </li>
              <li>
                <button onClick={() => deleteEvent()}>削除</button>
              </li>
              <li className="finished">
                <button
                  className="finished-progress"
                  onClick={() => fillMaxprogress()}
                >
                  タスク
                  <br />
                  <small>コンプリート!</small>
                </button>
              </li>
            </ul>
          </div>
        ) : null}
        {isStopWatch ? (
          <Stopwatch
            close={handleClose}
            submit={submitStopwatch}
            onChange={(val) => {
              setStopwatchTime(val);
            }}
          />
        ) : null}
        {gantModalOpen ? (
          <GantModal
            open={gantModalOpen}
            user={context.userId}
            close={() => handleGantModal()}
            onClose={() => handleGantModal()}
            events={
              yearlyEvents
                ? { data: [...yearlyEvents] }
                : { data: [] }
            }
            reload={reloadEvents}
          />
        ) : null}
        <LineBotSettings
          open={LineBotSettingsOpen}
          user={id}
          close={() => setLineBotSettingsOpen(false)}
        />
        <TemplateSelectModal
          open={templateSelectModalOpen}
          close={() => setTemplateSelectModalOpen(false)}
          new={() => {
            setTemplateSelectModalOpen(false);
            setTemplateAddModalOpen(true);
          }}
          templates={myTemplates}
          templateSelected={selectTemplate}
          weekTasks={myEventsList}
          submitDelete={submitDeleteTemplate}
          submitOverride={submitOverrideTemplate}
        />
        <TemplateAddModal
          open={templateAddModalOpen}
          close={() => setTemplateAddModalOpen(false)}
          onCreate={saveTemplate}
          onDelete={deleteTemplate}
        />
        <CreateEventModal
          open={entryModalOpen}
          close={() => {
            setEntryModalOpen(false);
            setSelected(null);
            fetchEvents();
            setDateMarq(null);
          }}
          onClose={() => {
            setEntryModalOpen(false);
            setSelected(null);
            fetchEvents();
            setDateMarq(null);
          }}
          isEdit={entryModalIsEdit}
          dateMarq={dateMarq}
          selected={selected}
          save={(obj) => {
            addEvent(obj);
          }}
          edit={(obj) => {
            editEvent(obj);
          }}
          tabIndex={entryModalIndex}
          events={myEventsList}
          tasksS={yearlyEventsSearched}
          tasks={yearlyEvents.filter((task) => {
            return task.type === 'task' || task.type === 'routine';
          })}
          gantModalStatus={gantModalOpen}
          selDate={selDate}
          deleteTask={() => deleteEvent()}
        />
        <ChooseTaskModal
        events={myEventsList}
        tasksS={yearlyEventsSearched}
        tasks={yearlyEvents.filter((task) => {
          return task.type === 'task' || task.type === 'routine';
        })}
        selected={selected}
        deleteTask={() => deleteEvent()}
        edit={(obj) => {
          editEvent(obj);
        }}
        open={taskSelectModalOpen}
        close={() => {
          setTaskSelectModalOpen(false);
          setSelected(null);
          fetchEvents();
          setDateMarq(null);
        }}
        onClose={() => {
          setTaskSelectModalOpen(false);
          setSelected(null);
          fetchEvents();
          setDateMarq(null);
        }}
        selDate={selDate}
      />
        <TaskEventDiffTotalModal
          open={TaskEventDiffTotalOpen}
          close={() => setTaskEventDiffTotalOpen(false)}
          events={myEventsList}
          tasks={yearlyEvents.filter((task) => {
            return task.type === 'task' || task.type === 'routine';
          })}
          selDate={selDate}
        />
        <DeleteConfirmModal
          open={selected && DeleteConfirmModalOpen}
          close={() => setDeleteConfirmModalOpen(false)}
          onClose={() => {
            setEditModalOpen(false);
            setSelected(null);
            fetchEvents();
          }}
          submit={() => {
            deleteWeeklyEvent(selected.id).then(async () => {
              setSelected(null);
              await fetchEvents();
            });
          }}
        />
        <OnboardModal
          open={OnboardModalOpen}
          close={closeAndHideMover}
          closeWithGant={closeWithGant}
        />
        {isMovingModal ? (
          <MoveEventModal
            close={async () => {
              setIsMovingModal(false);
              setSelected(null);
              await fetchEvents();
            }}
            updateEvent={(event) => editEventFromMover(event)}
            event={selected}
          />
        ) : null}
        <FurikaeruModal
          open={furikaeruModalOpen}
          close={() => {
            setFurikaeruModalOpen(false);
          }}
          date={selDate}
          weeklyInfo={weeklyInfo}
          fetchWeeklyInfo={fetchWeeklyInfo}
        />
      </div>
    </LineAuthCheck>
  );
};

export default withStyles(styles)(LineCalendar);
