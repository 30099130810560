import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';

import {createInvite} from './invites';

import axios from 'axios';

axios.defaults.baseURL = ' https://asia-northeast1-future-log-67799.cloudfunctions.net/webApi/api/v1';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

if (!firebase.apps.length) {
  const CLIENT_CONFIG = {
    apiKey: "AIzaSyDeV1gVgtuJzbKfdmdIMXDu2Fy6XUmxTq4",
    authDomain: "future-log-67799.firebaseapp.com",
    projectId: "future-log-67799",
    storageBucket: "future-log-67799.appspot.com",
    messagingSenderId: "337593555009",
    appId: "1:337593555009:web:6bc12bf96d00005cf48ec0",
    measurementId: "G-DSJRD2QR53"
  };


  firebase.initializeApp(CLIENT_CONFIG);
}

const db = firebase.firestore();
const col = 'users';
const auth = firebase.auth();

export const getUsers = async () => {
  const userData = await db
    .collection(col)
    .get()
    .then(snap => {
      const data = [];
      snap.forEach(doc => {
        data.push(doc.data());
      });
      return data;
    })
    .catch(err => {
      return err;
    });
  return userData;
};

export const getUsersWithQuery = (
  searchName,
  searchLoc,
  searchTeam,
) => {
  let query = db.collection(col);
  if (searchName) {
    query = query.where('email', '>=', searchName)
      .where('email', '<=', searchName + 'z');
  } else if (searchLoc) {
    query = query.where('location', '==', searchLoc);
  } else if (searchTeam) {
    query = query.where('team', '==', searchTeam);
  } else if (searchName && searchLoc) {
    query = query
      .where('email', '>=', searchName)
      .where('email', '<=', searchName + 'z')
      .where('location', '==', searchLoc);
  } else if (searchName && searchTeam) {
    query = query
      .where('email', '>=', searchName)
      .where('email', '<=', searchName + 'z')
      .where('team', '==', searchTeam);
  } else if (searchLoc && searchTeam) {
    query = query
      .where('location', '==', searchLoc)
      .where('team', '==', searchTeam)
  } else if (searchName && searchLoc && searchTeam) {
    query = query
      .where('email', '>=', searchName)
      .where('email', '<=', searchName + 'z')
      .where('location', '==', searchLoc)
      .where('team', '==', searchTeam);
  }
  return query.get();
};

export const getUser = async (uid) => {
  const data = await db
    .collection(col)
    .doc(uid)
    .get()
    .then(doc => {
      if (!doc.exists) {
        return false
      }
      return doc.data()
    })
  return data;
};

export const signOut = () => {
  auth.signOut();
}

export const currentUser = () => {
  const currentUser = auth.currentUser;
  if(currentUser){
    auth.currentUser.getIdTokenResult()
    .then((result) => {
        return currentUser.role = result.claims.role;
       }
    )
    .catch((error) => {
      console.error(error);
    });
  }

  return currentUser
};

export const createMultiUsers = (userData) => {

  return Promise.all(userData.map(async (user) => {
    const responses = [];

    const {nameSei, nameMei, email, role, department, school} = user;

    await axios.post('users', {
      email: email, role: role, nameSei: nameSei, nameMei: nameMei, department: department, school: school
    })
      .then((response) => responses.push(response))
      .catch(err => {
        responses.push(err);
      });
    return responses;
  }))
}

export const changePassword = (currentPassword,password) => {
  var user = auth.currentUser;
  var credential = firebase.auth.EmailAuthProvider.credential(
    user.email, currentPassword);

  return user.reauthenticateWithCredential(credential).then(function() {
    auth.currentUser.updatePassword(password).catch(error =>{
      return error;
    })
  })

}

export const deleteUser = (id) => {
  //db.collection(col).doc(id).delete();
  return axios.delete(`users/${id}`);
}

export const updateUser = (user) => {
  db.collection(col).doc(user.id).set(user);
  //return axios.put(`users/${user.id}`, user);
}