import React, { useState, useEffect } from 'react';

import './StudentForm.scoped.scss';
import Grid from '@material-ui/core/Grid';

import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';

import TagMenu from './TagMenu';
import CourseMenu from './CourseMenu';
import ClassificationMenu from './ClassificationMenu';

import {
  getClassifications,
  createClassification,
  deleteClassification,
  updateClassification,
} from '../../api/classifications';
import {
  getCourses,
  createCourse,
  deleteCourse,
  updateCourse,
} from '../../api/courses';
import {
  getTags,
  createTag,
  deleteTag,
  updateTag,
} from '../../api/tags';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: '80%',
    maxWidth: '80%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    backgroundColor: 'white',
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  hasAdd: {
    display: 'flex',
    alignItems: 'center',
  },
  addButton: {
    fontSize: 20,
    color: 'black',
    fontWeight: 'bold',
    height: 30,
    width: 30,
    minWidth: 30,
    textAlign: 'center',
    backgroundColor: 'white',
    border: '2px solid black',
    lineHeight: 0,
    padding: 0,
    borderRadius: '100%',
    marginLeft: 15,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#b6263d',
      color: 'white',
      borderColor: '#b6263d',
    },
  },
}));

const StudentForm = props => {
  const { role } = props;

  const classes = useStyles();
  const theme = useTheme();

  const { student, isEdit, fireData } = props;

  const [userName, setUserName] = useState('');
  const [studentId, setStudentId] = useState('');
  const [bumon, setBumon] = useState('');
  const [courses, setCourses] = useState([]);
  const [year, setYear] = useState('');
  const [classifications, setClassifications] = useState([]);
  const [memo, setMemo] = useState('');
  const [school, setSchool] = useState('');
  const [tags, setTags] = useState([]);

  const [fDepartments, setFDepartments] = useState([]);
  const [fCourses, setFCourses] = useState([]);
  const [fClassifications, setFClassifications] = useState([]);
  const [fSchools, setFSchools] = useState([]);
  const [fTags, setFTags] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElClass, setAnchorElClass] = React.useState(null);
  const [anchorElCourse, setAnchorElCourse] = React.useState(null);

  const menuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const menuClose = () => {
    setAnchorEl(null);
    props.reload();
  };
  const menuClickClass = event => {
    setAnchorElClass(event.currentTarget);
  };
  const menuCloseClass = () => {
    setAnchorElClass(null);
    props.reload();
  };
  const menuClickCourse = event => {
    setAnchorElCourse(event.currentTarget);
  };
  const menuCloseCourse = () => {
    setAnchorElCourse(null);
    props.reload();
  };

  const [
    newClassificationDialogue,
    setNewClassificationDialogue,
  ] = useState(false);

  const [newCourseDialogue, setNewCourseDialogue] = useState(false);

  const [newTagsDialogue, setNewTagsDialogue] = useState(false);

  useEffect(() => {
    if (student) {
      setUserName(student.name);
      setStudentId(student.studentId);
      setBumon(student.department);
      setCourses(student.courses);
      setYear(student.schoolYear);
      setClassifications(student.classifications);
      setMemo(student.memo);
      setStudentId(student.studentId);
      setTags(student.tags);
      setSchool(student.school);
    }
  }, [student]);

  useEffect(() => {
    if (fireData) {
      setFDepartments(fireData.departments);
      setFCourses(fireData.courses);
      setFClassifications(fireData.classifications);
      setFSchools(fireData.schools);
      setFTags(fireData.tags);
      setFSchools(fireData.schools);
    }
  }, [fireData]);

  const getNameFromId = (arr, id, field) => {
    const fItems = arr.filter(item => {
      return item.id === id;
    });
    return fItems[0] ? fItems[0][field] : '';
  };

  const getObjFromId = (arr,id) => {
    const fItems = arr?.filter(item => {
      return item.id === id
    });
    return fItems[0];
  }

  const renderStudentName = () => {
    if (!isEdit) {
      return userName;
    } else {
      return (
        <FormControl className={classes.formControl}>
          <TextField value={userName} onChange={updateName} />
        </FormControl>
      );
    }
  };

  const updateSchool = e => {
    setSchool(e.target.value);
  };
  const updateDepartment = e => {
    setBumon(e.target.value);
  };

  const updateCourses = e => {
    setCourses(e.target.value);
  };
  const updateYear = e => {
    setYear(e.target.value);
  };
  const updateTags = e => {
    setTags(e.target.value);
  };
  const updateMemo = e => {
    setMemo(e.target.value);
  };
  const updateClassifications = e => {
    setClassifications(e.target.value);
  };
  const updateName = e => {
    setUserName(e.target.value);
  };
  const updateStudentId = e => {
    setStudentId(e.target.value);
  };

  const renderBumon = () => {
    if (!isEdit) {
      return getNameFromId(fDepartments, bumon, 'title');
    } else {
      return (
        <FormControl className={classes.formControl}>
          <Select value={bumon} onChange={updateDepartment}>
            {fDepartments.map(item => (
              <MenuItem value={item.id} key={item.id}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  const renderSchool = () => {
    if (!isEdit) {
      return getNameFromId(fSchools, school, 'title');
    } else {
      return (
        <FormControl className={classes.formControl}>
          <Select value={school} onChange={updateSchool}>
            {fSchools.filter( item => {
              const dep = getObjFromId(fDepartments, bumon);
              return dep?.schools?.includes(item.id)
            }).map(item => (
              <MenuItem value={item.id} key={item.id}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  const renderYear = () => {
    if (!isEdit) {
      return (
        <>
        {year === 1 ? '社会人' : null}
        {year === 2 ? '高卒・浪人生' : null}
        {year === 3 ? '高校3年生' : null}
        {year === 4 ? '高校2年生' : null}
        {year === 5 ? '高校1年生' : null}
        {year === 6 ? '中学3年生' : null}
        {year === 7 ? '中学2年生' : null}
        {year === 8 ? '中学1年生' : null}
        {year === 9 ? '小学6年生' : null}
        {year === 10 ? '小学5年生' : null}
        {year === 11 ? '小学4年生' : null}
        {year === 12 ? '小学3年生' : null}
        {year === 13 ? '小学2年生' : null}
        {year === 14 ? '小学1年生' : null}
        {year === 15 ? 'その他' : null}
        </>
      );
    } else {
      return (
        <FormControl className={classes.formControl}>
          <Select value={year} onChange={updateYear}>
            <MenuItem value="1">社会人</MenuItem>
            <MenuItem value="2">高卒・浪人生</MenuItem>
            <MenuItem value="3">高校3年生</MenuItem>
            <MenuItem value="4">高校2年生</MenuItem>
            <MenuItem value="5">高校1年生</MenuItem>
            <MenuItem value="6">中学3年生</MenuItem>
            <MenuItem value="7">中学2年生</MenuItem>
            <MenuItem value="8">中学1年生</MenuItem>
            <MenuItem value="9">小学6年生</MenuItem>
            <MenuItem value="10">小学5年生</MenuItem>
            <MenuItem value="11">小学4年生</MenuItem>
            <MenuItem value="12">小学3年生</MenuItem>
            <MenuItem value="13">小学2年生</MenuItem>
            <MenuItem value="14">小学1年生</MenuItem>
            <MenuItem value="15">その他</MenuItem>
          </Select>
        </FormControl>
      );
    }
  };

  const renderTags = () => {
    if (!isEdit) {
      return (
        <div className={classes.chips}>
          {tags.map(value => (
            <Chip
              key={value}
              label={getNameFromId(fTags, value, 'title')}
              className={classes.chip}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className={classes.hasAdd}>
          <FormControl className={classes.formControl}>
            <Select
              value={tags}
              multiple
              input={<Input id="select-multiple-chip" />}
              onChange={updateTags}
              renderValue={selected => (
                <div className={classes.chips}>
                  {selected.map(value => (
                    <Chip
                      key={value}
                      label={getNameFromId(fTags, value, 'title')}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
            >
              {fTags.map(item => (
                <MenuItem value={item.id} key={item.id}>
                  <Checkbox checked={tags.indexOf(item.id) > -1} />
                  <ListItemText primary={item.title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {role === 'admin' ? (
            <>
              <button
                className={classes.addButton}
                onClick={menuClick}
              >
                +
              </button>
              <TagMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                close={menuClose}
                handleClose={menuClose}
                locs={fTags}
                editLocSubmit={updateTag}
                deleteLocSubmit={deleteTag}
                newLocSubmit={createTag}
              />
            </>
          ) : null}
        </div>
      );
    }
  };

  const renderMemo = () => {
    if (!isEdit) {
      return memo;
    } else {
      return (
        <FormControl className={classes.formControl}>
          <TextField
            multiline
            rows={4}
            onChange={updateMemo}
            value={memo}
          />
        </FormControl>
      );
    }
  };

  const renderId = () => {
    if (!isEdit) {
      return studentId;
    } else {
      return (
        <FormControl className={classes.formControl}>
          <TextField value={studentId} onChange={updateStudentId} />
        </FormControl>
      );
    }
  };

  const submit = () => {
    const oldStudent = { ...student };
    oldStudent.displayName = userName;
    oldStudent.studentId = studentId;
    oldStudent.name = userName;
    oldStudent.department = bumon;
    oldStudent.schoolYear = Number(year);
    oldStudent.school = school;
    oldStudent.memo = memo;
    oldStudent.tags = tags;
    props.save(oldStudent);
  };

  return (
    <div className="student-form">
      <Grid container>
        <Grid item xs={6}>
          <div className="form-item">
            <p className="form-label">氏名</p>
            <div className="form-field">{renderStudentName()}</div>
          </div>
          <div className="form-item">
            <p className="form-label">部門</p>
            <div className="form-field">{renderBumon()}</div>
          </div>
          <div className="form-item">
            <p className="form-label">属性/学年</p>
            <div className="form-field">{renderYear()}</div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="form-item">
            <p className="form-label">ID</p>
            <div className="form-field">{renderId()}</div>
          </div>
          <div className="form-item">
            <p className="form-label">校舎</p>
            <div className="form-field">{renderSchool()}</div>
          </div>
          <div className="form-item">
            <p className="form-label">タグ</p>
            <div className="form-field">{renderTags()}</div>
          </div>
          <div className="form-item">
            <p className="form-label">備考</p>
            <div className="form-field">{renderMemo()}</div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <p className="submit" style={{ textAlign: 'right' }}>
            {!props.isEdit ? (
              <button
                className="grad-button"
                onClick={() => props.toggleEdit()}
              >
                編集
              </button>
            ) : (
              <>
                <button
                  className="grad-button"
                  style={{
                    color: 'black',
                    backgroundColor: 'white',
                    marginRight: 20,
                  }}
                  onClick={() => props.toggleEdit()}
                >
                  キャンセル
                </button>
                <button
                  className="grad-button"
                  onClick={() => submit()}
                >
                  保存
                </button>
              </>
            )}
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default StudentForm;
