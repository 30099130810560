import React, { useState, useEffect } from 'react';
import { withAuthorization } from '../components/Session';
import { useParams, useHistory } from 'react-router-dom';

import App from '../components/Global/App';

import StudentHeader from '../components/Students/StudentHeader';
import StudentForm from '../components/Students/StudentForm';

import { getDepartments } from '../api/departments';
import { getSchools } from '../api/schools';
import {
  getClassifications,
  createClassification,
} from '../api/classifications';
import { getCourses, createCourse } from '../api/courses';
import { getTags, createTag } from '../api/tags';

import {
  createYearlyEvent,
  updateYearlyEvent,
  getYearlyEvents,
} from '../api/yearlyEvents';

import { getLineUser, updateLineUser } from '../api/lineUsers';
import { currentUser, getUser, up } from '../api/users';

import StudentGant from '../components/Common/Gant/StudentGant';

const Student = props => {
  const { uid } = currentUser();
  const {id} = useParams();
  const [user, setUser] = useState({ role: 'teacher' });

  const history = useHistory();

  useEffect(() => {
    getUser(uid).then(data => {
      setUser(data);
    });
  }, [uid]);

  const [student, setStudent] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isStudentGant, setIsStudentGant] = useState(false);
  const [isWeekly, setIsWeekly] = useState(false);
  const [yearlyEvents, setYearlyEvents] = useState([]);

  const [fireData, setFireData] = useState({
    departments: [],
    schools: [],
    classifications: [],
    courses: [],
    tags: [],
  });

  const getUserData = () => {
    getLineUser(id).then(data => {
      setStudent(data);
    });
  };

  useEffect(() => {
    getUserData();
    const fData = { ...fireData };

    getDepartments().then(data => {
      fData.departments.push(...data);
      getSchools().then(data => {
        fData.schools.push(...data);
        getClassifications().then(data => {
          fData.classifications.push(...data);
          getCourses().then(data => {
            fData.courses.push(...data);
            getTags().then(data => {
              fData.tags.push(...data);
              setFireData(fData);
            });
          });
        });
      });
    });
  }, []);

  useEffect(() => {
    getYearlyEvents(id).then((data) => {
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].start_date && data[i].end_date) {
          data[i].start_date = new Date(
            data[i].start_date.seconds * 1000 +
              data[i].start_date.nanoseconds / 1000000,
          );
          data[i].end_date = new Date(
            data[i].end_date.seconds * 1000 +
              data[i].end_date.nanoseconds / 1000000,
          );
        }
      }
      setYearlyEvents(data);
    })
  }, [])

  const reload = async () => {
    const fData = {
      departments: [],
      schools: [],
      classifications: [],
      courses: [],
      tags: [],
    };
    getDepartments().then(data => {
      fData.departments.push(...data);
      getSchools().then(data => {
        fData.schools.push(...data);
        getClassifications().then(data => {
          fData.classifications.push(...data);
          getCourses().then(data => {
            fData.courses.push(...data);
            getTags().then(data => {
              fData.tags.push(...data);
              setFireData(fData);
            });
          });
        });
      });
    });
  };

  const save = async(data) => {
    await updateLineUser(data).then(()=>{
      setIsEdit(false);
      getUserData();
    })
  };

  const reloadEvents = async () => {
    getYearlyEvents(id).then((data) => {
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].start_date && data[i].end_date) {
          data[i].start_date = new Date(
            data[i].start_date.seconds * 1000 +
              data[i].start_date.nanoseconds / 1000000,
          );
          data[i].end_date = new Date(
            data[i].end_date.seconds * 1000 +
              data[i].end_date.nanoseconds / 1000000,
          );
        }
      }
      setYearlyEvents(data);
    })
  }
  
  const handleCloseGant = () => {
    setIsStudentGant(false)
  }

  useEffect(() => {
    reload();
    reloadEvents();
  },[user])

  return (
    <App>
      
      <StudentHeader
        student={student}
        openStudentGant={() => setIsStudentGant(true)}
        openWeekly={() => setIsWeekly(true)}
      />
      <StudentForm
        role={user.role}
        student={student}
        fireData={fireData}
        reload={reload}
        isEdit={isEdit}
        save={save}
        toggleEdit={() => {
          isEdit ? setIsEdit(false) : setIsEdit(true);
        }}
      />
      {isStudentGant ? 
      <StudentGant
        open={isStudentGant}
        events={yearlyEvents ? {data:[...yearlyEvents]} : {data:[]}}
        close={() => handleCloseGant()}
        user={id}
        reload={reloadEvents}
      /> : null}
    </App>
  );
};

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Student);
