import React, { useState, useEffect, useRef } from 'react';
import App from '../components/Global/App';
import PageTitle from '../components/Common/PageTitle';

import { withAuthorization } from '../components/Session';
import {
  getUser,
  changePassword,
  updateUser,
  changeAvatar,
} from '../api/users';

import {getDepartment} from '../api/departments';
import {getSchool} from '../api/schools';

import './scss/MyPage.scoped.scss';

import NameDialogue from '../components/Users/NameDialogue';
import PasswordDialogue from '../components/Users/PasswordDialogue';

const MyPage = props => {
  const [currentUser, SetCurrentUser] = useState({
    nameMei: '',
    nameSei: '',
    avatar: '',
    department: [],
    school: [],
    newMessages: false,
  });

  const [department, setDepartment] = useState([]);
  const [school, setSchool] = useState([]);

  const [showNameDialogue, setShowNameDialogue] = useState(false);
  const [showPasswordDialogue, setShowPasswordDialogue] = useState(
    false,
  );

  const getUserData = async () => {
    let { uid } = await props.firebase.currentUser();

    if(!uid) return false;

    await getUser(uid).then(doc => {
      SetCurrentUser(doc);
    });

  };

  useEffect(() => {
    getUserData();
  }, []);

  const fetchSchools = () => {
    const newArr = [];
    if(currentUser.school.length){
      currentUser.school.map(async (item) => {
        const dt = await getSchool(item).then((data) => {
          return data;
        });
        if(dt){
          newArr.push(dt);
        }
        setSchool([...newArr]);
      })
    }
  }

  const fetchDeps = () => {
    const newArr = [];
    if(currentUser.department.length){
      currentUser.department.map(async (item) => {
        const dt = await getDepartment(item).then((data) => {
          return data;
        });
        if(dt){
          newArr.push(dt);
        }
        setDepartment([...newArr]);
      })
    }
  }

  useEffect(() => {
    if(!currentUser.id) return;
    fetchSchools();
    fetchDeps();
  }, [currentUser])

  const handleDialogue = dialogue => {
    setShowNameDialogue(false);
    setShowPasswordDialogue(false);

    if (dialogue === 'password') {
      setShowPasswordDialogue(true);
    }
    if (dialogue === 'name') {
      setShowNameDialogue(true);
    }
  };
  const handleClose = () => {
    setShowNameDialogue(false);
    setShowPasswordDialogue(false);
  };

  const handleNameSubmit = (sei, mei) => {
    const newUser = { ...currentUser };
    newUser.nameMei = mei;
    newUser.nameSei = sei;
    updateUser(newUser);
    getUserData();
    handleClose();
  };

  const handlePasswordSubmit = async (currPass, newPass) => {
    await changePassword(currPass, newPass).then(() => {
      getUserData();
      handleClose();
    });
  };

  return (
    <App>
      <PageTitle title="マイページ" icon={['fas', 'user']} />
      <div className="user-data-table">
        <div className="data-table-row">
          <p>氏名</p>
          <p>
            {currentUser.nameSei} {currentUser.nameMei}
          </p>
          <p>
            <button
              className="edit"
              onClick={() => handleDialogue('name')}
            >
              変更
            </button>
          </p>
        </div>

        <div className="data-table-row">
          <p>校舎</p>
          <p>{school.map((item,index) => (<React.Fragment key={index}>{(index > 0 & school.length > 1) ? ', ' : null}{item.title}</React.Fragment>))}</p>
          <p />
        </div>

        <div className="data-table-row">
          <p>メールアドレス</p>
          <p>{currentUser.email}</p>
          <p />
        </div>

        <div className="data-table-row">
          <p>パスワード</p>
          <p>●●●●●●●●</p>
          <p>
            <button
              className="edit"
              onClick={() => handleDialogue('password')}
            >
              変更
            </button>
          </p>
        </div>

        <div className="data-table-row">
          <p>部門</p>
          <p>{department.map((item,index) => (<React.Fragment key={index}>{(index > 0 & department.length > 1) ? ', ' : null}{item.title}</React.Fragment>))}</p>
          <p />
        </div>
      </div>
      <NameDialogue
        nameMei={currentUser.nameMei}
        nameSei={currentUser.nameSei}
        submit={handleNameSubmit}
        close={handleClose}
        show={showNameDialogue}
      />
      <PasswordDialogue
        submit={handlePasswordSubmit}
        close={handleClose}
        show={showPasswordDialogue}
      />
    </App>
  );
};
const condition = authUser => !!authUser;
export default withAuthorization(condition)(MyPage);
