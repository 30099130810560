import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';

if (!firebase.apps.length) {
  const CLIENT_CONFIG = {
    apiKey: "AIzaSyDeV1gVgtuJzbKfdmdIMXDu2Fy6XUmxTq4",
    authDomain: "future-log-67799.firebaseapp.com",
    projectId: "future-log-67799",
    storageBucket: "future-log-67799.appspot.com",
    messagingSenderId: "337593555009",
    appId: "1:337593555009:web:6bc12bf96d00005cf48ec0",
    measurementId: "G-DSJRD2QR53"
  };


  firebase.initializeApp(CLIENT_CONFIG);
}

const db = firebase.firestore();
const col = 'dailyInfo';
const auth = firebase.auth();

export const getDailyInfos = async () => {
  const courseData = await db
    .collection(col)
    .get()
    .then(snap => {
      const data = [];
      snap.forEach(doc => {
        data.push(doc.data());
      });
      return data;
    })
    .catch(err => {
      return err;
    });
  return courseData;
};

export const getDailyInfo = async (year,month,day,userId) => {
  const data = await db
    .collection(col)
    .where('year', "==", year)
    .where('month', "==", month)
    .where('day', "==", day)
    .where('user', "==", userId)
    .get()
    .then(snap => {
      const data = [];
      snap.forEach(doc => {
        data.push(doc.data());
      });
      return data;
    })
  return data;
};

export const createDailyInfo = async info => {
  const courseRef = db.collection(col).doc();
  const courseObj = {
    id : courseRef.id,
    ...info
  }
  await courseRef.set(courseObj);
  return courseObj;
};

export const updateDailyInfo = async info => {
  const courseRef = db.collection(col).doc(info.id);
  const courseObj = {...info};
  await courseRef.set(courseObj, {merge : true});
  return courseObj;
};

export const deleteDailyInfo = async id => {
  await db
    .collection(col)
    .doc(id)
    .delete().catch(err => {
      return err
    });
  return true;
};
