import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

import { getLineUser, updateLineUser } from '../../../api/lineUsers';

import './OnboardModal.scoped.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OnboardModal = props => {

  const [hide, setHide] = useState(false);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.close}
        TransitionComponent={Transition}
        maxWidth="xs"
        fullWidth
      >
      <DialogTitle id="simple-dialog-title" style={{fontSize:16}}>どちらを使用しますか？</DialogTitle>
      <Divider />
      <List>
        <ListItem>
        <button id="mover-week" onClick={() => props.closeWithGant(hide)}>目標達成管理表</button>
        </ListItem>
        <Divider />
        <ListItem>
        <button id="mover-gant" onClick={() => props.close(hide)}>週間実行カレンダー</button>
        </ListItem>
        <Divider />
        <ListItem>
          <Checkbox onChange={()=>setHide(!hide)} checked={hide} /> 次回から表示しない
        </ListItem>
      </List>
    </Dialog>
    </>
  );
};

export default OnboardModal;