import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';

if (!firebase.apps.length) {
  const CLIENT_CONFIG = {
    apiKey: "AIzaSyDeV1gVgtuJzbKfdmdIMXDu2Fy6XUmxTq4",
    authDomain: "future-log-67799.firebaseapp.com",
    projectId: "future-log-67799",
    storageBucket: "future-log-67799.appspot.com",
    messagingSenderId: "337593555009",
    appId: "1:337593555009:web:6bc12bf96d00005cf48ec0",
    measurementId: "G-DSJRD2QR53"
  };


  firebase.initializeApp(CLIENT_CONFIG);
}

const db = firebase.firestore();
const col = 'departments';
const auth = firebase.auth();

export const getDepartments = async () => {
  const departmentData = await db
    .collection(col)
    .get()
    .then(snap => {
      const data = [];
      snap.forEach(doc => {
        data.push(doc.data());
      });
      return data;
    })
    .catch(err => {
      return err;
    });
  return departmentData;
};

export const getDepartment = async id => {
  const data = await db
    .collection(col)
    .doc(id)
    .get()
    .then(doc => {
      if (!doc.exists) {
        return false
      }
      return doc.data()
    }).catch(err => {
      return err
    })
  return data;
};

export const createDepartment = async department => {
  const departmentRef = db.collection(col).doc();
  const departmentObj = {
    id: departmentRef.id,
    title: department.title,
    created: new Date(),
    updated: new Date(),
    schools: []
  }
  await departmentRef.set(departmentObj);
  return departmentObj;
};

export const updateDepartment = async department => {
  const departmentRef = db.collection(col).doc(department.id);
  const departmentObj = { ...department };
  departmentObj.updated = new Date();
  await departmentRef.set(departmentObj, { merge: true });
  return departmentObj;
};

export const deleteDepartment = async id => {
  await db
    .collection(col)
    .doc(id)
    .delete().catch(err => {
      return err
    });
  return true;
};
