import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Marquee  from 'react-double-marquee';

const moment = extendMoment(Moment);
import Chip from './Chip';

import './TaskEventDiffTotal.scoped.scss';

const TaskEventSchedTotal = props => {
    const {plannedMinutes, scheduledMinutes, progress} = props;
    return (
        <tr>
            <th>合計</th>
            <td><NumberFormat value={scheduledMinutes} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
            <td>
                <Chip tJiseki={plannedMinutes} tWari={scheduledMinutes} />
            </td>
            <td><NumberFormat value={plannedMinutes} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
            <td>
                <Chip tJiseki={progress} tWari={plannedMinutes} />
            </td>
            <td><NumberFormat value={progress} displayType={'text'} decimalScale={0} thousandSeparator={true} suffix={'分'} /></td>
        </tr>
    );
};

export default TaskEventSchedTotal;